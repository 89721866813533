import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChanges
} from '@angular/core'
import { NgIf, NgFor, DatePipe } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { FileUploader, FileItem, ParsedResponseHeaders, FileLikeObject, FileUploadModule } from 'ng2-file-upload'
import { CommonService } from '@appShared/services/common.service'
import { ConfirmOptions, ConfirmService } from '@appShared/components/confirm-modal-and-service'
import {
   PropertyEditSectionData,
   PropertyEditSection_,
   PropertyService
} from '@appShared/services/property.service'
import {
   IProperty,
   IPropertyDocument
} from '@appShared/interfaces/[Model-based]'
import {
   DocumentType,
   DocumentTypes
} from '@appShared/services/lookup/[CodeGen]/document-type.domain'
import { ToastrType } from '@appShared/services/toastr.service'
import { TransactionTypes } from '@appShared/services/lookup/[CodeGen]'
import { ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service'
import { ButtonComponent } from '@appShared/components/button/button.component'
import * as _ from 'lodash'

@Component({
    selector: 'app-property-edit-documents',
    templateUrl: './property-edit-documents.component.html',
    standalone: true,
    imports: [FormsModule, NgIf, NgSelectModule, ButtonComponent, FileUploadModule, NgFor, ConfirmModalComponent, DatePipe]
})
export class PropertyEditDocumentsComponent implements OnInit, OnChanges {
   @Input() property: IProperty
   @Input() canEdit: boolean
   @Output() propertyStageUpdated = new EventEmitter();

   selectedPropertyDocument: IPropertyDocument
   documentTypes = [...DocumentTypes];
   documentTypeCode: number
   documentFileUploader: FileUploader = new FileUploader({ url: '' })
   filesRequired: string
   isSubmitting: boolean
   isDeleting: boolean

   /* grid */
   ColumnMode = ColumnMode;
   propertyDocumentFiles: IPropertyDocument[]
   propertyDocumentBaseUri: string

   constructor(
      private _commonService: CommonService,
      private _propertyService: PropertyService,
      private _confirmService: ConfirmService
   ) {
      this.propertyDocumentBaseUri = _propertyService.propertyDocumentBaseUri
   }

   ngOnInit(): void {

      this.documentFileUploader = new FileUploader({
         // will set in OnChanges url: `/api/lead/upload-da/${this.property.id}/true`,
         url: '',
         removeAfterUpload: false,
         autoUpload: true,
         itemAlias: 'formFile',
         disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
         allowedFileType: ['image', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt']
      })

      this.documentFileUploader.onSuccessItem = (
         property,
         response,
         status,
         headers
      ) => this.onSuccessPropertyDocument(property, response, status, headers)

      this.documentFileUploader.onErrorItem = (
         property,
         response,
         status,
         headers
      ) => this.onErrorPropertyDocument(property, response, status, headers)

      this.documentFileUploader.onAfterAddingFile = property => {
         this.isSubmitting = true
      }

      this.documentFileUploader.onWhenAddingFileFailed = (
         property: FileLikeObject
      ) => {
         if (property) {
            this._commonService.messageUser(
               `Unable to upload file ${property.name} of type: ${property.type}`,
               null,
               ToastrType.error
            )
         } else {
            this._commonService.messageUser(
               'Unable to upload file',
               null,
               ToastrType.error
            )
         }
      }
   }

   ngOnChanges(changes: SimpleChanges): void {
      const property = changes?.['property']
      const previousProperty = property?.previousValue
      const currentProperty = property?.currentValue
      const currentValueItemId = currentProperty?.id
      if (currentValueItemId) {
         /*only reload grid if property changes*/
         const previousValueId = previousProperty?.id

         if (
            previousValueId !== currentValueItemId
            || previousProperty?.['propertyDocuments'] != currentProperty?.['propertyDocuments']
            || !_.isEqual(previousProperty?.info['requiredDocumentTypeCodes'],
                       currentProperty?.info['requiredDocumentTypeCodes'])

         ) { this._setPropertyDocuments() }
      }
   }

   /*
    * events
    * */

   onDocumentTypeChange(documentType: DocumentType): void {
      if (documentType) {
         /* remove for now - they want to allow multiple documents of a given type */
         //const selectedPropertyDocument = this.propertyDocumentFiles.find(
         //   propertyDocument => propertyDocument.typeCode === documentType.code
         //)
         this._setSelectedPropertyDocument(null/*selectedPropertyDocument*/, documentType)
      }
   }

   onSuccessPropertyDocument(
      property: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
   ): any {
      this._setPropertyDocuments()
      this.isSubmitting = false
      this._setSelectedPropertyDocument(null)
      this.propertyStageUpdated.emit({
         propertyEditSection: PropertyEditSection_.Documents,
         property: this.property,
         refresh: true
      } as PropertyEditSectionData)
   }

   /**
    *
    * //https://stackoverflow.com/questions/36280565/angular2-http-get-location-header-of-201-response
    return this._http.post(this._heroesUrl, body, options)
        .flatMap((res:Response) => {
          var location = res.headers.get('Location');
          return this._http.get(location);
        }).map((res:Response) => res.json()))
        .catch(this.handleError)
    */

   onErrorPropertyDocument(
      fileItem: FileItem,
      response: string | object,
      status: number,
      headers: ParsedResponseHeaders
   ): any {
      this.isSubmitting = false

      const unknownError = 'Unknown error!'
      let error = _.isObject(response)
         ? response['error'] || response['message'] || unknownError
         : _.isString(response)
            ? (response.startsWith('<!DOCTYPE') || response.startsWith('<html'))
               ? unknownError
               : response
            : unknownError

      this._commonService.messageUser(error, 'Error Occurred uploading file!', ToastrType.error)
   }

   /*
    * private methods
    * */

   private _setFilesRequired(): void {
      const reqDocTypeCodes = this.property.info.requiredDocumentTypeCodes

      this.filesRequired = null

      if (reqDocTypeCodes?.length) {

         const requiredDocs = this.documentTypes.filter(docType => reqDocTypeCodes.includes(docType.code))
            .map(doc => doc.description)
            .join(', ')

         if (requiredDocs) {
            const transactionType = TransactionTypes.find(
               type => type.code == this.property.transactionTypeCode
            ).description

            this.filesRequired = `${transactionType} property requires ${requiredDocs} document(s)!`
         }
      }
   }

   private _setPropertyDocuments(): void {
      this.propertyDocumentFiles = this.property.propertyDocuments;

      this._setFilesRequired()

      this.documentFileUploader.clearQueue()
   }

   private _setSelectedPropertyDocument(
      selectedPropertyDocument: IPropertyDocument,
      documentType?: DocumentType
   ): void {
      this.selectedPropertyDocument = selectedPropertyDocument

      /* if documentType passed, then derive code */
      this.documentTypeCode = documentType?.code

      if (selectedPropertyDocument) {
         if (!documentType) {
            documentType = DocumentTypes.find(
               iType => iType.code === selectedPropertyDocument.typeCode
            )

            this.documentTypeCode =
               (documentType && documentType.code) ||
               DocumentTypes.find(
                  dType => dType.code === selectedPropertyDocument.typeCode
               ).code
         }
      }

      if (this.documentTypeCode) {
         const fileUploadUrl = selectedPropertyDocument
            ? `/api/portal/properties/${this.property.id}/documents/${selectedPropertyDocument.id
            }?version=${this.property.id /*TODO selectedPropertyDocument.property.version*/
            }`
            : `/api/portal/properties/${this.property.id}/documents/${documentType.code
            }`

         this.documentFileUploader.setOptions({
            url: fileUploadUrl,
            method: selectedPropertyDocument ? 'PUT' : 'POST'
         })
      }
   }

   /*
    * public methods
    */

   clearForm(): void {
      this._setSelectedPropertyDocument(null)
   }

   editPropertyDocument(propertyDocument: IPropertyDocument): void {
      this._setSelectedPropertyDocument(propertyDocument)
      setTimeout(() => {
         this._commonService.scrollToModalAnchor(
            `property-edit-section-${PropertyEditSection_.Documents}`
         )
      }, 0)
   }

   deletePropertyDocument(propertyDocument: IPropertyDocument): void {
      if (propertyDocument) {
         this._confirmService
            .confirm({
               title: 'Confirm Delete!',
               message: `You are removing <strong>${propertyDocument.fileName
                  }</strong><br/><br/>
                     Are you sure?`
            } as ConfirmOptions)
            .then(() => {
               this.isDeleting = true
               this._propertyService
                  .deletePropertyDocument(propertyDocument)
                  .then(() => {
                     this._setPropertyDocuments()

                     this.propertyStageUpdated.emit({
                        propertyEditSection: PropertyEditSection_.Documents,
                        property: this.property,
                        refresh: true
                     } as PropertyEditSectionData)
                  })
                  .catch(() => {
                     /* Do Nothing */
                  })
                  .finally(() => (this.isDeleting = false))
            })
            .catch(() => {
               /* Do Nothing */
            })
      }
   }
}
