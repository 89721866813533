import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   Output,
   SimpleChanges
} from '@angular/core'
import { NgIf, NgClass } from '@angular/common'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxCurrencyDirective } from 'ngx-currency'
import {
   PropertyEditSectionData,
   PropertyEditSection_,
   PropertyService
} from '@appShared/services/property.service'
import { ConfirmService } from '@appShared/components/confirm-modal-and-service'
import { CommonService } from '@appShared/services/common.service'
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface'
import {
   TransactionType_,
   TransactionTypes,
   PropertyType_
} from '@appShared/services/lookup/[CodeGen]'
import { ButtonComponent } from '@appShared/components/button/button.component'
import * as _ from 'lodash'

@Component({
    selector: 'app-property-edit-details',
    templateUrl: './property-edit-details.component.html',
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, BsDatepickerModule, NgxCurrencyDirective, ButtonComponent]
})
export class PropertyEditDetailsComponent implements OnChanges {
   @Input() property: IProperty
   @Input() canEdit: boolean
   @Output() propertyStageUpdated = new EventEmitter()

   // formgroup/fields
   propertyDetailsForm: FormGroup
   closingDatePicker: FormControl
   datePickerBaseConfig: Partial<BsDatepickerConfig> = {}
   salePrice: FormControl
   grossCommissionIncome: FormControl
   listingFee: FormControl
   hasReferral: boolean
   referralPartner: FormControl
   private _referralPartner: string /*to hold org referralPartner*/
   referralPaidOut: FormControl
   private _referralPaidOut: number /*to hold org referralPaidOut*/
   isCancelled: boolean
   private _cancelled: string /*to hold org cancelled*/
   standardDomainSearchFn: (
      term: string,
      item: any,
      matchingFieldOverride1?: string,
      matchingFieldOverride2?: string
   ) => boolean
   mouseoverPropertyDetailSubmit: boolean
   isSubmitting: boolean

   constructor(
      private _commonService: CommonService,
      private _propertyService: PropertyService,
      private _confirmService: ConfirmService
   ) {
      this._createFormGroup()

      this.standardDomainSearchFn = this._commonService.standardDomainSearchFn
   }

   // ngOnInit(): void {
   //  //this._createFormGroup()
   // }

   ngOnChanges(changes: SimpleChanges): void {
      const property = changes?.['property']
      const firstChange = property?.firstChange
      const currentValue = property?.currentValue
      if ((firstChange && currentValue?.id === 0) ||
            (!firstChange && currentValue?.id !== 0)
      ) {
         this._setDetailData()
      }
   }

   /*
    * private methods
    * */

   private _createFormGroup(): void {
      this.closingDatePicker = new FormControl(null, Validators.required)
      this.salePrice = new FormControl(null, Validators.required)
      this.grossCommissionIncome = new FormControl(null, Validators.required)
      this.listingFee = new FormControl()
      this.referralPartner = new FormControl()
      this.referralPaidOut = new FormControl()

      this.propertyDetailsForm = new FormGroup({
         closingDatePicker: this.closingDatePicker,
         salePrice: this.salePrice,
         grossCommissionIncome: this.grossCommissionIncome,
         listingFee: this.listingFee,
         referralPartner: this.referralPartner,
         referralPaidOut: this.referralPaidOut
      })
   }

   private _setDetailData(): void {
      const property = this.property
      const transactionTypeCode = property.transactionTypeCode
      const transactionType = TransactionTypes.find(
         type => type.code == transactionTypeCode
      )

      this._referralPartner = property.referralPartner
      this._referralPaidOut = property.referralPaidOut
      this.hasReferral = !!(
         property.referralPartner || property.referralPaidOut
      )

      //TODO - not sure about this
      this._cancelled = property.cancelled as string
      this.isCancelled = !!property.cancelled

      const listingFee = property.listingFee === 0
         ? 0
         : property.listingFee || null

      this.propertyDetailsForm.patchValue({
         closingDatePicker: property.closing
            ? new Date(property.closing)
            : null,
         salePrice: property.salePrice || null,
         grossCommissionIncome: property.grossCommissionIncome || null,
         listingFee,
         referralPartner: property.referralPartner || null,
         referralPaidOut: property.referralPaidOut || null
      })

      this.canEdit
         ? this.propertyDetailsForm.enable()
         : this.propertyDetailsForm.disable()

      this.setHasReferral(this.hasReferral)
      this.setIsCancelled(this.isCancelled, transactionType?.propertyTypeCode)

   }

   _disableField(formControl: FormControl, ): void {
      formControl.setValidators(null)
      formControl.setValue(null)
      formControl.markAsPristine()
      formControl.markAsUntouched()
      formControl.disable()
   }
   /*
    * events
    * */

   setListingFee(): void {
      const transactionTypeCode = this.property.transactionTypeCode
      if (!this.isCancelled &&
         transactionTypeCode &&
         _.includes(
            [TransactionType_.Seller, TransactionType_.BuyerSeller],
            transactionTypeCode
         )
      ) {
         if (this.propertyDetailsForm.enabled) {
            this.listingFee.setValidators(Validators.required)
            this.listingFee.enable()
         } else {
            this.listingFee.setValidators([])
            this.listingFee.disable()
         }
      } else {
         this._disableField(this.listingFee)
      }

      this.listingFee.updateValueAndValidity()
   }

   setHasReferral(hasReferral?: boolean): void {
      //referral property type doesn't not allow "has referral" section
      hasReferral = hasReferral && !(this.property.transactionTypeCode === TransactionType_.Referral)

      const enableField = (
         formControl: FormControl,
         enable: boolean,
         orgValue: string | number,
         disallowZero?: boolean
      ) => {
         if (enable) {
            const currentValue = formControl.value
            /* populate with org value if there is not a current value*/
            formControl.setValue(currentValue || orgValue)

            if (this.propertyDetailsForm.enabled) {

               let validators = [Validators.required]
               if (disallowZero) {
                  validators.push(Validators.min(1))
               }
               formControl.setValidators(validators)
               formControl.enable()
            } else {
               formControl.setValidators([])
               formControl.disable()
            }
         } else {
            this._disableField(formControl)
         }

         formControl.updateValueAndValidity()
      }

      this.hasReferral = hasReferral

      enableField(this.referralPartner, hasReferral, this._referralPartner)
      enableField(this.referralPaidOut, hasReferral, this._referralPaidOut, true/*disallowZero*/)
   }

   setIsCancelled(isCancelled?: boolean, propertyTypeCode?: PropertyType_): void {
      const setValidation = (
         formControl: FormControl,
         enable: boolean,
         disallowZero?: boolean
      ) => {
         if (this.propertyDetailsForm.enabled && enable) {
            formControl.setValidators([Validators.required, Validators.min(1)])
            formControl.enable()
         } else {
            this._disableField(formControl)
         }

         formControl.updateValueAndValidity()
      }

      const isReferral = propertyTypeCode == PropertyType_.Referral
      this.isCancelled = isCancelled

      if (isCancelled || isReferral) {
         /* populate cancelled date from org cancelled date or new date */
         this._cancelled =
            this._cancelled ||
            this._commonService.dateTime.formatDate(null, null, !isReferral)

         this.closingDatePicker.setValidators([])
      } else {
         this.closingDatePicker.setValidators(Validators.required)
      }

      this.closingDatePicker.updateValueAndValidity()

      setValidation(this.salePrice, !isCancelled && !isReferral)
      setValidation(this.grossCommissionIncome, !isCancelled && !isReferral)

      this.setListingFee()

      if (isCancelled) {
         this.setHasReferral(false)
      }
   }

   /*
    * public methods
    * */

   updatePropertyDetails(): void {
      this.isSubmitting = true

      /*
       * if fields are disabled, angular forms removes them
       * from the object so need pull "raw" values
       */
      const rawValues = this.propertyDetailsForm.getRawValue()
      const propertyDetailsValues = { ...rawValues }
      const closing = this._commonService.dateTime.formatDate(
         propertyDetailsValues.closingDatePicker
      )
      const cancelled = this.isCancelled ? this._cancelled : null
      const property = this._propertyService.readyPropertyObjectForSubmit({ ...this.property })

      delete propertyDetailsValues.closingDatePicker

      const updatedProperty = {
         ...property,
         ...propertyDetailsValues,
         closing,
         cancelled
      } as IProperty

      const section = PropertyEditSection_.Details

      this._propertyService.createUpdateProperty(updatedProperty, section)
         .then(returnProperty => {
            const msgSuffix = updatedProperty.id ? 'updated' : 'created'
            this._commonService.messageUser(`Property ${msgSuffix}!`)

            this.propertyStageUpdated.emit({
               propertyEditSection: section,
               property: returnProperty,
               refresh: true
            } as PropertyEditSectionData)
         })
         .catch(() => {
            /* Do Nothing */
         })
         .finally(() => this.isSubmitting = false)
   }
}
