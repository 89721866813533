import { Component } from '@angular/core'
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-page-not-found',
    template: `
    <div class="text-center mt-3 mb-5">
      <h2>404: PAGE NOT FOUND</h2>
      <p class="mx-auto text-center">
        We are sorry, the page you requested could not be found.
        Please go back to the home page, or contact us at <a href="mailto:customercare@southwesternadvantage.com" class="contact font-weight-bold">customercare&#64;southwesternadvantage.com</a></p>
      <div class="mt-4 mb-5">
        <a [routerLink]="['/']" class="btn btn-large btn-primary">GO TO HOMEPAGE</a>
      </div>
    </div>
  `,
    styles: [`
      h2 {
        font-size: 40px;
        font-weight: 700;
        color: #75a03b;
      }
      p { max-width: 400px }
      a.contact, a.contact:hover, a.contact:focus, a.contact:visited {
        color: #029edb;
        text-decoration: none;
      }
  `],
    standalone: true,
    imports: [RouterLink]
})

export class PageNotFoundComponent { }
