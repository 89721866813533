import { createAction, props } from '@ngrx/store'
import { IAgent } from '@appShared/interfaces/[Model-based]/agent.interface'
const apiLevelEventPrefix = '[Agents Page API]'

export const loadAgentsSuccess = createAction(
   `${apiLevelEventPrefix} Load Agents Success`,
   props<{ agents: IAgent[] }>()
)

export const loadAgentsFailure = createAction(
   `${apiLevelEventPrefix} Load Agents Failure`,
   props<{ error: string }>()
)
