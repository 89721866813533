import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgIf, NgFor, AsyncPipe } from '@angular/common'
import { Observable, Subject } from 'rxjs'
import {
   ChangeDetectionStrategy,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   ViewChild
} from '@angular/core'
import {
   debounceTime,
   distinctUntilChanged,
   filter,
   switchMap,
   takeUntil,
   tap
} from 'rxjs/operators'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CommonService } from '@appShared/services/common.service'
import { AdminService } from '@appShared/services/admin.service'
import { ConfirmOptions, ConfirmService } from '@appShared/components/confirm-modal-and-service'
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service'
import { AutofocusDirective } from '@appShared/directives/auto-focus.directive'
import { IEmployeeSearchResult } from '@appShared/interfaces/[Interface-based]/employee-search-result.interface'

@Component({
    selector: 'app-add-agent',
    templateUrl: './add-agent.component.html',
    styleUrls: ['./add-agent.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, AutofocusDirective, ReactiveFormsModule, NgIf, NgFor, ConfirmTemplateDirective, ConfirmModalComponent, AsyncPipe]
})
export class AddAgentComponent implements OnInit, OnDestroy {

   //@Output() agentSectionUpdated = new EventEmitter();
   private _ngDestroyed$ = new Subject();
   searchedTerm: string
   searching: boolean
   hasSearched: boolean
   employeeSearchTerm: FormControl
   employees$: Observable<IEmployeeSearchResult[]>

   constructor(
      private _adminService: AdminService,
      private _commonService: CommonService,
      private _confirmService: ConfirmService,
      private _modalService: NgbModal,
      private _activeModal: NgbActiveModal
   ) { }

   ngOnInit(): void {
      this.employeeSearchTerm = new FormControl()

      // https://codecraft.tv/courses/angular/http/http-with-observables/
      this.employees$ = this.employeeSearchTerm.valueChanges.pipe(
         takeUntil(this._ngDestroyed$),
         debounceTime(400),
         distinctUntilChanged(),
         filter(searchTerm => searchTerm.length > 1) /* more than 1 character */,
         tap(_ => (this.searching = true)),
         switchMap(term => this._adminService.employeeSearch(term)),
         tap(_ => (this.searching = false))
      )
   }

   ngOnDestroy(): void {
      this._ngDestroyed$.next(true)
      this._ngDestroyed$.complete()
   }
   /*
    * private methods
    * */

   /*
    * public methods
    * */

   createAgent(employee: IEmployeeSearchResult): void {
      const employeeName =
         employee.displayName ||
         (employee.firstName
            ? employee.lastName
               ? `${employee.firstName} ${employee.lastName}`
               : employee.firstName
            : employee.lastName || '')
      const company = employee.company
      const department = employee.department
      const companyDepartment = !!(company && department)
         ? `${employee.company} / ${employee.department}`
         : company
            ? employee.company
            : employee.department || ''

      this._confirmService
         .confirm({
            title: 'Verify New Agent!',
            message: `You are creating a new Agent:
            <p class='ps-3'><strong class='text-site-primary'>${employeeName}<br/>${companyDepartment}
            </strong><p>
            Are you sure?`
         } as ConfirmOptions)
         .then(() => {
            this._activeModal.close()
            this._commonService.messageUser(`${employeeName} added as a User!`)
            //const agentPinModalRef = this._modalService.open(
            //   AgentPinModalComponent
            //)

            //const agentPinComponent =
            //   agentPinModalRef.componentInstance
            //agentPinComponent.employee = { ...employee }
            //agentPinComponent.agentSectionUpdated = (
            //   agent: IAgent
            //) => {
            //   this.agentSectionUpdated.emit({
            //      agentEditSection: AgentEditSection_.newEmployeeInfo,
            //      agent,
            //      refresh: true
            //   } as AgentEditSectionData)
            //}
         })
         .catch(() => {
            /* Do Nothing */
         })
   }
}
