import { createAction, props } from '@ngrx/store'
import { IProperty } from '../../../shared/interfaces/[Model-based]/property.interface'

const pageLevelEventPrefix = '[Property Page Action]'

export const loadProperties = createAction(
   `${pageLevelEventPrefix} Load Agents`
)

export const setPropertiesSearchFilter = createAction(
   `${pageLevelEventPrefix} Set Properties Search Filter`,
   props<{ searchFilter: string }>()
)

export const setPropertiesIsRelevant = createAction(
   `${pageLevelEventPrefix} Set Properties IsRelevant`,
   props<{ isRelevant: boolean }>()
)

export const updatePropertyInPropertiesSearch = createAction(
   `${pageLevelEventPrefix} Update Property within Properties Search`,
   props<{ property: IProperty }>()
)
