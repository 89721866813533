import {
   ActivatedRouteSnapshot,
   RouterStateSnapshot,
   Params
} from '@angular/router'
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store'

import * as fromRouter from '@ngrx/router-store'
import * as fromProperty from './property.reducer'
import * as fromApp from './app.reducer'
import * as fromAgent from './agent.reducer'

export interface RouterStateUrl {
   /*can extend more if needed */
   url: string
   queryParams: Params
   params: Params
}

export interface State {
   //admin: fromAdmin.AdminState
   app: fromApp.AppState
   routerReducer: fromRouter.RouterReducerState<RouterStateUrl>
   property: fromProperty.PropertyState
   agent: fromAgent.AgentState
}

export const reducers: ActionReducerMap<State> = {
   //admin: fromAdmin.adminReducer,
   app: fromApp.appReducer,
   routerReducer: fromRouter.routerReducer,
   property: fromProperty.propertyReducer,
   agent: fromAgent.agentReducer
}

export const getRouterState = createFeatureSelector<
   fromRouter.RouterReducerState<RouterStateUrl>
>('router-reducer')

export class CustomSerializer
   implements fromRouter.RouterStateSerializer<RouterStateUrl> {
   serialize(routerState: RouterStateSnapshot): RouterStateUrl {
      const { url } = routerState
      const { queryParams } = routerState.root
      // have to traverse state tree to get params
      let state: ActivatedRouteSnapshot = routerState.root
      // can pull any values from 'state'
      while (state.firstChild) {
         state = state.firstChild
      }
      const { params } = state

      return { url, queryParams, params }
   }
}
