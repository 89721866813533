<div class="container">
   <div >
      <h4 *ngIf="propertyContact?.id">Contact: {{propertyContact.info.displayName}}</h4>
      <h4 *ngIf="propertyContact && !(propertyContact?.id)">New Contact</h4>
   </div>

   <form [formGroup]="propertyContactForm"
         (ngSubmit)="updatePropertyContact()"
         autocomplete="off" novalidate>

      <div class="row mt-4">

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': firstName.invalid && (firstName.dirty || firstName.touched || mouseoverPropertyContactSubmit)}">
               <span for="firstName" class="fake-label">First Name</span>
               <em *ngIf="firstName.invalid && (firstName.dirty || firstName.touched || mouseoverPropertyContactSubmit)" class="error-msg">
                  Required
               </em>
               <input formControlName="firstName"
                      id="firstName"
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      required />
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': lastName.invalid && (lastName.dirty || lastName.touched || mouseoverPropertyContactSubmit)}">
               <span for="lastName" class="fake-label">Last Name</span>
               <em *ngIf="lastName.invalid && (lastName.dirty || lastName.touched || mouseoverPropertyContactSubmit)" class="error-msg">
                  Required
               </em>
               <input formControlName="lastName"
                      id="lastName"
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      required />
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched || mouseoverPropertyContactSubmit)}">
               <span for="phoneNumber" class="fake-label">Cellphone</span>
               <em *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched || mouseoverPropertyContactSubmit)" class="error-msg">
                  <span *ngIf="phoneNumber.errors.required">
                     Req
                  </span>
                  <span *ngIf="phoneNumber.errors.pattern">
                     Invalid phone
                  </span>
               </em>
               <input formControlName="phoneNumber"
                      id="phoneNumber"
                      type="text"
                      mask="(000) 000-0000"
                      class="form-control"
                      placeholder="Cellphone"
                      maxlength="14"/>
            </div>

         </div>

      </div>

      <div>

         <div class="row mt-2">

            <div class="col-sm-6">

               <div class="form-group" [ngClass]="{'error': emailAddress.invalid && (emailAddress.dirty || emailAddress.touched || mouseoverPropertyContactSubmit) }">
                  <span for="emailAddress" class="fake-label">Email Address</span>
                  <em *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched || mouseoverPropertyContactSubmit)" class="error-msg">
                     <span *ngIf="emailAddress.errors.required">
                        Required
                     </span>
                     <span *ngIf="emailAddress.errors.pattern">
                        Enter valid email
                     </span>
                  </em>
                  <input formControlName="emailAddress"
                         id="emailAddress"
                         type="text"
                         class="form-control"
                         placeholder="Email Address"/>
               </div>

            </div>

            <div class="col-sm-2 mt-3">

               <div class="form-group mt-4">
                  <div class="pretty p-svg p-curve p-pulse">
                     <input type="checkbox"
                            formControlName="isPrimary"
                            id="isPrimary" />
                     <div class="state p-success">
                        <svg class="svg svg-icon" viewBox="0 0 20 20">
                           <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                        </svg>
                        <label>Primary?</label>
                     </div>
                  </div>
               </div>

            </div>

            <div class="col-sm-4 col-button text-center">

               <span (mouseenter)="mouseoverPropertyContactSubmit=true" (mouseleave)="mouseoverPropertyContactSubmit=false">
                  <app-button [buttonConfig]="{textChange: ['Create', 'Update']}"
                              [textChangeIndex]="+(!!(propertyContact?.id))"
                              [isLoading]="isSubmitting"
                              [disabled]="!propertyContactForm.valid || isSubmitting"
                              (textBtnClickEmt)="updatePropertyContact()"></app-button>
               </span>

               <!--<small class="pointable text-site-primary text-underline"
               (click)="cancel()">clear form</small>-->
               <button class="btn btn-site-tertiary ms-2"
                       type="button"
                       (click)="cancel()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                     <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg> Cancel
               </button>

            </div>

         </div>

      </div>

   </form>

</div>
