import { Injectable } from '@angular/core'
import {
   CanActivate,
   ActivatedRouteSnapshot,
   RouterStateSnapshot,
   Router
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators'
import { CommonService } from './common.service'
import { AppFacade } from './app.facade'

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
   constructor(
      private _commonService: CommonService,
      private _router: Router,
      private _appFacade: AppFacade
   ) {}

   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean> {
      // https://levelup.gitconnected.com/ngrx-and-angular-route-guards-a3fc3d5255f8
      return this._appFacade.siteConfig$.pipe(
         tap(siteConfig => this._appFacade.prefetchSiteConfig(siteConfig)),
         filter(data => !!data), // filter till has data
         take(1), // now that is has filtered data - take 1
         switchMap(config => {
            const returnUrl = state.url
            const profile = config.userProfile

            if (!profile) {
               this._commonService.navigateToLogin(returnUrl)

               return of(false)

            }
            // else if (user.mustResetPassword
            //    && returnUrl !== setPasswordUrl) {
            //    // force them to set-password
            //    this._router.navigate([setPasswordUrl])

            //    return of(false)

            // } else if (!user.mustResetPassword
            //    && returnUrl === setPasswordUrl) {
            //    // don't need set-password
            //    this._router.navigate(['/member'])

            //    return of(false)

            // } else if (currentSubscription && currentSubscription.statusCode === SubscriptionStatus_.OnHold
            //    && returnUrl !== accountBillingUrl) {
            //    // subscription is on hold - must address billing
            //    this._router.navigate(['/member/account/billing'])

            //    return of(false)
            // }

            return of(true)
         }),
         catchError(() => of(false))
      )
   }
}
