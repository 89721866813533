import { createFeatureSelector, createReducer, on } from '@ngrx/store'
import { IAgent } from '../../shared/interfaces/[Model-based]/agent.interface'
import { AgentApiActions, AgentPageActions } from '../actions/agent'

export interface AgentState {
   agents: IAgent[]
   searchFilter: string
   includeInactive: boolean
   error: string
}

const intialAgentState: AgentState = {
   agents: null,
   searchFilter: '',
   includeInactive: false,
   error: ''
}

export const getAgentFeatureState = createFeatureSelector<AgentState>(
   'agent'
)

export const agentReducer = createReducer<AgentState>(
   intialAgentState,
   on(
      AgentApiActions.loadAgentsSuccess,
      (state, action): AgentState => {
         return {
            ...state,
            agents: action.agents,
            error: ''
         }
      }
   ),
   on(
      AgentApiActions.loadAgentsFailure,
      (state, action): AgentState => {
         return {
            ...state,
            agents: [],
            error: action.error
         }
      }
   ),
   on(
      AgentPageActions.setAgentsSearchFilter,
      (state, action): AgentState => {
         return {
            ...state,
            searchFilter: action.searchFilter
         }
      }
   ),
   on(
      AgentPageActions.setAgentsIncludeInactive,
      (state, action): AgentState => {
         return {
            ...state,
            includeInactive: action.includeInactive
         }
      }
   )
)
