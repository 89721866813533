
import { Component, Input, Output, OnChanges, OnDestroy, OnInit, EventEmitter, SimpleChanges } from '@angular/core'
import { NgIf, NgClass } from '@angular/common'
import { Subject } from 'rxjs'
import { NgxMaskDirective } from 'ngx-mask'
import { PropertyEditSectionData, PropertyEditSection_, PropertyService } from '@appShared/services/property.service'
import { IPropertyContact, IProperty } from '@appShared/interfaces/[Model-based]'
import { CommonService } from '@appShared/services/common.service'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ButtonComponent } from '@appShared/components/button/button.component';

@Component({
    selector: 'app-property-edit-contact',
    templateUrl: './property-edit-contact.component.html',
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, NgxMaskDirective, ButtonComponent]
})
export class PropertyEditContactComponent
   implements OnInit, OnChanges, OnDestroy {
   @Input() property: IProperty
   @Input() propertyContact: IPropertyContact
   @Input() canEdit: boolean
   @Output() propertyContactUpdated = new EventEmitter();
   @Output() cancelPropertyContact = new EventEmitter();
   @Output() createNewPropertyContact = new EventEmitter();

   private _ngDestroyed$ = new Subject();
   standardDomainSearchFn: (
      term: string,
      item: any,
      matchingFieldOverride1?: string,
      matchingFieldOverride2?: string) => boolean

   _propertyContacts: IPropertyContact[]

   // formgroup/fields
   propertyContactForm: FormGroup
   firstName: FormControl
   lastName: FormControl
   phoneNumber: FormControl
   emailAddress: FormControl
   isPrimary: FormControl
   mouseoverPropertyContactSubmit: boolean
   isSubmitting: boolean

   // TODO - NEED THESE??
   isDiscontinued: boolean
   primaryDisabled: boolean

   get isExistingAuthManager(): boolean {
      return !!(this.propertyContact?.id)
   }

   constructor(
      private _commonService: CommonService,
      private _propertyService: PropertyService
   ) { }

   ngOnInit(): void {

      this._createFormGroup()
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.['propertyContact']?.currentValue) {
         this._setContactData()
      }
   }

   ngOnDestroy(): void {
      this._ngDestroyed$.next(true)
      this._ngDestroyed$.complete()
   }

   /*
    * events
    */

   /*
    * private methods
    * */

   private _createFormGroup(): void {
      this.firstName = new FormControl(null, Validators.required)
      this.lastName = new FormControl(null, Validators.required)
      this.phoneNumber = new FormControl(null, Validators.pattern(this._commonService.regexp().phone.usOnly))
      this.emailAddress = new FormControl(null, Validators.pattern(this._commonService.regexp().email))
      this.isPrimary = new FormControl()
      // this.installments = new FormControl(null, [
      //  Validators.min(1),
      //  Validators.max(365),
      //  Validators.required
      // ]);
      // this.retailPrice = new FormControl(null, Validators.required);
      // this.effectiveBeginDatePicker = new FormControl(
      //  null,
      //  Validators.required
      // );
      // this.effectiveEndDatePicker = new FormControl(null);
      // this.isSelectedByDefault = new FormControl(false);

      this.propertyContactForm = new FormGroup({
         firstName: this.firstName,
         lastName: this.lastName,
         phoneNumber: this.phoneNumber,
         emailAddress: this.emailAddress,
         isPrimary: this.isPrimary
      })
   }

   private _setContactData(): void {

      const propertyContact = this.propertyContact || {
         id: 0,
         propertyId: this.property.id,
         property: { ...this.property, id: this.property.id },
         isPrimary: false
      } as IPropertyContact

      this.propertyContact = { ...propertyContact }

      this.propertyContactForm.patchValue({
         firstName: propertyContact.firstName,
         lastName: propertyContact.lastName,
         phoneNumber: propertyContact.phoneNumber,
         emailAddress: propertyContact.emailAddress,
         isPrimary: propertyContact.isPrimary
      })

      this.canEdit ? this.propertyContactForm.enable() : this.propertyContactForm.disable()

      this._propertyService.getPrimaryContactsAsync(
         this.property.id
      )
         .then(propertyContacts => {
            this._propertyContacts = propertyContacts
         })
         .catch(() => {
            /* Do Nothing */
         })
   }

   private _emitContactUpdated(updateProperty: IProperty, updatedContact: IPropertyContact, msgSuffix) {
      this.property = updateProperty

      this.propertyContactUpdated.emit({
         propertyEditSection: PropertyEditSection_.Contacts,
         property: { ...updateProperty }
         // not going to set so will hide add/new section
         //selectedPropertyContact: updatedContact
      } as PropertyEditSectionData)

      this._commonService.messageUser(`Contact ${msgSuffix}!`)
   }

   /*
    * public methods
    * */

   clearForm(): void {
      this._setContactData()
   }

   newPropertyContact(): void {
      this.createNewPropertyContact.emit({
         id: 0,
         propertyId: this.property.id,
         property: { ...this.property, id: this.property.id },
         // publisherAuthorizationManagerId: 0,
         isPrimary: false
      } as IPropertyContact)
   }

   updatePropertyContact(): void {

      this.isSubmitting = true

      const contactValues = this.propertyContactForm.value
      const selectedPropertyContact = {
         ...this.propertyContact,
         propertyId: this.property.id,
         ...contactValues,
         info: { displayName: `${contactValues.firstName} ${contactValues.lastName}` }
      } as IPropertyContact

      if (selectedPropertyContact.id) {
         this._propertyService.updatePropertyContact(selectedPropertyContact)
            .then(returnProperty => {
               const returnContact = returnProperty.propertyContacts.find(
                  cont => cont.id === selectedPropertyContact.id
               )
               this._emitContactUpdated(returnProperty, returnContact, 'updated')
            })
            .catch(() => {
               /* Do Nothing */
            })
            .finally(() => this.isSubmitting = false)

      } else {
         this._propertyService.createPropertyContact(selectedPropertyContact)
            //TODO for future use if we want to return specific contact
            //.then(returnContact => {
            //   this._emitContactUpdated(returnContact, 'updated')
            //})
            .then(returnProperty => {
               const returnContact = returnProperty.propertyContacts.reduce((prev: IPropertyContact, curr: IPropertyContact) => {
                  if (prev.id < curr.id) {
                     return curr
                  } else {
                     return prev
                  }
               })
               this._emitContactUpdated(returnProperty, returnContact, 'updated')
            })
            .catch(() => {
               /* Do Nothing */
            })
            .finally(() => this.isSubmitting = false)
      }
   }

   cancel(): void {
      this.cancelPropertyContact.emit()
   }
}
