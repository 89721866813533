import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'

@Injectable({ providedIn: 'root' })
export class SiteService {
   private _siteAdminApi = '/api/admin/site'

   constructor(
      private _http: HttpClient,
      private _commonService: CommonService
   ) { }

   toggleIsMaintenanceMode(isMaintenanceMode: boolean): Observable<boolean> {
      const url = `${this._siteAdminApi}/set-offline/${isMaintenanceMode}`
      const options = this._commonService.httpPostOptions()

      // return of(isMaintenanceMode)
      return this._http.put<boolean>(url, null, options)
   }
}
