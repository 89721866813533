<div class="modal-header">

   <div class="modal-title">

      <h2 class="text-uppercase d-inline-block">
         <ng-template #newProperty>
            ADD NEW PROPERTY
         </ng-template>
         <span *ngIf="property?.id; else newProperty"
               class="property-address pe-2">
            {{property?.info.addressFullDisplay}}
            <span *ngIf="property?.info.mappedAddressUri"
                  class="text-underline text-site-primary-alt pointable"
                  (click)="viewGoogleMap()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#c56b09" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
   <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
</svg></span>
         </span>
      </h2>


      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
      </button>
   </div>

   <div class="modal-nav-menu clearfix" [hidden]="!(property?.id)">
      <span (click)="goToSection(PropertyEditSection_.BasicInfo)" class="link">Basic Info</span><span class="separator">|</span>
      <span (click)="goToSection(PropertyEditSection_.Contacts)" class="link">Contacts</span><span class="separator">|</span>
      <span (click)="goToSection(PropertyEditSection_.Details)" class="link">Details</span><span class="separator">|</span>
      <span (click)="goToSection(PropertyEditSection_.Documents)" class="link">Documents</span>
      <span *ngIf="showAccoutingLink">
         <span class="separator">|</span>
         <span (click)="goToSection(PropertyEditSection_.Accounting)" class="link">Accounting</span>
      </span>

      <span class="item-action discontinue btn-site-primary pointable"
            (click)="submitToAccounting()"
            *ngIf="property?.id > 0 && !property?.info.isApproved && !property?.info.isSubmitted && !property?.info.isCancelled">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
         </svg>
         Submit to Accounting
      </span>
      <span *ngIf="!property?.info.isCancelled && property?.info.isSubmitted && !property?.info.isApproved"
            class="item-action discontinue">
         Submitted: {{property.submitted|date}}
      </span>
      <span *ngIf="!property?.info.isCancelled && property?.info.isApproved"
            class="item-action discontinue">
         Approved: {{property.approved|date}}
      </span>
      <span *ngIf="property?.info.isCancelled"
            class="item-action discontinue">
         <span class="fw-bold text-site-tertiary">CANCELLED:</span> {{property.cancelled|date}}
      </span>
   </div>

</div>

<div class="modal-body pt-0">

   <app-property-edit [property]="property"
                      [userProfile]="userProfile"
                      [canEdit]="canEdit"
                      (propertyReset)="propertyStageUpdated($event)">
   </app-property-edit>

</div>
<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
