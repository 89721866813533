<div class="container" *ngIf="property">

   <form [formGroup]="propertyBasicInfoForm"
         (ngSubmit)="updateBasicInfo()"
         autocomplete="off"
         novalidate class="">

      <div class="row mt-2">

         <div class="col-sm-4">

            <div class="well mt-2">
               <label for="propertyTypeSelectFieldName">Property Type</label>
               <div class="row well" style="margin: 0 10px 10px;">
                  <span class="d-inline-block mt-2" *ngFor="let propertyType of propertyTypes">
                     <span class="d-block pretty p-default p-round">
                        <input type="radio"
                               [formControlName]="propertyTypeSelectFieldName"
                               [value]="propertyType.code"
                               (change)="onPropertyTypeChange(propertyType.code)" />
                        <span class="d-block state p-success-o">
                           <label>{{propertyType.description}}</label>
                        </span>
                     </span>
                  </span>
               </div>
            </div>
            <!--(change)="propertyTypeSelect = propertyType"-->
         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': listingNumber.invalid && (listingNumber.dirty || listingNumber.touched || mouseoverPropertyBasicInfoSubmit)}">
               <span class="fake-label">MLS#</span>
               <div class="d-inline form-group ps-3">
                  <div class="pretty p-icon">
                     <input type="checkbox"
                            formControlName="isListingUnavailable"
                            id="isListingUnavailable"
                            (change)="setListingAvailability($event.target.checked)" />
                     <div class="state p-success">
                        <i class="icon material-icons mdi mdi-check"></i>
                        <label>No MLS available</label>
                     </div>
                  </div>
               </div>
               <em *ngIf="listingNumber.invalid && (listingNumber.dirty || listingNumber.touched || mouseoverPropertyBasicInfoSubmit)" class="error-msg">
                  Required
               </em>
               <input formControlName="listingNumber"
                      type="text"
                      name="listingNumber"
                      class="form-control"
                      maxlength="9"
                      required />
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': agentsDropDown.invalid && (agentsDropDown.dirty || agentsDropDown.touched || mouseoverPropertyBasicInfoSubmit)}">
               <span class="fake-label">Agent</span>
               <em *ngIf="agentsDropDown.invalid && (agentsDropDown.dirty || agentsDropDown.touched || mouseoverPropertyBasicInfoSubmit)" class="error-msg">
                  Required
               </em>
               <ng-select formControlName="agentsDropDown"
                          [items]="agents"
                          bindLabel="displayName"
                          [clearable]="false"
                          required>
               </ng-select>
            </div>

         </div>

      </div>

      <div class="row mt-2">

         <div class="col-sm-6">

            <div class="form-group"
                 [ngClass]="{'error': street.invalid && (street.dirty || street.touched || mouseoverPropertyBasicInfoSubmit)}">
               <span class="fake-label" for="street">Address</span>
               <em *ngIf="street.invalid && (street.dirty || street.touched || mouseoverPropertyBasicInfoSubmit)" class="error-msg">
                  Required
               </em>
               <input formControlName="street"
                      id="street"
                      type="text"
                      class="form-control"
                      placeholder="Address"
                      required />
            </div>

         </div>

         <div class="col-sm-6">

            <div class="form-group"
                 [ngClass]="{'error': city.invalid && (city.dirty || city.touched || mouseoverPropertyBasicInfoSubmit)}">
               <span for="City" class="fake-label">City</span>
               <em *ngIf="city.invalid && (city.dirty || city.touched || mouseoverPropertyBasicInfoSubmit)" class="error-msg">
                  Required
               </em>
               <input formControlName="city"
                      id="city"
                      type="text"
                      class="form-control"
                      placeholder="City"
                      required />
            </div>

         </div>

      </div>

      <div class="row mt-2">

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': statesDropdown.invalid && (statesDropdown.dirty || statesDropdown.touched || mouseoverPropertyBasicInfoSubmit)}">
               <span class="fake-label">State/Province</span>
               <em *ngIf="statesDropdown.invalid && (statesDropdown.dirty || statesDropdown.touched || mouseoverPropertyBasicInfoSubmit)" class="error-msg">
                  Required
               </em>
               <ng-select [items]="states"
                          bindLabel="description"
                          formControlName="statesDropdown"
                          [searchFn]="standardDomainSearchFn"
                          dropdownPosition="top"
                          [clearable]="false"
                          required>
               </ng-select>
            </div>

         </div>

         <div class="col-sm-2">

            <div class="form-group"
                 [ngClass]="{'error': postalCode.invalid && (postalCode.dirty || postalCode.touched || mouseoverPropertyBasicInfoSubmit)}">
               <span for="postalCode" class="fake-label">Zip</span>
               <em *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched || mouseoverPropertyBasicInfoSubmit)" class="error-msg">
                  <span *ngIf="postalCode.errors.required">
                     Req
                  </span>
                  <span *ngIf="postalCode.errors.pattern">
                     Invalid
                  </span>
               </em>
               <input formControlName="postalCode"
                      id="postalCode"
                      type="text"
                      class="form-control"
                      placeholder="Zip Code"
                      required />
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': transactionTypeDropdown.invalid && (transactionTypeDropdown.dirty || transactionTypeDropdown.touched || mouseoverPropertyBasicInfoSubmit)}">
               <span class="fake-label">Transaction Type</span>
               <em *ngIf="transactionTypeDropdown.invalid && (transactionTypeDropdown.dirty || transactionTypeDropdown.touched || mouseoverPropertyBasicInfoSubmit)" class="error-msg">
                  Req
               </em>
               <ng-select formControlName="transactionTypeDropdown"
                          [items]="transactionTypes"
                          bindLabel="description"
                          dropdownPosition="top"
                          [clearable]="false"
                          required>
               </ng-select>
               <!--(change)="onTransactionTypeChange($event)"-->
            </div>
         </div>

         <div class="col-sm-2 col-button text-center">

            <span (mouseenter)="mouseoverPropertyBasicInfoSubmit=true" (mouseleave)="mouseoverPropertyBasicInfoSubmit=false">
               <app-button [buttonConfig]="{textChange: ['Create', 'Update']}" [textChangeIndex]="+(!!(property.id))"
                           [isLoading]="isSubmitting" [disabled]="!propertyBasicInfoForm.valid || isSubmitting" (textBtnClickEmt)="updateBasicInfo()">
               </app-button>
            </span>

         </div>

      </div>

   </form>

</div>

