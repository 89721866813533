import { Component } from '@angular/core'

@Component({
    selector: 'app-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.less'],
    standalone: true
})
export class SiteFooterComponent {
   currentYear = new Date().getFullYear()
}
