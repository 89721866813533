import {
   Component,
   Input,
   OnChanges,
   OnInit,
   SimpleChanges
} from '@angular/core'
import { AsyncPipe } from '@angular/common'
import { RouterLink } from '@angular/router'
import { Observable } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UrlService } from '@appShared/services/url.service'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import { IProfile } from '@appShared/interfaces/[Interface-based]/profile.interface'
import { AgentListModalComponent } from '@appComponents/agent'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-internal-header',
    templateUrl: './internal-header.component.html',
    styleUrls: ['./internal-header.component.less'],
    standalone: true,
    imports: [RouterLink, AsyncPipe]
})
export class InternalHeaderComponent implements OnInit {
   headerTitle$: Observable<string>
   profile$: Observable<IProfile>
   contentRootUri = environment.contentRootUri

   constructor(
      private _appFacade: AppFacade,
      private _commonService: CommonService,
      private _modalService: NgbModal,
      urlService: UrlService
   ) {
      this.headerTitle$ = urlService.headerTitle$
   }

   ngOnInit() {
      this.profile$ = this._appFacade.profile$
   }

   // ngOnChanges(changes: SimpleChanges): void {
   //    if (changes && !changes['leadSection']) {
   //       this.leadSection == this._initialLeadSection
   //    }
   // }

   /*
   * public methods
   * */

   comingSoon(areaName?: string) {
      const message = areaName ? `"${areaName}" feature is coming soon.` : ''
      this._commonService.comingSoon(message)
   }

   logout() {
      this._commonService.logout()
   }

   showAgentList(): void {
      this._modalService.open(AgentListModalComponent, {
         scrollable: true,
         windowClass: 'my-modal-md'
      })
   }
}
