import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { PropertyService } from '@appShared/services/property.service'
import {
   PropertyApiActions,
   PropertyPageActions
} from '@appState/actions/property'

@Injectable()
export class PropertyEffects {
   constructor(
      private _actions$: Actions,
      private _propertyService: PropertyService
   ) { }

   loadProperties$ = createEffect(() => {
      return this._actions$.pipe(
         ofType(PropertyPageActions.loadProperties),
         switchMap(action =>
            this._propertyService
               .getProperties()
               .pipe(
                  map(properties =>
                     PropertyApiActions.loadPropertiesSuccess({ properties })
                  ),
                  catchError(error =>
                     of(PropertyApiActions.loadPropertiesFailure({ error }))
                  )
               )
         )
      )
   })
}
