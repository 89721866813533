import { createAction, props } from '@ngrx/store'
import {
   ISiteConfiguration,
   IProfile
} from '@appShared/interfaces/[Interface-based]'

const eventPrefix = '[App API]'

export const loadSiteConfig = createAction(`${eventPrefix} Set Site Config`)

export const loadSiteConfigSuccess = createAction(
   `${eventPrefix} Load Site Config Success`,
   props<{ siteConfig: ISiteConfiguration }>()
)

export const loadSiteConfigFailure = createAction(
   `${eventPrefix} Load Site Config Failure`,
   props<{ error: string }>()
)

export const setProfile = createAction(
   `${eventPrefix} Set Current Profile`,
   props<{ profile: IProfile }>()
)

export const toggleIsMaintenanceMode = createAction(
   `${eventPrefix} Toggle Site Is Offline`,
   props<{ isMaintenanceMode: boolean }>()
)

export const toggleIsMaintenanceModeSuccess = createAction(
   `${eventPrefix} Toggle Site Is Offline Success`,
   props<{ isMaintenanceMode: boolean }>()
)

export const toggleIsMaintenanceModeFailure = createAction(
   `${eventPrefix} Toggle Site Is Offline Failure`,
   props<{ error: string }>()
)

export const setUserMustResetPassword = createAction(
   `${eventPrefix} Set Current User Must Reset Password`,
   props<{ mustResetPassword: boolean }>()
)

//export const setHasVerifiedSSN = createAction(
//  `${eventPrefix} Set Has Verified SSN`
//)

//export const toggleIsAdminOnlyMode = createAction(
//  `${eventPrefix} Toggle Site Is Offline`,
//  props<{ isAdminOnlyMode: boolean }>()
//)

//export const toggleIsAdminOnlyModeSuccess = createAction(
//  `${eventPrefix} Toggle Site Is Offline Success`,
//  props<{ isAdminOnlyMode: boolean }>()
//)

//export const toggleIsAdminOnlyModeFailure = createAction(
//  `${eventPrefix} Toggle Site Is Offline Failure`,
//  props<{ error: string }>()
//)
