<div class="container mt-3" *ngIf="property">

   <form [formGroup]="propertyAccountingForm"
         (ngSubmit)="updatePropertyAccounting()"
         autocomplete="off"
         novalidate class="">

      <div class="row mt-2">

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': checkDepositAmount.invalid && (checkDepositAmount.dirty || checkDepositAmount.touched || mouseoverPropertyAccountingSubmit)}">
               <span class="fake-label">Check Deposit Amount</span>
               <em *ngIf="checkDepositAmount.invalid && (checkDepositAmount.dirty || checkDepositAmount.touched || mouseoverPropertyAccountingSubmit)" class="error-msg">
                  Req
               </em>
               <input formControlName="checkDepositAmount"
                      type="text"
                      #salePriceInput
                      currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }"
                      name="checkDepositAmount"
                      class="form-control text-right" />
               <!--mask="separator.2" thousandSeparator=","-->
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': checkDepositedDatePicker.invalid && (checkDepositedDatePicker.dirty || checkDepositedDatePicker.touched || mouseoverPropertyAccountingSubmit)}">
               <span class="fake-label">Check Deposited <!--<small>(opt.)</small>--></span>
               <!--<small class="pointable text-site-primary text-underline pl-2" *ngIf="canEdit" (click)="checkDepositedDatePicker.setValue(null)">clear</small>-->
               <em *ngIf="checkDepositedDatePicker.invalid && (checkDepositedDatePicker.dirty || checkDepositedDatePicker.touched || mouseoverPropertyAccountingSubmit)" class="error-msg">
                  Required
               </em>
               <div class="input-group">
                  <input formControlName="checkDepositedDatePicker"
                         #checkDepositedDateInput="bsDatepicker"
                         type="text"
                         class="form-control"
                         container=""
                         placement="right"
                         bsDatepicker
                         [bsConfig]="datePickerBaseConfig">
                  <div class="input-group-append">
                     <button class="btn btn-outline-secondary"
                             type="button"
                             (click)="checkDepositedDateInput.toggle()">
                        <i class="fas fa-calendar-times"></i>
                     </button>
                  </div>
               </div>
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': depositAccountTypeDropDown.invalid && (depositAccountTypeDropDown.dirty || depositAccountTypeDropDown.touched || mouseoverPropertyAccountingSubmit)}">
               <span class="fake-label">Deposit Account</span>
               <em *ngIf="depositAccountTypeDropDown.invalid && (depositAccountTypeDropDown.dirty || depositAccountTypeDropDown.touched || mouseoverPropertyAccountingSubmit)" class="error-msg">
                  Req
               </em>
               <ng-select formControlName="depositAccountTypeDropDown"
                          [items]="depositAccountTypes"
                          bindLabel="description"
                          [clearable]="false">
               </ng-select>
            </div>

         </div>

      </div>

      <div class="row mt-2">

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': commission.invalid && (commission.dirty || commission.touched || mouseoverPropertyAccountingSubmit)}">
               <span class="fake-label">Commission Amount</span>
               <em *ngIf="commission.invalid && (commission.dirty || commission.touched || mouseoverPropertyAccountingSubmit)" class="error-msg">
                  Req
               </em>
               <input formControlName="commission"
                      type="text"
                      currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }"
                      name="commission"
                      class="form-control text-right" />
            </div>

         </div>

         <div class="col-sm-2">

            <div class="form-group"
                 [ngClass]="{'error': transactionCount.invalid && (transactionCount.dirty || transactionCount.touched || mouseoverPropertyAccountingSubmit)}">
               <span class="fake-label">Trans Count</span>
               <em *ngIf="transactionCount.invalid && (transactionCount.dirty || transactionCount.touched || mouseoverPropertyAccountingSubmit)" class="error-msg">
                  *
               </em>
               <input formControlName="transactionCount"
                      mask="999"
                      maxlength="3"
                      name="transactionCount"
                      class="form-control text-right" />
            </div>

         </div>

         <div class="col-sm-2">

            <div class="form-group"
                 [ngClass]="{'error': transactionWeight.invalid && (transactionWeight.dirty || transactionWeight.touched || mouseoverPropertyAccountingSubmit)}">
               <span class="fake-label">Trans Weight</span>
               <em *ngIf="transactionWeight.invalid && (transactionWeight.dirty || transactionWeight.touched || mouseoverPropertyAccountingSubmit)" class="error-msg">
                  *
               </em>
               <input formControlName="transactionWeight"
                      currencyMask
                      maxlength="5"
                      [options]="{ prefix: '', decimal: '.' }"
                      name="transactionWeight"
                      class="form-control text-right" />
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': sapPostingDatePicker.invalid && (sapPostingDatePicker.dirty || sapPostingDatePicker.touched || mouseoverPropertyAccountingSubmit)}">
               <span class="fake-label">SAP Posting Date <!--<small>(opt.)</small>--></span>
               <!--<small class="pointable text-site-primary text-underline pl-2" *ngIf="canEdit" (click)="sapPostingDatePicker.setValue(null)">clear</small>-->
               <em *ngIf="sapPostingDatePicker.invalid && (sapPostingDatePicker.dirty || sapPostingDatePicker.touched || mouseoverPropertyAccountingSubmit)" class="error-msg">
                  Required
               </em>
               <div class="input-group">
                  <input formControlName="sapPostingDatePicker"
                         #sapPostingDateInput="bsDatepicker"
                         type="text"
                         class="form-control"
                         container=""
                         placement="left"
                         bsDatepicker
                         [bsConfig]="datePickerBaseConfig">
                  <div class="input-group-append">
                     <button class="btn btn-outline-secondary"
                             type="button"
                             (click)="sapPostingDateInput.toggle()">
                        <i class="fas fa-calendar-times"></i>
                     </button>
                  </div>
               </div>
            </div>

         </div>

      </div>

      <div class="row mt-2">

         <div class="col-sm-3">

            <div class="form-group"
                 *ngIf="property.submitted">
               <label class="d-block text-center">Submitted to Accounting</label>
               <input type="text"
                      value="{{property.submitted|date}}"
                      class="form-control text-center"
                      disabled /><!--[value]="property.submitted"-->
            </div>

         </div>

         <div class="col-sm-3">

            <div class="form-group"
                 *ngIf="property.approved">
               <label class="d-block text-center">Closed by Accounting</label>
               <input type="text"
                      value="{{property.approved|date}}"
                      class="form-control text-center"
                      disabled /><!--[value]="property.approved"-->
            </div>

         </div>

         <div class="col-sm-4 mt-1 text-right">

            <div class="form-group mt-4"
                 [ngClass]="{'d-none': !isSaleTransactionType}">
               <div class="pretty p-svg p-curve p-pulse">
                  <input type="checkbox"
                         formControlName="isAgentDirectPay"
                         id="isAgentDirectPay" />
                  <div class="state p-success">
                     <svg class="svg svg-icon" viewBox="0 0 20 20">
                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                     </svg>
                     <label>Agent Direct Pay?</label>
                  </div>
               </div>
            </div>

         </div>

         <div class="col-sm-2 mt-3 text-center">

            <span (mouseenter)="mouseoverPropertyAccountingSubmit=true" (mouseleave)="mouseoverPropertyAccountingSubmit=false">
               <app-button [buttonConfig]="{textChange: ['Update']}"
                           [isLoading]="isSubmitting"
                           [disabled]="!propertyAccountingForm.valid || isSubmitting"
                           (textBtnClickEmt)="updatePropertyAccounting(null)">
               </app-button>
            </span>

         </div>

      </div>

      <div *ngIf="isAdmin"
           class="row mt-4">

         <div class="col text-center">
            <app-button [buttonConfig]="{textChange: ['Disapprove'], class: 'btn btn-danger me-3'}"
                        [isLoading]="isSubmitting"
                        [disabled]="isSubmitting"
                        (textBtnClickEmt)="updatePropertyAccounting(false)">
            </app-button>
            <app-button [buttonConfig]="{textChange: ['Approve'], class: 'btn btn-site-primary'}"
                        [isLoading]="isSubmitting"
                        [disabled]="property.approved || isSubmitting"
                        (textBtnClickEmt)="updatePropertyAccounting(true)">
            </app-button>
         </div>

      </div>

   </form>

</div>
<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
