import { createSelector } from '@ngrx/store'
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface'
import { getPropertyFeatureState } from '@appState/reducers/property.reducer'

// export const getItemState = createSelector(
//  fromFeature.getProfileFeatureState,
//  (state: fromFeature.ProductsAdminState) => state.items
// )

// export const getCurrentProfile = createSelector(
//  getProfileFeatureState,
//  state => state.userProfile
// )


export const getProperties = createSelector(
   getPropertyFeatureState,
   state => state.properties
)

export const getPropertySearchFilter = createSelector(
   getPropertyFeatureState,
   state => state.searchFilter
)

export const getPropertySearchIsRelevant = createSelector(
   getPropertyFeatureState,
   state => state.isRelevant
)

export const getFilteredProperties$ = createSelector(
   getProperties,
   getPropertySearchFilter,
   getPropertySearchIsRelevant,
   (properties: IProperty[], searchFilter: string, isRelevant: boolean) => {
      const filterBy = searchFilter?.toLocaleLowerCase()
      let filteredProperties: IProperty[] = [...properties]

      if (filterBy.trim()) {
         filteredProperties = properties.filter((property: IProperty) => {
            return (
               (property.listingNumber &&
                  property.listingNumber
                     .toLocaleLowerCase()
                     .indexOf(filterBy) !== -1) ||
               (property.clientName &&
                  property.clientName
                     .toLocaleLowerCase()
                     .indexOf(filterBy) !== -1) ||
               property.info.addressFullDisplay.toLocaleLowerCase()
                  .indexOf(filterBy) !== -1 ||
               property.agent.displayName.toLocaleLowerCase()
                  .indexOf(filterBy) !== -1 ||
               property.info.propertyTypeDescription.toLocaleLowerCase()
                  .indexOf(filterBy) !== -1 ||
               property.info.status
                  .toLocaleLowerCase()
                  .indexOf(filterBy) !== -1
            )
         })
      }

      // filter by "isRelevant" only if they select it
      if (isRelevant) {
         filteredProperties = filteredProperties.filter((property: IProperty) => {
            return property.info.isRelevant === isRelevant
         })
      }

      return filteredProperties
   }
)

