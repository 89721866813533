import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { AgentService } from '@appShared/services/agent.service'
import {
   AgentApiActions,
   AgentPageActions
} from '@appState/actions/agent'

@Injectable()
export class AgentEffects {
   constructor(
      private _actions$: Actions,
      private _agentService: AgentService
   ) { }

   loadAgents$ = createEffect(() => {
      return this._actions$.pipe(
         ofType(AgentPageActions.loadAgents),
         switchMap(action =>
            this._agentService
               .getAgents()
               .pipe(
                  map(agents =>
                     AgentApiActions.loadAgentsSuccess({ agents })
                  ),
                  catchError(error =>
                     of(AgentApiActions.loadAgentsFailure({ error }))
                  )
               )
         )
      )
   })
}
