import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable, of, take } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CommonService } from '@appShared/services/common.service';
import { IEmployeeSearchResult } from '@appShared/interfaces/[Interface-based]/employee-search-result.interface';
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface';
import * as _ from 'lodash';

export enum AgentEditSection_ {
   newEmployeeInfo = 1,
   basicInfo,
   planInfo,
   dividendInfo
}

export interface AgentEditSectionData {
   agentEditSection: AgentEditSection_;
   property: IProperty;
   refresh: boolean;
}

@Injectable({ providedIn: 'root' })
export class AdminService {
   /* https://blogs.msmvps.com/deborahk/build-a-simple-angular-service-to-share-data/ */
   private _propertyAdminApi = '/api/admin/property'

   constructor(
      private _http: HttpClient,
      private _commonService: CommonService
   ) { }

   /* TODO - remove*/
   fakeEmployees = [
      {
         objectId: '00000000-1234-0000-0000-000000000000',
         firstName: 'Ryan',
         lastName: 'Groom',
         company: 'SW Financial Services'
      } as IEmployeeSearchResult,
      {
         objectId: '00000000-0000-5678-0000-000000000000',
         firstName: 'Amy',
         lastName: 'Pigozzi-Groom',
         company: 'Southwestern',
         department: 'Bookeeping'
      } as IEmployeeSearchResult
   ] as IEmployeeSearchResult[]

   // getAgents(): Observable<IAgent[]> {
   //    const url = `${this._agentAdminApi}`
   //    return this._http.get<IAgent[]>(url)
   // }

   // async getAgent(id: number): Promise<IAgent> {
   //    const url = `${this._agentAdminApi}/${id}`

   //    return await this._http.get<IAgent>(url).toPromise()
   // }

    employeeSearch(term: string): Observable<IEmployeeSearchResult[]> {
      if (term) {
         // const url = `${this._propertyAdminApi}/search?term=${term}`;

         // return this._http.get<IEmployeeSearchResult[]>(url);
         const searchTermLowerCase = term.toLowerCase();

         const filteredEmployees: IEmployeeSearchResult[] = this.fakeEmployees.filter(
            employee => {
               const employeeInfo = `${employee.firstName} ${
                  employee.lastName
               }`.toLowerCase();
               return employeeInfo.indexOf(searchTermLowerCase) !== -1;
            }
         );

         return of(filteredEmployees);
      } else {
         return of([]);
      }
   }

   // async createUpdateAgent(
   //    agent: IAgent
   // ): Promise<IAgent> {
   //    if (!agent) {
   //       return new Promise((resolve, reject) => reject('No User passed!'))
   //    }

   //    const url = agent.id
   //       ? `${this._agentAdminApi}/${agent.id}`
   //       : this._agentAdminApi
   //    const options = this._commonService.httpPostOptions()

   //    if (agent.id) {
   //       /* update */
   //       return await this._http
   //          .put<IAgent>(url, agent, options)
   //          .toPromise()
   //    }

   //    /* else create */
   //    return await this._http
   //       .post<IAgent>(url, agent, options)
   //       .toPromise()

   //    // TODO return await this._http.post<IAgent>(url, agent, options).toPromise()
   //    // let newAgent = {
   //    //  ...agent,
   //    //  id: 987654
   //    // }
   //    // // this.fakeData.push(newAgent)
   //    // return await new Promise(resolve => {
   //    //  resolve({ ...newAgent } as IAgent)
   //    // })
   // }

   async getProperties(agentId: number): Promise<IProperty[]> {
      const url = `${this._propertyAdminApi}/${agentId}/plans`;

      const request$ = this._http.get<IProperty[]>(url).pipe(take(1))

      return await lastValueFrom<IProperty[]>(request$)

      // let propertyPlans = this.fakePlans.filter(
      //  data => data.agentId === agentId
      // );
      // return await new Promise(resolve => {
      //  resolve(propertyPlans);
      // });
   }

   async createUpdateProperty(property: IProperty): Promise<IProperty> {
      if (!property) {
         return new Promise((resolve, reject) => reject('No Property passed!'));
      }

      const url = property.id
         ? `${this._propertyAdminApi}/${property.id}`
         : this._propertyAdminApi;
      const options = this._commonService.httpPostOptions();
      let request$: Observable<IProperty>

      if (property.id) {
         /* update */
         request$ = this._http.put<IProperty>(url, property, options).pipe(take(1))
      } else {
         /* else create */
         request$ = this._http.post<IProperty>(url, property, options).pipe(take(1))
      }

      return await lastValueFrom<IProperty>(request$)
   }
}
