<div class="container" *ngIf="property">

   <form [formGroup]="propertyDetailsForm"
         (ngSubmit)="updatePropertyDetails()"
         autocomplete="off"
         novalidate class="">

      <div class="row mt-2">

         <div class="col-sm-3">

            <div class="form-group"
                 [ngClass]="{'error': closingDatePicker.invalid && (closingDatePicker.dirty || closingDatePicker.touched || mouseoverPropertyDetailSubmit)}">
               <span class="fake-label">Closing Date <!--<small>(opt.)</small>--></span>
               <!--<small class="pointable text-site-primary text-underline ps-2" *ngIf="canEdit" (click)="closingDatePicker.setValue(null)">clear</small>-->
               <em *ngIf="closingDatePicker.invalid && (closingDatePicker.dirty || closingDatePicker.touched || mouseoverPropertyDetailSubmit)" class="error-msg">
                  Required
               </em>
               <div class="input-group">
                  <input formControlName="closingDatePicker"
                         #closingDateInput="bsDatepicker"
                         type="text"
                         class="form-control"
                         container=""
                         placement="right"
                         bsDatepicker
                         [bsConfig]="datePickerBaseConfig">
                  <div class="input-group-append">
                     <button class="btn btn-outline-secondary"
                             type="button"
                             (click)="closingDateInput.toggle()">
                        <i class="fas fa-calendar-times"></i>
                     </button>
                  </div>
               </div>
            </div>

         </div>

      </div>

      <div class="row mt-2">

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': salePrice.invalid && (salePrice.dirty || salePrice.touched || mouseoverPropertyDetailSubmit)}">
               <span class="fake-label">Sale Price</span>
               <em *ngIf="salePrice.invalid && (salePrice.dirty || salePrice.touched || mouseoverPropertyDetailSubmit)" class="error-msg">
                  Req
               </em>
               <input formControlName="salePrice"
                      type="text"
                      #salePriceInput
                      currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }"
                      name="salePrice"
                      class="form-control text-right"
                      required />
               <!--mask="separator.2" thousandSeparator=","-->
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': grossCommissionIncome.invalid && (grossCommissionIncome.dirty || grossCommissionIncome.touched || mouseoverPropertyDetailSubmit)}">
               <span class="fake-label">GCI</span>
               <em *ngIf="grossCommissionIncome.invalid && (grossCommissionIncome.dirty || grossCommissionIncome.touched || mouseoverPropertyDetailSubmit)" class="error-msg">
                  Req
               </em>
               <input formControlName="grossCommissionIncome"
                      type="text"
                      currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }"
                      name="grossCommissionIncome"
                      class="form-control text-right"
                      required />
            </div>

         </div>

         <div class="col-sm-4">

            <div class="form-group"
                 [ngClass]="{'error': listingFee.invalid && (listingFee.dirty || listingFee.touched || mouseoverPropertyDetailSubmit)}">
               <span class="fake-label">Listing Fee</span>
               <em *ngIf="listingFee.invalid && (listingFee.dirty || listingFee.touched || mouseoverPropertyDetailSubmit)" class="error-msg">
                  Req
               </em>
               <input formControlName="listingFee"
                      type="text"
                      currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }"
                      name="listingFee"
                      class="form-control text-right" />
            </div>

         </div>

      </div>

      <div class="row mt-2">

         <div class="col-sm-2 mt-3">

            <div class="form-group mt-4">
               <div class="pretty p-svg p-curve p-pulse">
                  <input type="checkbox"
                         [checked]="hasReferral"
                         id="hasReferral"
                         (change)="setHasReferral($event.target.checked)"
                         [disabled]="isCancelled || property?.info.isReferral || !canEdit"/>
                  <div class="state p-success">
                     <svg class="svg svg-icon" viewBox="0 0 20 20">
                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                     </svg>
                     <label>Has Referral?</label>
                  </div>
               </div>
            </div>

         </div>

         <div class="col-sm-3">

            <div class="form-group"
                 [hidden]="!hasReferral"
                 [ngClass]="{'error': referralPartner.invalid && (referralPartner.dirty || referralPartner.touched || mouseoverPropertyDetailSubmit)}">
               <span for="referralPartner" class="fake-label">Referral Partner</span>
               <em *ngIf="referralPartner.invalid && (referralPartner.dirty || referralPartner.touched || mouseoverPropertyDetailSubmit)" class="error-msg">
                  Required
               </em>
               <input formControlName="referralPartner"
                      id="referralPartner"
                      type="text"
                      class="form-control" />
            </div>

         </div>

         <div class="col-sm-2">

            <div class="form-group"
                 [hidden]="!hasReferral"
                 [ngClass]="{'error': referralPaidOut.invalid && (referralPaidOut.dirty || referralPaidOut.touched || mouseoverPropertyDetailSubmit)}">
               <span class="fake-label">Ref Paid Out</span>
               <em *ngIf="referralPaidOut.invalid && (referralPaidOut.dirty || referralPaidOut.touched || mouseoverPropertyDetailSubmit)" class="error-msg">
                  Req
               </em>
               <input formControlName="referralPaidOut"
                      type="text"
                      currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }"
                      name="referralPaidOut"
                      class="form-control text-right" />
            </div>

         </div>

         <div class="col-sm-3 mt-3 text-right">

            <div class="form-group mt-4">
               <div class="pretty p-svg p-curve p-pulse">
                  <input type="checkbox"
                         [checked]="isCancelled"
                         (change)="setIsCancelled($event.target.checked)"
                         [disabled]="!canEdit" />
                  <div class="state p-success">
                     <svg class="svg svg-icon" viewBox="0 0 20 20">
                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                     </svg>
                     <label>Deal Cancelled?</label>
                  </div>
               </div>
            </div>

         </div>

         <div class="col-sm-2 col-button text-center">

            <span (mouseenter)="mouseoverPropertyDetailSubmit=true" (mouseleave)="mouseoverPropertyDetailSubmit=false">
               <app-button [buttonConfig]="{textChange: ['Update']}"
                           [isLoading]="isSubmitting"
                           [disabled]="!propertyDetailsForm.valid || isSubmitting"
                           (textBtnClickEmt)="updatePropertyDetails()">
               </app-button>
            </span>

         </div>

      </div>

   </form>

</div>
