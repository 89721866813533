import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface'
import { State } from '@appState/reducers'
import * as fromStore from '@appState/selectors/property.selectors'
import { PropertyPageActions } from '@appState/actions/property'

@Injectable({
   providedIn: 'root'
})
export class PropertyFacade {
   properties$: Observable<IProperty[]> = this._store.select(
      fromStore.getProperties
   )

   propertiesSearchFilter$: Observable<string> = this._store.select(
      fromStore.getPropertySearchFilter
   )

   propertiesSearchIsRelevant$: Observable<boolean> = this._store.select(
      fromStore.getPropertySearchIsRelevant
   )

   filteredProperties$: Observable<IProperty[]> = this._store.select(
      fromStore.getFilteredProperties$
   )

   constructor(private _store: Store<State>) { }

   loadProperties(): void {
      this._store.dispatch(PropertyPageActions.loadProperties())
   }

   setPropertiesSearchFilter(searchFilter?: string): void {
      this._store.dispatch(
         PropertyPageActions.setPropertiesSearchFilter({
            searchFilter
         })
      )
   }

   setPropertiesIsRelevant(isRelevant?: boolean): void {
      this._store.dispatch(
         PropertyPageActions.setPropertiesIsRelevant({
            isRelevant
         })
      )
   }

   updatePropertyInPropertiesSearch(property?: IProperty): void {
      if (property) {
         this._store.dispatch(
            PropertyPageActions.updatePropertyInPropertiesSearch({
               property
            })
         )
      }
   }

   prefetchProperties(properties: IProperty[]): void {
      // if haven't gotten store yet, go get it
      if (!properties) {
         this.loadProperties()
      }
   }
}
