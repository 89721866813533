import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core'
import { NgIf, AsyncPipe } from '@angular/common'
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router'
import { Observable, tap } from 'rxjs'
import { LoadingService } from '@appShared/services/loading.service'

//https://blog.angular-university.io/angular-loading-indicator/
@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.less'],
    standalone: true,
    imports: [NgIf, AsyncPipe]
})
export class LoadingIndicatorComponent implements OnInit {

   loading$: Observable<boolean>
   //isLoading: boolean

   @Input()
   detectRouteTransitions = false;

   @ContentChild("loading")
   customLoadingIndicator: TemplateRef<any> | null = null;

   constructor(
      private loadingService: LoadingService,
      private router: Router) {
      this.loading$ = this.loadingService.loading$

   }

   ngOnInit() {
      if (this.detectRouteTransitions) {
         this.router.events
            .pipe(
               tap((event) => {
                  if (event instanceof RouteConfigLoadStart) {
                     this.loadingService.loadingOn()
                  } else if (event instanceof RouteConfigLoadEnd) {
                     this.loadingService.loadingOff()
                  }
               })
            )
            .subscribe()
      }
   }
}
