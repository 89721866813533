import { Component, Input } from '@angular/core'
import { AddAgentComponent } from '@appAgent/add-agent/add-agent.component';
import { ModalComponent } from '@appShared/components/modal/modal.component'

@Component({
    template: `
    <app-modal title="Add New Agent" titleContainerName="add-agent" [showFooter]="false">
      <app-add-agent></app-add-agent>
    </app-modal>`,
    standalone: true,
    imports: [ModalComponent, AddAgentComponent]
})
export class AddAgentModalComponent {
   constructor() { }
}
