import { AfterViewInit, Component } from '@angular/core'
import { Observable } from 'rxjs'
import { RouterOutlet } from '@angular/router';
import { InternalHeaderComponent } from '@appShared/components/internal-header/internal-header.component';
//import { IUser } from '@appShared/interfaces/[Interface-based]/user.interface'
//import { AppFacade } from '@appShared/services/app.facade'

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    standalone: true,
    imports: [InternalHeaderComponent, RouterOutlet]
})
export class ShellComponent implements AfterViewInit {
   //userProfile$: Observable<IProfile>
   title = 'SWFC - LEAD'
   currentYear = new Date().getFullYear()
   sidebarToggle: HTMLElement

   constructor(/*appFacade: AppFacade*/) {
      //this.userProfile$ = appFacade.userProfile$
   }

   ngAfterViewInit() {
      this.sidebarToggle = document.getElementById('sidebar-toggle')
      if (this.sidebarToggle) {
         //Uncomment Below to persist sidebar toggle between refreshes
         //if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
         //   document.body.classList.toggle('sb-sidenav-toggled')
         //}
         this.sidebarToggle.addEventListener('click', event => {
            event.preventDefault()
            document.body.classList.toggle('sb-sidenav-toggled')
            //localStorage.setItem(
            //   'sb|sidebar-toggle',
            //   document.body.classList.contains('sb-sidenav-toggled').toString()
            //)
         })
      }

      const sidebarWrapperLinks = document.body.querySelectorAll(
         '#sidebar-wrapper li > a'
      )
      if (sidebarWrapperLinks) {
         sidebarWrapperLinks.forEach(link => {
            link.addEventListener('click', event => {
               //only toggle sidebar if toggle button is visible (has offsetParent)
               if (this.sidebarToggle.offsetParent) {
                  document.body.classList.toggle('sb-sidenav-toggled')
                  //localStorage.setItem(
                  //   'sb|sidebar-toggle',
                  //   document.body.classList
                  //      .contains('sb-sidenav-toggled')
                  //      .toString()
                  //)
               }

               // issue with tooltips that won't go away when a link is clicked - remove it
               let tooltips = document.querySelectorAll("div[role='tooltip']")
               tooltips.forEach(item => item.remove())
            })
         })
      }
   }
}
