import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { combineLatest, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import { PropertyFacade } from './property.facade'
import { ToastrType } from '@appShared/services/toastr.service'

@Injectable({ providedIn: 'root' })
export class PropertyListingsGuardService {
   constructor(
      private _commonService: CommonService,
      private _propertyFacade: PropertyFacade,
      private _appFacade: AppFacade,
      private _router: Router,
   ) { }

   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

      return combineLatest({
         siteConfig: this._appFacade.siteConfig$,
         isMaintenanceMode: this._appFacade.isMaintenanceMode$
      }).pipe(
         take(1),
         switchMap(({ siteConfig, isMaintenanceMode}) => {

            const userProfile = siteConfig.userProfile;

            if (!(userProfile?.objectId)) {
               const returnUrl = this._commonService.getResolvedUrl(route)
               this._commonService.navigateToLogin(returnUrl)
               return of(false)
            } else if (!(userProfile?.isAuthorized)) {
               this._commonService.messageUser(
                  'Not Authorized to view this page',
                  'Document Management Site Administration',
                  ToastrType.error
               )
               window.location.href = siteConfig.unauthorizedRedirectUri
               return of(false)
            }
            // if (!isMaintenanceMode) {
            //    // if (userProfile?.hasSubordinates) {
            //    //    // hasSubordinates have to verify SSN first
            //    //    this._router.navigate([''])
            //    //    return of(false)
            //    // }

            //    // this._commonService.messageUser(
            //    //  'Not Authorized to view this page',
            //    //  'Document Management Site Administration',
            //    //  ToastrType.error
            //    // )
            //    this._router.navigate([''])
            //    return of(false)
            // } else {
            //    // site offline - redirect home
            //    this._router.navigate([''])
            //    return of(false)
            // }

            // prefetch Observable<User[]>
            return this._propertyFacade.properties$.pipe(
               tap(properties =>
                  this._propertyFacade.prefetchProperties(properties)
               ),
               filter(data => !!data), // filter till has data
               take(1), // now that is has filtered data - take 1
               switchMap(() => of(true)),
               catchError(() => of(false))
            )
         }),
         catchError(() => of(false))
      )
   }
}
