<!-- Footer-->
<footer class="footer py-3">
   <div class="container">
      <div class="d-flex justify-content-between align-items-center font-bold">
         <div>
            &copy; {{currentYear}} Southwestern Real Estate, Inc. All rights reserved.
   
            | <a href="https://swrealestate.com/about/" target="_blank">About</a>
   
            | <a href="https://swrealestate.com/contact/" target="_blank">Contact</a>
         </div>
   
         <ul class="list-inline mb-0">
            <li class="list-inline-item me-3">
               <a href="https://www.facebook.com/southwesternrealestate" target="_blank">
                  <i class="fab fa-facebook fa-2x fa-fw"></i>
               </a>
            </li>
            <li class="list-inline-item">
               <a href="https://www.instagram.com/sw.realestate/" target="_blank">
                  <i class="fab fa-instagram fa-2x fa-fw"></i>
               </a>
            </li>
         </ul>
      </div>
   </div>
</footer>
