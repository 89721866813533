import { Inject, Injectable } from '@angular/core'
import { MOMENT_TOKEN } from './moment.service'
import { FormControl } from '@angular/forms'
import { Moment } from 'moment/moment'

export interface IStartEndDateVerifcation {
  startDate: {
    control: FormControl
    formValue: Date | null
  }
  endDate: {
    control: FormControl
    formValue: Date | null
  }
  incomingDate: Date | null
  incomingDateIsStartDate: boolean
}

export interface IStartEndDateVerificationResult {
  startDate: Date | null
  endDate: Date | null
}


@Injectable({ providedIn: 'root' })
export class DateTimeService {
  constructor(@Inject(MOMENT_TOKEN) public moment: any) { }

  formatDate(date?, format?: string, disallowNull?: boolean): any {
    date = this.getMomentDate(date, disallowNull)
    return date ? date.format(format || 'MM/DD/YYYY') : null
  }

  getMomentDate(date?: string | Date, disallowNull?: boolean): Moment | null {
    let returnMoment

    if (date) {
      returnMoment = this.moment(date, 'M/D/YYYY')
    }

    return returnMoment && returnMoment.isValid()
      ? returnMoment
      : disallowNull
        ? this.moment()
        : null
  }

  startEndDateVerification(startEndDates: IStartEndDateVerifcation): IStartEndDateVerificationResult {
    const isStartDate = startEndDates.incomingDateIsStartDate
    const incomingDate = startEndDates.incomingDate
    const inputStartDate = isStartDate
      ? incomingDate
      : startEndDates.startDate.formValue
    const inputEndDate = isStartDate
      ? startEndDates.endDate.formValue
      : incomingDate
    let startDate
    let endDate

    if (inputStartDate || inputEndDate) {
      startDate = this.getMomentDate(inputStartDate)
      endDate = this.getMomentDate(inputEndDate)

      if (startDate && endDate) {
        if (startDate.isAfter(endDate) || endDate.isBefore(startDate)) {
          if (isStartDate) {
            endDate = null
            startEndDates.endDate.control.setValue(null)
          } else {
            startDate = null
            startEndDates.startDate.control.setValue(null)
          }
        }
      }
    }

    return {
      startDate: startDate?._d,
      endDate: endDate?._d
    } as IStartEndDateVerificationResult
  }

  getMomentDateWithoutTime(date: any): Moment {
    if (date) {
      const m: Moment = this.moment(date)
      if (m) {
        return this.moment({
          year: m.year(),
          month: m.month(),
          day: m.date()
        })
      }
    }
    return null
  }

  getDateWithoutTime(date: any): Date {
    const m: Moment = this.getMomentDateWithoutTime(date)
    if (m) {
      return m.toDate()
    }
    return null
  }

}

