import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { IAgent } from '@appShared/interfaces/[Model-based]/agent.interface'
import { State } from '@appState/reducers'
import * as fromStore from '@appState/selectors/agent.selectors'
import { AgentPageActions } from '@appState/actions/agent'

@Injectable({
   providedIn: 'root'
})
export class AgentFacade {
   agents$: Observable<IAgent[]> = this._store.select(
      fromStore.getAgents
   )

   agentsSearchFilter$: Observable<string> = this._store.select(
      fromStore.getAgentSearchFilter
   )

   agentsIncludeInactive$: Observable<boolean> = this._store.select(
      fromStore.getAgentsIncludeInactive
   )

   filteredAgents$: Observable<IAgent[]> = this._store.select(
      fromStore.getFilteredAgents$
   )

   constructor(private _store: Store<State>) { }

   loadAgents(): void {
      this._store.dispatch(AgentPageActions.loadAgents())
   }

   setAgentsSearchFilter(searchFilter?: string): void {
      this._store.dispatch(
         AgentPageActions.setAgentsSearchFilter({
            searchFilter
         })
      )
   }

   setAgentsIncludeInactive(includeInactive?: boolean): void {
      this._store.dispatch(
         AgentPageActions.setAgentsIncludeInactive({
            includeInactive
         })
      )
   }

   prefetchAgents(agents: IAgent[]): void {
      // if haven't gotten store yet, go get it
      if (!agents) {
         this.loadAgents()
      }
   }
}
