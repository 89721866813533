import { FormControl } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import {
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   ViewChild
} from '@angular/core'
import {
   debounceTime,
   distinctUntilChanged,
   filter,
   switchMap,
   takeUntil,
   tap
} from 'rxjs/operators'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CommonService } from '@appShared/services/common.service'
import {
   /*AgentEditSectionData,
   AgentEditSection_,*/
   AdminService
} from '@appShared/services/admin.service'
import { AgentFacade } from '@appShared/services/agent.facade'
import { ClosedPropertiesExportComponent } from './closed-properties-export.component'
import { ModalComponent } from '@appShared/components/modal/modal.component'

@Component({
    template: `
    <app-modal title="Export Closed Properties" titleContainerName="closed-properties" [showFooter]="false">
      <app-closed-properties-export></app-closed-properties-export>
    </app-modal>`,
    standalone: true,
    imports: [ModalComponent, ClosedPropertiesExportComponent]
})
export class ClosedPropertiesExportModalComponent {
   //@Output() agentSectionUpdated = new EventEmitter();
   private _ngDestroyed$ = new Subject();

   constructor(
      private _agentFacade: AgentFacade
      //private _modalService: NgbModal,
      //private _activeModal: NgbActiveModal
   ) { }

   ngOnInit(): void {
      this._agentFacade.prefetchAgents(null)
   }

   ngOnDestroy(): void {
      this._ngDestroyed$.next(true)
      this._ngDestroyed$.complete()
   }
   /*
    * private methods
    * */

   /*
    * public methods
    * */


}
