import {
   Component,
   ChangeDetectionStrategy,
   OnInit,
   ViewChild,
   OnDestroy
} from '@angular/core'
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'
import { CommonService } from '@appShared/services/common.service'
import { ConfirmOptions, ConfirmService } from '@appShared/components/confirm-modal-and-service'
import { ToastrType } from '@appShared/services/toastr.service'
import { AppFacade } from '@appShared/services/app.facade'
import { PropertyFacade } from '@appShared/services/property.facade'
import { IProfile } from '@appShared/interfaces/[Interface-based]/profile.interface'
import { IProperty, IPropertyInfo } from '@appShared/interfaces/[Model-based]/property.interface'
import { PropertyEditModalComponent } from '@appProperty/property-edit/property-edit-modal/property-edit-modal.component'
import { PropertyType_ } from '@appShared/services/lookup/[CodeGen]/property-type.domain'
import { TransactionType_ } from '@appShared/services/lookup/[CodeGen]/transaction-type.domain'
import { PropertyEditSectionData, SiteService } from '@appShared/services'
import { ClosedPropertiesExportModalComponent } from '@appComponents/property/closed-properties-export/closed-properties-export-modal.component'
//import { IAgent } from '@appShared/interfaces/[Model-based]'
import { SafePipe } from '@appShared/filters/safe.pipe';
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service'
import { AutofocusDirective } from '@appShared/directives/auto-focus.directive'

@Component({
    selector: 'app-property-listings',
    templateUrl: './property-listings.component.html',
    styleUrls: ['./property-listings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, AutofocusDirective, ReactiveFormsModule, NgFor, NgClass, ConfirmTemplateDirective, ConfirmModalComponent, AsyncPipe, SafePipe]
})
export class PropertyListingsComponent implements OnInit, OnDestroy {
   userProfile: IProfile
   @ViewChild(DatatableComponent, { static: true })
   table: DatatableComponent
   private _ngDestroyed$ = new Subject()
   private _isMaintenanceMode: boolean
   private _isInitialLoad = true
   isMaintenanceMode$: Observable<boolean>
   filteredProperties$: Observable<IProperty[]>
   propertiesSearchFilter$: Observable<string>
   propertiesSearchIsRelevant$: Observable<boolean>
   propertyType = PropertyType_
   propertiesSearchFilterInput: FormControl
   private _searchFilter: string
   isOpenSubmittedCheckbox: FormControl
   hitMessage = ''
   /* grid */
   ColumnMode = ColumnMode

   constructor(
      private _appFacade: AppFacade,
      private _siteService: SiteService,
      private _propertyFacade: PropertyFacade,
      private _commonService: CommonService,
      private _confirmService: ConfirmService,
      private _modalService: NgbModal
   ) {
      this._appFacade.profile$.pipe(
         take(1)
      ).subscribe(userProfile => {
         this.userProfile = userProfile
      })

      this.isMaintenanceMode$ = this._appFacade.isMaintenanceMode$.pipe(
         tap(isMaintenanceMode => (this._isMaintenanceMode = isMaintenanceMode))
      )
   }

   ngOnInit(): void {
      this.propertiesSearchFilter$ = this._propertyFacade.propertiesSearchFilter$.pipe(
         tap(searchFilter => {
            this._searchFilter = searchFilter
         })
      )

      this.propertiesSearchIsRelevant$ = this._propertyFacade.propertiesSearchIsRelevant$.pipe(
         tap(isRecent => {
            console.log('Getting Properties', `${(new Date()).getTime()} isRecent: ${isRecent}`)
            if (!this._isInitialLoad) {
               this._propertyFacade.loadProperties()
            } else {
               this._isInitialLoad = false
            }
         })
      )

      this.filteredProperties$ = this._propertyFacade.filteredProperties$.pipe(
         tap(filteredProperties => {
            const hitCount = filteredProperties.length
            if (hitCount) {
               this.hitMessage = this._searchFilter.trim()
                  ? `${hitCount} record(s) found matching: '${this._searchFilter}'`
                  : `Viewing ${hitCount} record(s)`
            } else {
               this.hitMessage = `No records found matching: '${this._searchFilter}'`
            }
         })
      )
      this.propertiesSearchFilterInput = new FormControl()
      this.propertiesSearchFilterInput.valueChanges.pipe(
         takeUntil(this._ngDestroyed$),
         tap(_ => this._setLoadingMessage()),
         debounceTime(400),
         distinctUntilChanged()
      ).subscribe(searchFilter => {
         this._propertyFacade.setPropertiesSearchFilter(searchFilter)
      })

      this.isOpenSubmittedCheckbox = new FormControl(true)
      this.isOpenSubmittedCheckbox.valueChanges.pipe(
         takeUntil(this._ngDestroyed$),
         tap(_ => this._setLoadingMessage()),
         debounceTime(400)
      ).subscribe(checked => {
         this._propertyFacade.setPropertiesIsRelevant(checked)
      })
   }

   ngOnDestroy(): void {
      this._ngDestroyed$.next(true)
      this._ngDestroyed$.complete()
   }

   /*
    * private methods
    * */

   private _updateProperty(propertyEditSectionData: PropertyEditSectionData): void {
      const property = propertyEditSectionData?.property;
      if (property) {
         this._propertyFacade.updatePropertyInPropertiesSearch(property);
      }
   }

   private _showPropertyEditModal(property: IProperty): void {
      const poppertyEditModalRef = this._modalService.open(
         PropertyEditModalComponent,
         { scrollable: true, windowClass: 'my-modal-lg' }
      )
      const propertyEditComponent: PropertyEditModalComponent = poppertyEditModalRef.componentInstance
      propertyEditComponent.property = property
      propertyEditComponent.userProfile = this.userProfile
      propertyEditComponent.refreshGrid = this.refreshGrid.bind(this)
      //propertyEditComponent.propertyUpdated = this._updateProperty.bind(this)
   }

   private _setLoadingMessage(msg?: string) {
      this.hitMessage = msg || '<span class="fw-normal pb-1">Loading properties</span><img src="/assets/images/processing.gif" class="processing-gif ps-2"/>'
   }

   /*
    * public methods
    * */

   editProperty(property: IProperty, evt): void {
      /*
       adding this blur method to resolve known ngx-datatable issue and NgbModal
       https://github.com/swimlane/ngx-datatable/issues/721#issuecomment-389634571
       "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        Previous value: 'datatable-body-cell custom sort-active active'"
      */
      // evt.target.parentElement.parentElement.blur();

      this._showPropertyEditModal({ ...property })
   }

   createProperty(): void {

      this._showPropertyEditModal({
         id: 0,
         transactionTypeCode: TransactionType_.Seller,
         info: {} as IPropertyInfo
      } as IProperty)
   }

   refreshGrid(): void {
      this._propertyFacade.loadProperties()
   }

   viewGoogleMap(property: IProperty): void {
      if (property) {
         window.open(property.info.mappedAddressUri, '_blank')
      }
   }

   toggleOffline(): void {
      const maintenanceMode = this._isMaintenanceMode
      const maintenanceModeConfirm = maintenanceMode
         ? {
            header: 'Bringing Site Back ONLINE',
            display:
               'bring the site back <span class="text-success font-weight-bold">ONLINE</span>...',
            postToastr: {
               msg: 'Site back ONLINE',
               type: ToastrType.success
            }
         }
         : {
            header: 'Taking Site OFFLINE',
            display:
               'take the site <span class="text-error font-weight-bold">OFFLINE</span>...',
            postToastr: {
               msg: 'Site taken OFFLINE',
               type: ToastrType.info
            }
         }

      this._confirmService
         .confirm({
            title: `${maintenanceModeConfirm.header}!`,
            message: `You are about to
            ${maintenanceModeConfirm.display}
            <p class="mt-2 text-error font-weight-bold">Are you sure?<p>`
         } as ConfirmOptions)
         .then(() => {
            this._siteService.toggleIsMaintenanceMode(!maintenanceMode)

            this._commonService.messageUser(
               maintenanceModeConfirm.postToastr.msg,
               null,
               maintenanceModeConfirm.postToastr.type
            )
         })
         .catch(() => {
            /* Do Nothing */
         })
   }

   propertyTrackBy(index, property: IProperty) {
      if (!property) return null
      return property?.id
   }

   showClosedPropertyModal(): void {
      this._modalService.open(ClosedPropertiesExportModalComponent, {
         scrollable: true,
         windowClass: 'my-modal-md'
      })
   }
}
