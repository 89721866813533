import { createAction, props } from '@ngrx/store'

const pageLevelEventPrefix = '[Agent Page Action]'

export const loadAgents = createAction(
   `${pageLevelEventPrefix} Load Agents`
)

export const setAgentsSearchFilter = createAction(
   `${pageLevelEventPrefix} Set Agents Search Filter`,
   props<{ searchFilter: string }>()
)

export const setAgentsIncludeInactive = createAction(
   `${pageLevelEventPrefix} Set Agents Include Inactive`,
   props<{ includeInactive: boolean }>()
)
