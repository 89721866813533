<div class="modal-header">
   <div class="modal-title" id="modal-basic-title">
      <h2 class="d-inline-block">Confirm Submit to Accounting</h2>
      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
   </div>
</div>

<div class="modal-body">

   <table class="table" *ngIf="property">
      <tr *ngIf="closing">
         <td class="text-right">Closing Date:</td>
         <td>
            {{closing}}
         </td>
      </tr>
      <tr><td colspan="2"><hr class="m-0" /></td></tr>
      <tr *ngIf="property.salePrice">
         <td class="text-right">Sale Price:</td>
         <td>
            {{property.salePrice|currency}}
         </td>
      </tr>
      <tr *ngIf="property.grossCommissionIncome">
         <td class="text-right">GCI:</td>
         <td>
            {{property.grossCommissionIncome|currency}}
         </td>
      </tr>
      <tr><td colspan="2"><hr class="m-0" /></td></tr>
      <tr>
         <td class="text-right">Client Info:</td>
         <td>
            <strong>{{primaryContact.info.displayName}}</strong><br />
            <div *ngIf="primaryContact.emailAddress"
                 class="d-inline-block">email: {{primaryContact.emailAddress}}</div>
            <div *ngIf="primaryContact.phoneNumber"
                 class="d-inline-block">phone: {{primaryContact.phoneNumber}}</div>
         </td>
      </tr>
      <tr *ngIf="secondaryContact">
         <td class="text-right">&nbsp;</td>
         <td>
            <strong>{{secondaryContact.info.displayName}}</strong><br />
            <div *ngIf="secondaryContact.emailAddress"
                 class="d-inline-block">email: {{secondaryContact.emailAddress}}</div>
            <div *ngIf="secondaryContact.phoneNumber"
                 class="d-inline-block">phone: {{secondaryContact.phoneNumber}}</div>
         </td>
      </tr>
   </table>
   <div class="mt-3">
      <label for="message" class="form-label">Message to Accounting: <small><i>(optional)</i></small></label>
      <textarea class="form-control" id="message" #messageTextArea [(ngModel)]="message"></textarea>
   </div>

</div>

<div class="modal-footer">
   <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Cancel</button>
   <button type="button" class="btn btn-site-primary" (click)="submitToAccounting()">Confirm and Submit</button>
</div>
