import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { NgIf, CurrencyPipe } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
   PropertyEditSectionData,
   PropertyService
} from '@appShared/services/property.service';
import { CommonService } from '@appShared/services/common.service'
import {
   IProperty,
   IPropertyContact,
   IPropertyWorkflow
} from '@appShared/interfaces/[Model-based]'
import { PropertyWorkflowAction_ } from '@appShared/services/lookup/[CodeGen]/property-workflow-action.domain'

@Component({
    selector: 'app-property-edit-submit-modal',
    templateUrl: './property-edit-submit-modal.component.html',
    styleUrls: ['./property-edit-submit-modal.component.less'],
    standalone: true,
    imports: [NgIf, FormsModule, CurrencyPipe]
})
export class PropertyEditSubmitModalComponent implements OnInit, AfterViewInit {
   @Input() property: IProperty;
   @Input() propertyReset: (propertyEditSection: PropertyEditSectionData) => void;
   @Input() closeFunction: () => void | null;
   @Input() dismissFunction: () => void | null;

   @ViewChild('messageTextArea') messageTextAreaRef: ElementRef

   closing: string
   primaryContact: IPropertyContact
   secondaryContact: IPropertyContact
   message: string

   constructor(
      private _activeModal: NgbActiveModal,
      private _commonService: CommonService,
      private _propertyService: PropertyService
   ) {

   }

   ngOnInit() {
      const property = this.property
      this.closing = this._commonService.dateTime.formatDate(property.closing, 'MMM Do, YYYY', false)
      this.primaryContact = property.propertyContacts.find(prop => prop.isPrimary)
      //pull first non-primary contact
      this.secondaryContact = property.propertyContacts.find(prop => !prop.isPrimary);
   }

   ngAfterViewInit() {
      this.messageTextAreaRef.nativeElement.focus()
   }

   /*
    * private methods
    * */

   /*
    * public methods
    * */

   //propertyReset(propertyEditSection: PropertyEditSectionData): void {
   //   if (propertyEditSection.refresh) {
   //      this.refreshGrid()
   //   }
   //}

   submitToAccounting(): void {

      const propertyWorkflow: IPropertyWorkflow = {
         actionCode: PropertyWorkflowAction_.SubmittedToAccounting,
         comment: this.message,
         propertyId: this.property.id
      } as IPropertyWorkflow

      this._propertyService
         .submitToAccouting(this.property.id, propertyWorkflow)
         .then(property => {
            this._commonService.messageUser('Submitted to Accounting!')

            this.propertyReset({
               property: property,
               refresh: true
            } as PropertyEditSectionData)

            this.close();
         })
         .catch(() => {
            /* Do Nothing */
         });
   }

   dismiss(): void {
      this._activeModal.dismiss(this.closeFunction);
   }

   close(): void {
      this._activeModal.close(this.dismissFunction);
   }
}
