import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { lastValueFrom, Observable } from 'rxjs'
import { filter, take, tap } from 'rxjs/operators'
import { AppFacade } from '@appShared/services/app.facade'
import { CommonService } from '@appShared/services/common.service'
import { Store } from '@ngrx/store'
import { State } from '@appState/index'
import { AppActions } from '@appState/actions'
import { IProfile } from '@appShared/interfaces/[Interface-based]/profile.interface'
import { ISiteConfiguration } from '@appShared/interfaces/[Interface-based]/site-configuration.interface'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AuthService {
   private _authApi = '/auth'
   private _siteConfig: ISiteConfiguration

   // https://stackoverflow.com/a/71961946/1146117
   private _httpOptions = {}

   constructor(
      commonService: CommonService,
      private _store: Store<State>,
      private _httpClient: HttpClient,
      private _appFacade: AppFacade
   ) {
      this._httpOptions = commonService.httpPostOptions()


      _appFacade.siteConfig$
         .pipe(
            tap(siteConfig => _appFacade.prefetchSiteConfig(siteConfig)),
            filter(data => !!data), // filter till has data
            take(1) // now that is has filtered data - take 1
         )
         .subscribe(config => (this._siteConfig = config))
   }

   initializeSiteConfig(): Observable<ISiteConfiguration> {
      return this._httpClient.get<ISiteConfiguration>('api/config'/*, this._httpOptions*/).pipe(
         take(1),
         tap(siteConfig => {

            // override our environment
            if (siteConfig.siteUri) {
               environment.baseUri = siteConfig.siteUri
            }

            this._store.dispatch(AppActions.loadSiteConfigSuccess({ siteConfig }))
         })
      )
   }

   getSiteConfig(): Observable<ISiteConfiguration> {
      ////TODO - remove when we get DEV environment
     // return of({
     //    userProfile: {
     //       displayName: 'Bubba Licious',
     //       isAdmin: true,
     //       isAdminViewOnly: false,
     //       hasSubordinates: true,
     //       objectId: '1234'
     //    },
     //    isMaintenanceMode: false,
     //    propertyPlanBeginingYear: '',
     //    propertyPlanUri: 'TODO'
     //} as unknown as ISiteConfiguration)

      return this._httpClient.get<ISiteConfiguration>('api/config'/*, this._httpOptions*/)
   }

   async getCurrentProfile(): Promise<IProfile> {
      const url = `${this._authApi}/current-profile`

      const request$ = this._httpClient.get<IProfile>(url).pipe(
                        take(1),
                        tap(profile => {
                           console.log('setting updated profile in store', profile)
                           this._appFacade.setProfile(profile)
                        })
                       )

      return await lastValueFrom<IProfile>(request$)

      //return await this._httpClient
      //   .get<IProfile>(url)
      //   .pipe(
      //      tap(profile => {
      //         console.log('setting updated profile in store', profile)
      //         this._appFacade.setProfile(profile)
      //      })
      //   )
      //   .toPromise()
   }
}
