import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { lastValueFrom, Observable, of, switchMap, take, throwError } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { PropertyFacade } from './property.facade'
import {
   IProperty,
   IPropertyDocument,
   IPropertyContact,
   IPropertyWorkflow
} from '@appShared/interfaces/[Model-based]'
import { DocumentType_ } from '@appShared/services/lookup/[CodeGen]/document-type.domain'
import * as _ from 'lodash'

export enum PropertyEditSection_ {
   BasicInfo = 1,
   Contacts,
   Details,
   Documents,
   Workflows,
   Accounting
}

export const PropertyEditSections: PropertyEditSection[] = [
   { code: PropertyEditSection_.BasicInfo, uri: 'basic-info' } as PropertyEditSection,
   { code: PropertyEditSection_.Contacts, uri: 'contacts' } as PropertyEditSection,
   { code: PropertyEditSection_.Details, uri: 'details' } as PropertyEditSection,
   { code: PropertyEditSection_.Documents, uri: 'documents' } as PropertyEditSection,
   { code: PropertyEditSection_.Accounting, uri: 'accounting' } as PropertyEditSection
]

export class PropertyEditSection {
   code: PropertyEditSection_
   uri: string

   static toDomainModel(code: PropertyEditSection_): PropertyEditSection {
      return PropertyEditSections.find(d => d.code === code)
   }
}

export interface PropertyEditSectionData {
   propertyEditSection: PropertyEditSection_
   property: IProperty
   selectedPropertyContact?: IPropertyContact
   refresh: boolean
}

@Injectable({ providedIn: 'root' })
export class PropertyService {
   /* https://blogs.msmvps.com/deborahk/build-a-simple-angular-service-to-share-data/ */
   propertyApi = '/api/portal/properties'
   propertyDocumentBaseUri = `${this.propertyApi}/documents/`

   // https://stackoverflow.com/a/71961946/1146117
   private _httpOptions = {}

   //FAKE_PROPERTIES: IProperty[] = [
   //   {
   //      id: 1234,
   //      agentId: 123,
   //      clientName: 'Billy Boo',
   //      approved: '8/1/2019',
   //      closing: '10/1/2020',
   //      cancelled: '8/1/2020',
   //      isListingUnavailable: false,
   //      listingNumber: 'Z-789A-123',
   //      submitted: null,
   //      salePrice: 123456,
   //      listingFee: null,
   //      grossCommissionIncome: 2520.8,
   //      referralPartner: 'Jackie Chan',
   //      referralPaidOut: 456.23,
   //      street: '123 Main',
   //      city: 'Memphis',
   //      stateProvinceCode: 781,
   //      postalCode: '38018',
   //      transactionTypeCode: TransactionType_.Referral,
   //      transactionCount: 3,
   //      transactionWeight: 2,
   //      isAgentDirectPay: false,
   //      checkDepositAmount: 123.45,
   //      checkDeposited: '5/1/2023',
   //      commission: 45.23,

   //      propertyContacts: [
   //         {
   //            id: 123,
   //            propertyId: 1234,
   //            emailAddress: 'billyboo@server.com',
   //            firstName: 'Billy',
   //            lastName: 'Boo',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 1234 },
   //            info: { displayName: 'Billy Boo' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         {
   //            id: 234,
   //            propertyId: 1234,
   //            emailAddress: 'billyboo2@server.com',
   //            firstName: 'Billy2',
   //            lastName: 'Boo2',
   //            isPrimary: false,
   //            phoneNumber: '615-459-2222',
   //            property: { id: 1234 },
   //            info: { displayName: 'Billy2 Boo2' } as IPropertyContactInfo
   //         } as IPropertyContact
   //      ],
   //      propertyDocuments: [],
   //      info: {
   //         stateProvinceStandardCode: 'TN',
   //         isRecent: true,
   //         areDetailsComplete: true,
   //         areDocumentsComplete: true,
   //         hasContacts: true,
   //         addressCityStatePostal: 'Memphis, TN 38018',
   //         addressFullDisplay: '123 Main St., Memphis, TN 38018',
   //         addressMarkup: '123 Main<br/>Memphis, TN 38018',
   //         agentName: 'Ed Solima',
   //         mappedAddressUri:
   //            'https://www.google.com/maps/place/123+main,+memphis,+TN+38018',
   //         status: 'Submitted',
   //         statusIsRecent: true,
   //         primaryContact: {
   //            id: 123,
   //            propertyId: 1234,
   //            emailAddress: 'billyboo@server.com',
   //            firstName: 'Billy',
   //            lastName: 'Boo',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 1234 },
   //            info: { displayName: 'Billy Boo' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         secondaryContact: {
   //            id: 234,
   //            emailAddress: 'billyboo2@server.com',
   //            firstName: 'Billy2',
   //            lastName: 'Boo2',
   //            isPrimary: true,
   //            phoneNumber: '615-459-2222',
   //            property: { id: 234 },
   //            info: { displayName: 'Billy2 Boo2' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         propertyTypeDescription: 'Referral',
   //         transactionTypeDescription: 'Referral',
   //         contactsComplete: true,
   //         detailsComplete: true,
   //         isIncomplete: false
   //      } as IPropertyInfo
   //   } as IProperty,
   //   {
   //      id: 234,
   //      agentId: 234,
   //      clientName: 'Jackie Chan',
   //      approved: '8/1/2019',
   //      closing: '10/1/2020',
   //      cancelled: null,
   //      isListingUnavailable: true,
   //      listingNumber: '',
   //      typeCode: PropertyType_.Rental,
   //      submitted: '8/1/2016',
   //      salePrice: 123456,
   //      listingFee: 652,
   //      grossCommissionIncome: 2520.8,
   //      referralPartner: 'Jackie Chan',
   //      referralPaidOut: 456.23,
   //      street: '2451 Atrium Way',
   //      city: 'Nashville',
   //      stateProvinceCode: 781,
   //      postalCode: '37214',
   //      transactionTypeCode: TransactionType_.Landord,
   //      transactionCount: 3,
   //      transactionWeight: 2,
   //      isAgentDirectPay: false,
   //      checkDepositAmount: 123.45,
   //      checkDeposited: '5/1/2023',
   //      commission: 45.23,
   //      created: '8/1/2023',
   //      version: '',
   //      agent: {} as IAgent,
   //      posted: '9/1/2023',
   //      propertyContacts: [
   //         {
   //            id: 123,
   //            propertyId: 1234,
   //            emailAddress: 'Jackie@server.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 234 },
   //            info: { displayName: 'Jackie Boo' } as IPropertyContactInfo
   //         } as IPropertyContact
   //      ],
   //      propertyDocuments: [],
   //      info: {
   //         stateProvinceStandardCode: 'TN',
   //         isRecent: true,
   //         areDetailsComplete: true,
   //         areDocumentsComplete: true,
   //         hasContacts: true,
   //         addressCityStatePostal: 'Nashville, TN 37214',
   //         addressFullDisplay: '2451 Atrium Way, Nashville, TN 37214',
   //         addressMarkup: '2451 Atrium Way<br/>Nashville, TN 37214',
   //         agentName: 'Leonard Lewis',
   //         mappedAddressUri:
   //            'https://www.google.com/maps/place/2451+atrium_way,+Nashville,+TN+37214',
   //         status: 'Complete',
   //         statusIsRecent: true,
   //         primaryContact: {
   //            id: 123,
   //            emailAddress: 'billyboo@Chan.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 234 },
   //            info: { displayName: 'Jackie Chan' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         propertyTypeDescription: 'Rental',
   //         transactionTypeDescription: 'Buyer',
   //         contactsComplete: false,
   //         detailsComplete: true,
   //         isIncomplete: true
   //      } as IPropertyInfo
   //   } as IProperty,
   //   {
   //      id: 345,
   //      agentId: 345,
   //      clientName: 'Jackie Chan',
   //      approved: '8/1/2019',
   //      closing: '10/1/2020',
   //      cancelled: null,
   //      isListingUnavailable: false,
   //      listingNumber: 'Z-1234-A234',
   //      typeCode: PropertyType_.Sale,
   //      submitted: '8/1/2016',
   //      salePrice: 123456,
   //      listingFee: null,
   //      grossCommissionIncome: 2520.8,
   //      referralPartner: null,
   //      referralPaidOut: null,
   //      street: '1459 Stonegate Pass',
   //      city: 'Germantown',
   //      stateProvinceCode: 781,
   //      postalCode: '38138',
   //      transactionTypeCode: TransactionType_.BuyerSeller,
   //      transactionCount: 3,
   //      transactionWeight: 2,
   //      isAgentDirectPay: false,
   //      checkDepositAmount: 123.45,
   //      checkDeposited: '5/1/2023',
   //      commission: 45.23,
   //      created: '8/1/2023',
   //      version: '',
   //      agent: {} as IAgent,
   //      posted: '9/1/2023',
   //      propertyContacts: [
   //         {
   //            id: 987,
   //            propertyId: 345,
   //            emailAddress: 'szui@server.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Boo' } as IPropertyContactInfo
   //         } as IPropertyContact
   //      ],
   //      propertyDocuments: [],
   //      info: {
   //         stateProvinceStandardCode: 'TN',
   //         isRecent: true,
   //         areDetailsComplete: true,
   //         areDocumentsComplete: true,
   //         hasContacts: true,
   //         addressCityStatePostal: 'Germantown, TN 38138',
   //         addressFullDisplay: '1459 Stonegate Pass, Germantown, TN 38138',
   //         addressMarkup: '1459 Stonegate Pass<br/>Memphis, TN 38138',
   //         agentName: 'Jeff Young',
   //         mappedAddressUri:
   //            'https://www.google.com/maps/place/1459+Stonegate+Pass,+Germantown,+TN+38138',
   //         status: 'Open',
   //         statusIsRecent: false,
   //         primaryContact: {
   //            id: 123,
   //            emailAddress: 'billyboo@Chan.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Chan' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         propertyTypeDescription: 'Sale',
   //         transactionTypeDescription: 'Buyer',
   //         contactsComplete: true,
   //         detailsComplete: true,
   //         isIncomplete: false
   //      } as IPropertyInfo
   //   } as IProperty,

   //   {
   //      id: 345,
   //      agentId: 345,
   //      clientName: 'Jackie Chan',
   //      approved: '8/1/2019',
   //      closing: '10/1/2020',
   //      cancelled: null,
   //      isListingUnavailable: false,
   //      listingNumber: 'Z-1234-A234',
   //      typeCode: PropertyType_.Sale,
   //      submitted: '8/1/2016',
   //      salePrice: 123456,
   //      listingFee: null,
   //      grossCommissionIncome: 2520.8,
   //      referralPartner: null,
   //      referralPaidOut: null,
   //      street: '605 Enon Springs Rd West',
   //      city: 'Smyrna',
   //      stateProvinceCode: 781,
   //      postalCode: '38138',
   //      transactionTypeCode: TransactionType_.BuyerSeller,
   //      transactionCount: 3,
   //      transactionWeight: 2,
   //      isAgentDirectPay: false,
   //      checkDepositAmount: 123.45,
   //      checkDeposited: '5/1/2023',
   //      commission: 45.23,
   //      created: '8/1/2023',
   //      version: '',
   //      agent: {} as IAgent,
   //      posted: '9/1/2023',
   //      propertyContacts: [
   //         {
   //            id: 987,
   //            propertyId: 345,
   //            emailAddress: 'szui@server.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Boo' } as IPropertyContactInfo
   //         } as IPropertyContact
   //      ],
   //      propertyDocuments: [],
   //      info: {
   //         stateProvinceStandardCode: 'TN',
   //         isRecent: true,
   //         areDetailsComplete: true,
   //         areDocumentsComplete: true,
   //         hasContacts: true,
   //         addressCityStatePostal: 'Smyrna, TN 37167',
   //         addressFullDisplay: '605 Enon Springs Rd West, Smyrna, TN 37167',
   //         addressMarkup: '605 Enon Springs Rd West<br/>Smyrna, TN 37167',
   //         agentName: 'Jeff Young',
   //         mappedAddressUri:
   //            'https://www.google.com/maps/place/1459+Stonegate+Pass,+Smyrna,+TN+37167',
   //         status: 'Open',
   //         statusIsRecent: true,
   //         primaryContact: {
   //            id: 123,
   //            emailAddress: 'billyboo@Chan.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Chan' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         propertyTypeDescription: 'Sale',
   //         transactionTypeDescription: 'Buyer',
   //         contactsComplete: true,
   //         detailsComplete: true,
   //         isIncomplete: false
   //      } as IPropertyInfo
   //   } as IProperty,

   //   {
   //      id: 345,
   //      agentId: 345,
   //      clientName: 'Jackie Chan',
   //      approved: '8/1/2019',
   //      closing: '10/1/2020',
   //      cancelled: null,
   //      isListingUnavailable: false,
   //      listingNumber: 'Z-1234-A234',
   //      typeCode: PropertyType_.Sale,
   //      submitted: '8/1/2016',
   //      salePrice: 123456,
   //      listingFee: null,
   //      grossCommissionIncome: 2520.8,
   //      referralPartner: null,
   //      referralPaidOut: null,
   //      street: '308 Ella Street',
   //      city: 'Smyrna',
   //      stateProvinceCode: 781,
   //      postalCode: '38138',
   //      transactionTypeCode: TransactionType_.BuyerSeller,
   //      transactionCount: 3,
   //      transactionWeight: 2,
   //      isAgentDirectPay: false,
   //      checkDepositAmount: 123.45,
   //      checkDeposited: '5/1/2023',
   //      commission: 45.23,
   //      created: '8/1/2023',
   //      version: '',
   //      agent: {} as IAgent,
   //      posted: '9/1/2023',
   //      propertyContacts: [
   //         {
   //            id: 987,
   //            propertyId: 345,
   //            emailAddress: 'szui@server.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Boo' } as IPropertyContactInfo
   //         } as IPropertyContact
   //      ],
   //      propertyDocuments: [],
   //      info: {
   //         stateProvinceStandardCode: 'TN',
   //         isRecent: true,
   //         areDetailsComplete: true,
   //         areDocumentsComplete: true,
   //         hasContacts: true,
   //         addressCityStatePostal: 'Smyrna, TN 37167',
   //         addressFullDisplay: '308 Ella Street, Smyrna, TN 37167',
   //         addressMarkup: '308 Ella Street<br/>Smyrna, TN 37167',
   //         agentName: 'Jeff Young',
   //         mappedAddressUri:
   //            'https://www.google.com/maps/place/1459+Stonegate+Pass,+Memphis,+TN+38138',
   //         status: 'Open',
   //         statusIsRecent: true,
   //         primaryContact: {
   //            id: 123,
   //            emailAddress: 'billyboo@Chan.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Chan' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         propertyTypeDescription: 'Sale',
   //         transactionTypeDescription: 'Buyer',
   //         contactsComplete: true,
   //         detailsComplete: true,
   //         isIncomplete: false
   //      } as IPropertyInfo
   //   } as IProperty,

   //   {
   //      id: 345,
   //      agentId: 345,
   //      clientName: 'Jackie Chan',
   //      approved: '8/1/2019',
   //      closing: '10/1/2020',
   //      cancelled: null,
   //      isListingUnavailable: false,
   //      listingNumber: 'Z-1234-A234',
   //      typeCode: PropertyType_.Referral,
   //      submitted: '8/1/2016',
   //      salePrice: 123456,
   //      listingFee: null,
   //      grossCommissionIncome: 2520.8,
   //      referralPartner: null,
   //      referralPaidOut: null,
   //      street: '1459 Stonegate Pass',
   //      city: 'Germantown',
   //      stateProvinceCode: 781,
   //      postalCode: '38138',
   //      transactionTypeCode: TransactionType_.BuyerSeller,
   //      transactionCount: 3,
   //      transactionWeight: 2,
   //      isAgentDirectPay: false,
   //      checkDepositAmount: 123.45,
   //      checkDeposited: '5/1/2023',
   //      commission: 45.23,
   //      created: '8/1/2023',
   //      version: '',
   //      agent: {} as IAgent,
   //      posted: '9/1/2023',
   //      propertyContacts: [
   //         {
   //            id: 987,
   //            propertyId: 345,
   //            emailAddress: 'szui@server.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Boo' } as IPropertyContactInfo
   //         } as IPropertyContact
   //      ],
   //      propertyDocuments: [],
   //      info: {
   //         stateProvinceStandardCode: 'TN',
   //         isRecent: true,
   //         areDetailsComplete: true,
   //         areDocumentsComplete: true,
   //         hasContacts: true,
   //         addressCityStatePostal: 'Germantown, TN 38138',
   //         addressFullDisplay: '1459 Stonegate Pass, Germantown, TN 38138',
   //         addressMarkup: '1459 Stonegate Pass<br/>Germantown, TN 38138',
   //         agentName: 'Jeff Young',
   //         mappedAddressUri:
   //            'https://www.google.com/maps/place/1459+Stonegate+Pass,+Memphis,+TN+38138',
   //         status: 'Open',
   //         statusIsRecent: false,
   //         primaryContact: {
   //            id: 123,
   //            emailAddress: 'billyboo@Chan.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Chan' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         propertyTypeDescription: 'Referral',
   //         transactionTypeDescription: 'Buyer',
   //         contactsComplete: true,
   //         detailsComplete: true,
   //         isIncomplete: false
   //      } as IPropertyInfo
   //   } as IProperty,
   //   {
   //      id: 345,
   //      agentId: 345,
   //      clientName: 'Jackie Chan',
   //      approved: '8/1/2019',
   //      closing: '10/1/2020',
   //      cancelled: null,
   //      isListingUnavailable: false,
   //      listingNumber: 'Z-1234-A234',
   //      typeCode: PropertyType_.Referral,
   //      submitted: '8/1/2016',
   //      salePrice: 123456,
   //      listingFee: null,
   //      grossCommissionIncome: 2520.8,
   //      referralPartner: null,
   //      referralPaidOut: null,
   //      street: '1459 Stonegate Pass',
   //      city: 'Germantown',
   //      stateProvinceCode: 781,
   //      postalCode: '38138',
   //      transactionTypeCode: TransactionType_.BuyerSeller,
   //      transactionCount: 3,
   //      transactionWeight: 2,
   //      isAgentDirectPay: false,
   //      checkDepositAmount: 123.45,
   //      checkDeposited: '5/1/2023',
   //      commission: 45.23,
   //      created: '8/1/2023',
   //      version: '',
   //      agent: {} as IAgent,
   //      posted: '9/1/2023',
   //      propertyContacts: [
   //         {
   //            id: 987,
   //            propertyId: 345,
   //            emailAddress: 'szui@server.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Boo' } as IPropertyContactInfo
   //         } as IPropertyContact
   //      ],
   //      propertyDocuments: [],
   //      info: {
   //         stateProvinceStandardCode: 'TN',
   //         isRecent: true,
   //         areDetailsComplete: true,
   //         areDocumentsComplete: true,
   //         hasContacts: true,
   //         addressCityStatePostal: 'Memphis, TN 37214',
   //         addressFullDisplay: '1459 Stonegate Pass, Memphis, TN 38138',
   //         addressMarkup: '1459 Stonegate Pass<br/>Memphis, TN 38138',
   //         agentName: 'Jeff Young',
   //         mappedAddressUri:
   //            'https://www.google.com/maps/place/1459+Stonegate+Pass,+Memphis,+TN+38138',
   //         status: 'Open',
   //         statusIsRecent: true,
   //         primaryContact: {
   //            id: 123,
   //            emailAddress: 'billyboo@Chan.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Chan' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         propertyTypeDescription: 'Referral',
   //         transactionTypeDescription: 'Buyer',
   //         contactsComplete: true,
   //         detailsComplete: true,
   //         isIncomplete: false
   //      } as IPropertyInfo
   //   } as IProperty,
   //   {
   //      id: 345,
   //      agentId: 345,
   //      clientName: 'Jackie Chan',
   //      approved: '8/1/2019',
   //      closing: '10/1/2020',
   //      cancelled: null,
   //      isListingUnavailable: false,
   //      listingNumber: 'Z-1234-A234',
   //      typeCode: PropertyType_.Sale,
   //      submitted: '8/1/2016',
   //      salePrice: 123456,
   //      listingFee: null,
   //      grossCommissionIncome: 2520.8,
   //      referralPartner: null,
   //      referralPaidOut: null,
   //      street: '1459 Stonegate Pass',
   //      city: 'Memphis',
   //      stateProvinceCode: 781,
   //      postalCode: '38138',
   //      transactionTypeCode: TransactionType_.BuyerSeller,
   //      transactionCount: 3,
   //      transactionWeight: 2,
   //      isAgentDirectPay: false,
   //      checkDepositAmount: 123.45,
   //      checkDeposited: '5/1/2023',
   //      commission: 45.23,
   //      created: '8/1/2023',
   //      version: '',
   //      agent: {} as IAgent,
   //      posted: '9/1/2023',
   //      propertyContacts: [
   //         {
   //            id: 987,
   //            propertyId: 345,
   //            emailAddress: 'szui@server.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Boo' } as IPropertyContactInfo
   //         } as IPropertyContact
   //      ],
   //      propertyDocuments: [],
   //      info: {
   //         stateProvinceStandardCode: 'TN',
   //         isRecent: true,
   //         areDetailsComplete: true,
   //         areDocumentsComplete: true,
   //         hasContacts: true,
   //         addressCityStatePostal: 'Memphis, TN 37214',
   //         addressFullDisplay: '1459 Stonegate Pass, Memphis, TN 38138',
   //         addressMarkup: '1459 Stonegate Pass<br/>Memphis, TN 38138',
   //         agentName: 'Jeff Young',
   //         mappedAddressUri:
   //            'https://www.google.com/maps/place/1459+Stonegate+Pass,+Memphis,+TN+38138',
   //         status: 'Open',
   //         statusIsRecent: true,
   //         primaryContact: {
   //            id: 123,
   //            emailAddress: 'billyboo@Chan.com',
   //            firstName: 'Jackie',
   //            lastName: 'Chan',
   //            isPrimary: true,
   //            phoneNumber: '615-459-1245',
   //            property: { id: 345 },
   //            info: { displayName: 'Jackie Chan' } as IPropertyContactInfo
   //         } as IPropertyContact,
   //         propertyTypeDescription: 'Sale',
   //         transactionTypeDescription: 'Buyer',
   //         contactsComplete: true,
   //         detailsComplete: true,
   //         isIncomplete: false
   //      } as IPropertyInfo
   //   } as IProperty
   //]

   FAKE_CONTACTS: IPropertyContact[] = [
      {
         id: 123,
         propertyId: 1234,
         firstName: 'Billy',
         lastName: 'Jones',
         emailAddress: 'bjones@server.com',
         phoneNumber: '6154592038',
         isPrimary: false,
         info: { displayName: 'Bill Jones' }
      } as IPropertyContact,
      {
         id: 234,
         propertyId: 1234,
         firstName: 'Khaki \'THE DAWG\'',
         lastName: 'Long Pants',
         emailAddress: 'Khakisomelongemail@reallylongemail.com',
         phoneNumber: '6154591234',
         isPrimary: true,
         info: { displayName: 'Khaki \'THE DAWG\' Long Pants' }
      } as IPropertyContact,
      {
         id: 345,
         propertyId: 1234,
         firstName: 'Suzi',
         lastName: 'Chaptsick',
         emailAddress: 'Suzi@Chaptsick.com',
         phoneNumber: '6154599874',
         isPrimary: false,
         info: { displayName: 'Suzi Chaptsick' }
      } as IPropertyContact
   ]

   constructor(
      private _http: HttpClient,
      private _commonService: CommonService,
      private _propertyFacade: PropertyFacade
   ) {
      this._httpOptions = _commonService.httpPostOptions()
   }

   getProperties(): Observable<IProperty[]> {
      //return of(this.FAKE_PROPERTIES)

      return this._propertyFacade.propertiesSearchIsRelevant$
         .pipe(
            take(1),
            switchMap(isRelevant => {
               const url = isRelevant
                  ? `${this.propertyApi}`
                  : `${this.propertyApi}?includeNonRelevant=true`

               return this._http.get<IProperty[]>(url)
            })
         )
   }

   async getPropertiesAsync(): Promise<IProperty[]> {
      const request$ = this.getProperties().pipe(take(1))

      return await lastValueFrom<IProperty[]>(request$)
   }

   async getProperty(id: number): Promise<IProperty> {
      // TODO JY remove
      //const matchingProperty = this.FAKE_PROPERTIES.find(
      //   property => property.id === id
      //)
      //return await new Promise(resolve => {
      //   resolve({ ...matchingProperty } as IProperty)
      //})

      const url = `${this.propertyApi}/${id}`
      const request$ = this._http.get<IProperty>(url).pipe(take(1))

      return await lastValueFrom<IProperty>(request$)
   }

   async createUpdateProperty(property: IProperty, section?: PropertyEditSection_, approved?: boolean): Promise<IProperty> {
      if (!property) {
         return new Promise((resolve, reject) => reject('No Stock passed!'));
      }

      delete property.propertyContacts
      delete property.propertyDocuments

      let sectionUri = section ? `/${PropertyEditSection.toDomainModel(section)?.uri}/` : ''

      const baseUri = `${this.propertyApi}/${property.id}${sectionUri}`
      const url = property.id
                     ? approved == null
                        ? baseUri
                        : `${baseUri}?approved=${approved}`
                     : this.propertyApi;
      const options = this._commonService.httpPostOptions();
      let request$: Observable<IProperty>

      if (property.id) {
         /* update */
         request$ = this._http.put<IProperty>(url, property, options).pipe(take(1))
      } else {
         /* else create */
         request$ = this._http.post<IProperty>(url, property, options).pipe(take(1))
      }

      return await lastValueFrom<IProperty>(request$)
   }

   getPrimaryContacts(propertyId: number): Observable<IPropertyContact[]> {
      if (!propertyId) { return throwError('No propertyId passed!') }

      return of(this.FAKE_CONTACTS)

      // const url = `${this.propertyApi}/${propertyId}/contacts/`

      // return this._http.get<IPropertyContact[]>(url)
   }

   async getPrimaryContactsAsync(
      propertyId: number
   ): Promise<IPropertyContact[]> {
      const request$ = this.getPrimaryContacts(propertyId).pipe(take(1))

      return await lastValueFrom<IPropertyContact[]>(request$)
   }

   async getPropertyContact(propertyId: number, id: number): Promise<IPropertyContact> {
      const url = `${this.propertyApi}/${propertyId}/contacts/${id}`

      const request$ = this._http.get<IPropertyContact>(url).pipe(take(1))

      return await lastValueFrom<IPropertyContact>(request$)
   }

   async createPropertyContact(
      propertyContact: IPropertyContact
   ): Promise<IProperty> {
      if (!propertyContact) {
         return new Promise((resolve, reject) =>
            reject('No propertyContact passed!')
         )
      }

      const url = `${this.propertyApi}/${propertyContact.propertyId
         }/contacts`

      const options = this._commonService.httpPostOptions()

      const request$ = this._http.post<IProperty>(url, propertyContact, options).pipe(take(1))

      return await lastValueFrom<IProperty>(request$)

      //return await this._http
      //   .post<IProperty>(url, propertyContact, options)
      //   //TODO for use in future things that need to get newly created object by "location"
      //   //.pipe(
      //   //   map(property => {
      //   //      const locationUri = property[this._commonService.createLocationUri]
      //   //      if (locationUri) {
      //   //         return this._http.get<IPropertyContact>(locationUri).toPromise()
      //   //      }
      //   //      return property.propertyContacts.reduce((prev: IPropertyContact, curr: IPropertyContact) => {
      //   //         if (prev.id < curr.id) {
      //   //            return curr
      //   //         } else {
      //   //            return prev
      //   //         }
      //   //      })
      //   //   })
      //   //)
      //   .toPromise()
   }

   async updatePropertyContact(
      propertyContact: IPropertyContact
   ): Promise<IProperty> {
      if (!propertyContact) {
         return new Promise((resolve, reject) =>
            reject('No propertyContact passed!')
         )
      }

      const url = `${this.propertyApi}/${propertyContact.propertyId
         }/contacts`
      const options = this._commonService.httpPostOptions()

      const request$ = this._http.put<IProperty>(url, propertyContact, options).pipe(take(1))

      return await lastValueFrom<IProperty>(request$)
   }

   async deletePropertyContact(propertyContact: IPropertyContact): Promise<unknown> {
      if (!propertyContact) {
         return new Promise((resolve, reject) =>
            reject('No propertyContact passed!')
         )
      }

      const url = `${this.propertyApi}/${propertyContact.propertyId
         }/contacts/${propertyContact.id}`

      //return await new Promise(resolve => {
      //   resolve({})
      //})
      const request$ = this._http.delete(url, this._httpOptions).pipe(take(1))

      return await lastValueFrom(request$)
   }

   async getPropertyDocuments(propertyId: number): Promise<IPropertyDocument[]> {
      const url = `${this.propertyApi}/${propertyId}/documents`

      // TODO remove
      return await new Promise(resolve => {
         resolve([
            {
               id: 123,
               typeCode: DocumentType_.AgencyAgreement,
               fileName: 'Bubbalicious.jpg',
               propertyId
               , uploaded: '8/1/2019',
               extension: '.pdf',
               content: '',
               logicallyDeleted: '',
               property: {} as IProperty,
               version: '',
               info: { documentTypeDescription: 'Agency Agreement' }
            } as IPropertyDocument,
            {
               id: 234,
               typeCode: DocumentType_.MutualCancellationAgreement,
               fileName: 'My_Contract50.pdf',
               propertyId,
               uploaded: '6/4/2021',
               extension: '.pdf',
               content: '',
               logicallyDeleted: '',
               property: {} as IProperty,
               version: '',
               info: { documentTypeDescription: 'Mututal Cancellation Agreement' }
            } as IPropertyDocument,
            {
               id: 345,
               typeCode: DocumentType_.LeaseAgreement,
               fileName: 'Lease_Agreement_JOJO.doc',
               propertyId,
               uploaded: '10/25/2020',
               extension: '.pdf',
               content: '',
               logicallyDeleted: '',
               property: {} as IProperty,
               version: '',
               info: { documentTypeDescription: 'Lease Agreement' }
            } as IPropertyDocument
         ] as IPropertyDocument[])
      })

      const request$ = this._http.get<IPropertyDocument[]>(url).pipe(take(1))

      return await lastValueFrom<IPropertyDocument[]>(request$)
   }

   async deletePropertyDocument(propertyDocument: IPropertyDocument): Promise<unknown> {
      const url = `${this.propertyApi}/${propertyDocument.propertyId
         }/documents/${propertyDocument.id}`

      // TODO remove
      //return await new Promise(resolve => {
      //   resolve({})
      //})

      const request$ = this._http.delete(url, this._httpOptions).pipe(take(1))

      return await lastValueFrom(request$)
   }

   async submitToAccouting(propertyId: number, propertyWorkflow: IPropertyWorkflow): Promise<IProperty> {
      if (!propertyId) {
         return new Promise((resolve, reject) => reject('No property passed!'))
      }

      const url = `${this.propertyApi}/${propertyId}/accounting/submit`
      const options = this._commonService.httpPostOptions()

      // TODO remove
      //property.submitted = '6/22/2021'
      //return await new Promise(resolve => {
      //   resolve({ ...property })
      //})

      const request$ = this._http.put<IProperty>(url, propertyWorkflow || {}, options).pipe(take(1))

      return await lastValueFrom<IProperty>(request$)

      //https://medium.com/@vicpuigcer/rxjs-7-operator-topromise-deprecated-alternatives-4b02d1061f44
      //TODO https://stackoverflow.com/questions/75642287/how-to-change-an-observable-http-request-to-promise-request
      /*
      async yourFunction(): Promise<YourTypeOrInterface> {
          const request$ = this.httpClient.get(yourUrl).pipe(take(1));

          return await lastValueFrom<YourTypeOrInterface>(request$);
      }
      */
   }

   readyPropertyObjectForSubmit(property: IProperty): IProperty {
      delete property.info

      if (property.propertyContacts) {
         property.propertyContacts = property.propertyContacts.map(
            propertyContact => {
               return _.omit(propertyContact, ['info', 'property'])
            }
         ) as unknown as IPropertyContact[]
      }

      delete property.propertyDocuments

      return property
   }
}
