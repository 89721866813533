import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnDestroy,
   Output,
   SimpleChanges,
   ViewChild
} from '@angular/core'
import { Subject } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import {
   IProperty,
   IPropertyContact
} from '@appShared/interfaces/[Model-based]'
import {
   PropertyEditSectionData,
   PropertyEditSection_
} from '@appShared/services/property.service'
import { PropertyEditContactsGridComponent } from '@appProperty/property-edit/property-edit-contacts/property-edit-contacts-grid/property-edit-contacts-grid.component'
import { PropertyEditContactComponent } from '@appProperty/property-edit/property-edit-contacts/property-edit-contact/property-edit-contact.component';

@Component({
    selector: 'app-property-edit-contacts-shell',
    templateUrl: './property-edit-contacts-shell.component.html',
    standalone: true,
    imports: [PropertyEditContactsGridComponent, PropertyEditContactComponent]
})
export class PropertyEditContactsShellComponent implements OnChanges {
   @Input() property: IProperty
   @Input() canEdit: boolean
   @Output() propertyStageUpdated = new EventEmitter();

   // @ViewChild(PropertyEditContactComponent)
   // propertyEditContactComponent: PropertyEditContactComponent;
   @ViewChild(PropertyEditContactsGridComponent)
   propertyEditContactsGridComponent: PropertyEditContactsGridComponent

   selectedPropertyContact: IPropertyContact

   constructor(private _commonService: CommonService) { }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.['property']?.currentValue?.id) {
        /*only reload grid if property changes*/
         // if (changes.property.previousValue?.id != changes.property.currentValue.id) this._setPublishers();
      }
   }

   /*
    * events
    * */

   /*
    * private methods
    * */

   /*
    * public methods
    * */

   setSelectedPropertyContact(propertyContact: IPropertyContact): void {
      this.selectedPropertyContact = propertyContact
         ? { ...propertyContact }
         : null

      const anchor = propertyContact
         ? 'property-edit-contact'
         : `property-edit-section-${PropertyEditSection_.Contacts}`

      setTimeout(() => this._commonService.scrollToModalAnchor(anchor), 0)
   }

   propertyContactUpdated(propertyEditSectionData: PropertyEditSectionData): void {
      this.propertyStageUpdated.emit({
         propertyEditSection: PropertyEditSection_.Contacts,
         property: propertyEditSectionData.property,
         refresh: propertyEditSectionData.refresh
      } as PropertyEditSectionData)

      this.property = { ...propertyEditSectionData.property }

      this.setSelectedPropertyContact(
         propertyEditSectionData.selectedPropertyContact
      )

      //this.propertyEditContactsGridComponent.addContact(
      //   propertyEditSectionData.selectedPropertyContact
      //)
   }

   propertyContactDeleted(propertyEditSectionData: PropertyEditSectionData) {
      this.propertyStageUpdated.emit({
         propertyEditSection: PropertyEditSection_.Contacts,
         property: propertyEditSectionData.property,
         refresh: propertyEditSectionData.refresh
      } as PropertyEditSectionData)

      this.propertyEditContactsGridComponent.getPrimaryContacts()
      this.setSelectedPropertyContact(null)
   }

   cancelPropertyContact(): void {
      this.selectedPropertyContact = null
      this.setSelectedPropertyContact(null)
      this.propertyEditContactsGridComponent.clearForm()
   }
}
