import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   Output,
   SimpleChanges
} from '@angular/core'
import { NgIf, NgFor, AsyncPipe } from '@angular/common'
import { Observable, of } from 'rxjs'
import { NgxMaskPipe } from 'ngx-mask'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { ConfirmOptions, ConfirmService } from '@appShared/components/confirm-modal-and-service'
import { CommonService } from '@appShared/services/common.service'
import {
   PropertyEditSectionData,
   PropertyEditSection_,
   PropertyService
} from '@appShared/services/property.service'
import {
   IPropertyContact,
   IProperty
} from '@appShared/interfaces/[Model-based]'
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service'


@Component({
    selector: 'app-property-edit-contacts-grid',
    templateUrl: './property-edit-contacts-grid.component.html',
    standalone: true,
    imports: [NgIf, NgFor, ConfirmTemplateDirective, ConfirmModalComponent, AsyncPipe, NgxMaskPipe]
})
export class PropertyEditContactsGridComponent implements OnChanges {
   @Input() property: IProperty
   @Input() canEdit: boolean
   @Input() refreshGrid: boolean
   @Output() propertyContactSelected = new EventEmitter();
   @Output() propertyContactUpdated = new EventEmitter();
   @Output() propertyContactDeleted = new EventEmitter();

   selectedPrimaryContact: IPropertyContact
   isSubmitting: boolean
   isInitialLoading = true;

   /* grid */
   ColumnMode = ColumnMode;
   contacts$: Observable<Array<IPropertyContact>> = of([]);

   constructor(
      private _propertyService: PropertyService,
      private _confirmService: ConfirmService,
      private _commonService: CommonService
   ) { }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.['property']?.currentValue || changes?.['refreshGrid']?.currentValue) {
         this.getPrimaryContacts()
      }
   }

   /*
    * private methods
    * */

   private _showContactInfo(): void {
      this.propertyContactSelected.emit(this.selectedPrimaryContact)
   }

   /*
    * public methods
    * */

   clearForm(): void {
      this.selectedPrimaryContact = null
      this._showContactInfo()
   }

   getPrimaryContacts(): void {
      this.contacts$ = of(this.property.propertyContacts)
      //this.contacts$ = this._propertyService
      //   .getPrimaryContacts(this.property.id)
      //   .pipe(tap(() => (this.isInitialLoading = false)))
   }

   addContact(contact?: IPropertyContact): void {
      this.selectedPrimaryContact =
         contact ||
         ({
            id: 0,
            propertyId: this.property.id,
            property: { ...this.property },
            // publisherAuthorizationManagerId: 0,
            isPrimary: false
         } as IPropertyContact)
      this._showContactInfo()
   }

   editContact(contact: IPropertyContact): void {
      if (contact) { this.addContact(contact) }
   }

   deleteContact(contact: IPropertyContact): void {
      if (contact) {
         this._confirmService
            .confirm({
               title: 'Confirm Delete!',
               message: `You are deleting contact information for<br/><strong class="ps-4 text-site-primary">- ${contact.info.displayName
                  }</strong><br/><br/>
                     Are you sure?`
            } as ConfirmOptions)
            .then(() => {
               this.isSubmitting = true
               this.selectedPrimaryContact = null
               this._showContactInfo()
               this._propertyService
                  .deletePropertyContact(contact)
                  .then(() => {
                     this._commonService.messageUser('Property Contact deleted!')
                     this.clearForm()

                     this.propertyContactDeleted.emit({
                        propertyEditSection: PropertyEditSection_.Contacts,
                        property: this.property,
                        refresh: true
                     } as PropertyEditSectionData)
                  })
                  .catch(() => {
                     /* Do Nothing */
                  })
                  .finally(() => (this.isSubmitting = false))
            })
            .catch(() => {
               /* Do Nothing */
            })
      }
   }
}
