

export interface RouteModel {
   baseUri: string
   title: string
   uri: string
}

const routes = {
   //signUp: {
   //   title: 'Create Account',
   //   baseUri: 'sign-up',
   //   uri: 'sign-up'
   //} as RouteModel,
   title: 'Southwestern Real Estate',
   uri: ''
}

export const environment = {
   production: false,
   baseUri: 'testportal.advantage4dealers.com',
   contentRootUri: 'property-listings',
   dealerApiUri: 'https://testportal.advantage4dealers.com/api/dealer',
   userPrincipalIdHeaderName: 'UserPrincipalId-29239E59E0924FC4',
   oAuthClientIdHeaderName: 'Client-Id-29239E59E0924FC4',
   oAuthClientSecretHeaderName: 'Client-Secret-29239E59E0924FC4',
   oAuthClientIdentifier: '9922177285349731',
   oAuthClientSecret: 'feaaec1a3f79435e82ae7bf712299850',
   serviceLayerClientIdHeaderName: 'Client-Id-EE8289B486034E60',
   serviceLayerClientId: '1204683634232208',
   serviceLayerClientSecretHeaderName: 'Client-Secret-EE8289B486034E60',
   serviceLayerClientSecret: 'e91ee2fd0f634a28aa43e0223c3864bf',
   routes
}
