<div class="text-center">

   <div class="">
      <bs-daterangepicker-inline (bsValueChange)="setDownloadParameters($event)"
                                 [bsConfig]="datePickerBaseConfig"></bs-daterangepicker-inline>
      <!--<div formControlName="exportDateRangePicker"
             #exportDateRangePickerInput="bsDaterangepicker"
             type="text"
             container=""
             placement="bottom"
             bsDaterangepicker
             [bsConfig]="datePickerBaseConfig"
           (bsValueChange)="setDownloadParameters($event)"
           ngDefaultControl
           [isOpen]="true"></div>-->
      <!--div class="input-group">
         <input formControlName="exportDateRangePicker"
                #exportDateRangePickerInput="bsDaterangepicker"
                type="text"
                container=""
                class="form-control"
                placement="bottom"
                bsDaterangepicker
                [bsConfig]="datePickerBaseConfig"
                [isOpen]="true" style="height: 0; overflow: hidden; border: none; padding: 0;">
         <div class="input-group-append">
            <button class="btn btn-outline-secondary"
                    type="button"
                    (click)="exportDateRangePickerInput.toggle()">
               <i class="fas fa-calendar-times"></i>
            </button>
         </div>
      </div>-->

   </div>

</div>

<p class="mt-4 mb-4 text-center text-site-tertiary fw-bold">
   {{dateRangeDisplay}}
</p>

<div *ngIf="downloadUri"
     class="row mt-4">

   <div class="col text-center">

      <a class="shadow ms-3 btn btn-primary"
         href="{{downloadUri}}"
         target="_blank">
         <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" class="bi bi-file-arrow-down" viewBox="0 0 16 16">
            <path d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5" />
            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
         </svg><span class="ps-2">Export</span>
      </a>

   </div>

</div>
