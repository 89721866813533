import { Component, OnDestroy, OnInit } from '@angular/core'
import {
   Router,
   Event,
   NavigationStart,
   NavigationEnd,
   NavigationCancel,
   NavigationError,
   RouterOutlet
} from '@angular/router'
import { Subject } from 'rxjs'
import { NgIf } from '@angular/common'
import { takeUntil } from 'rxjs/operators'
import { CommonService } from '@appShared/services/common.service'
import { UrlService } from '@appShared/services/url.service'
import { LoadingIndicatorComponent } from '@appShared/components/loading-indicator/loading-indicator.component'
import { SiteFooterComponent } from '@appComponents/site-footer/site-footer.component'

declare let gtag: () => void

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.less'],
   standalone: true,
   imports: [NgIf, RouterOutlet, LoadingIndicatorComponent, SiteFooterComponent]
})
export class AppComponent implements OnInit, OnDestroy {
   private _ngDestroyed$ = new Subject()
   previousUrl = ''
   currentUrl = ''
   loading = false
   showHeaderFooter = false
   isIframe = false

   constructor(
      private _commonService: CommonService,
      private _urlService: UrlService,
      router: Router
   ) {
      router.events
         .pipe(takeUntil(this._ngDestroyed$))
         .subscribe((routerEvent: Event) =>
            this._checkRouterEvent(routerEvent)
         )
   }

   ngOnInit(): void {
      this.isIframe = window !== window.parent && !window.opener
   }

   ngOnDestroy(): void {
      this._ngDestroyed$.next(true)
      this._ngDestroyed$.complete()
   }

   /*
    * private methods
    * */

   private _checkRouterEvent(routerEvent: Event): void {
      if (routerEvent instanceof NavigationStart) {
         this._commonService.setLoading(true)
      }

      if (
         routerEvent instanceof NavigationEnd ||
         routerEvent instanceof NavigationError ||
         routerEvent instanceof NavigationCancel
      ) {
         this._commonService.setLoading(false)

         if (routerEvent instanceof NavigationEnd) {
            this.previousUrl = this.currentUrl
            this.currentUrl = routerEvent.url
            this._urlService.setPreviousUrl(this.previousUrl)

            /* this._commonService.setShowHeaderFooter(
              this.currentUrl.indexOf('/livescoreboard/') === -1
            ) */

            /* gtag('config', 'UA-7880158-22', {
              page_path: routerEvent.urlAfterRedirects
            }) */
         }
      }
   }
}
