import { Routes, mapToCanActivate } from '@angular/router'
import {
   AgentListingGuardService,
   AuthGuardService,
   PropertyListingsGuardService,
   PublicOnlyGuardService
} from '@appShared/services'
import * as fromContainers from '@appContainers/index'
import { environment } from '@appEnvironments/environment'

export const APP_ROUTES: Routes = [
   {
      path: '',
      component: fromContainers.ShellComponent,
      canActivate: mapToCanActivate([AuthGuardService]),
      children: [
         // {
         //    path: '',
         //    canActivate: mapToCanActivate([PublicOnlyGuardService]),
         //    component: fromContainers.LandingComponent
         // },
         {
            path: '',
            // component: fromContainers.HomeComponent
            redirectTo: environment.contentRootUri,
            pathMatch: 'full'
         },
         {
            path: environment.contentRootUri,
            component: fromContainers.PropertyListingsComponent,
            canActivate: mapToCanActivate([PropertyListingsGuardService]),
         },
         // {
         //    path: 'users',
         //    component: fromContainers.UserListComponent,
         //    canActivate: mapToCanActivate([AgentListingGuardService]),
         // },
         // {
         //    path: environment.contentRootUri,
         //    component: fromContainers.PropertyListingsComponent,
         //    canActivate: mapToCanActivate([PropertyListingsGuardService]),
         // },
         //  {
         //     path: environment.contentRootUri,
         //     canActivate: [AuthGuardUserService],
         //     loadChildren: () =>
         //        import('./content/content.module').then(mod => mod.ContentModule)
         //  },
         //  {
         //     path: 'member',
         //     canActivate: [AuthGuardService],
         //     loadChildren: () =>
         //        import('./member/member.module').then(mod => mod.MemberModule)
         //  },
         //  {
         //     path: 'registration-landing',
         //     loadChildren: () =>
         //        import('./registration-landing/registration-landing.module').then(
         //           mod => mod.RegistrationLandingModule
         //        )
         //  },
         //  {
         //     path: 'registration',
         //     canActivate: [PublicOnlyGuardService],
         //     loadChildren: () =>
         //        import('./registration/registration.module').then(
         //           mod => mod.RegistrationModule
         //        )
         //  },
         {
            path: '404',
            component: fromContainers.PageNotFoundComponent
         },
         { path: '**', redirectTo: '404', pathMatch: 'full' }
      ]
   }
]
