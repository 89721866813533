import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { NgIf, NgClass } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import {
   IProperty,
   IPropertyWorkflow
} from '@appShared/interfaces/[Model-based]'
import { PropertyWorkflowAction_ } from '@appShared/services/lookup/[CodeGen]/property-workflow-action.domain'

@Component({
    selector: 'app-accounting-approval-modal',
    templateUrl: './accounting-approval-modal.component.html',
    styleUrls: ['./accounting-approval-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, NgClass]
})
export class AccountingApprovalModalComponent implements OnInit, AfterViewInit {
   @Input() property: IProperty
   @Input() isApprove: boolean
   @Input() updateProperty: (property: IProperty, approved?: boolean) => void
   @Input() closeFunction: () => void | null
   @Input() dismissFunction: () => void | null

   @ViewChild('messageTextArea') messageTextAreaRef: ElementRef

   approveType: string
   agentName: string
   message: string
   private _propertyWorkflowAction: PropertyWorkflowAction_

   constructor(
      private _activeModal: NgbActiveModal
   ) { }

   ngOnInit() {
      this.agentName = this.property.agent.displayName

      this._propertyWorkflowAction = this.isApprove
         ? PropertyWorkflowAction_.ApprovedByAccounting
         : PropertyWorkflowAction_.DisapprovedByAccounting
   }

   ngAfterViewInit() {
      this.messageTextAreaRef.nativeElement.focus()
   }

   /*
    * private methods
    * */

   /*
    * public methods
    * */

   setApproval(): void {
      const propertyWorkflow: IPropertyWorkflow = {
         actionCode: this._propertyWorkflowAction,
         comment: this.message,
         propertyId: this.property.id
      } as IPropertyWorkflow

      this.property.propertyWorkflows.push(propertyWorkflow)

      this.updateProperty(this.property, this.isApprove)

      this.close()
   }

   dismiss(): void {
      this._activeModal.dismiss(this.closeFunction)
   }

   close(): void {
      this._activeModal.close(this.dismissFunction)
   }
}
