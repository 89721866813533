import {
   Component,
   EventEmitter,
   Input,
   OnInit,
   OnChanges,
   Output,
   SimpleChanges
} from '@angular/core'
import { NgIf } from '@angular/common'
import { FormGroup } from '@angular/forms'
import { take, tap } from 'rxjs'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { CommonService } from '@appShared/services/common.service'
import {
   PropertyEditSectionData,
   PropertyEditSection_,
   PropertyService
} from '@appShared/services/property.service'
import { AppFacade } from '@appShared/services/app.facade'
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface'
import * as _ from 'lodash'
import { IProfile } from '@appShared/interfaces/[Interface-based]'
import { PropertyEditAccountingComponent } from '@appProperty/property-edit/property-edit-accounting/property-edit-accounting.component'
import { PropertyEditWorkflowsComponent } from '@appProperty/property-edit/property-edit-workflows/property-edit-workflows.component'
import { PropertyEditDocumentsComponent } from '@appProperty/property-edit/property-edit-documents/property-edit-documents.component'
import { PropertyEditDetailsComponent } from '@appProperty/property-edit/property-edit-details/property-edit-details.component'
import { PropertyEditContactsShellComponent } from '@appProperty/property-edit/property-edit-contacts/property-edit-contacts-shell.component'
import { PropertyEditBasicComponent } from '@appProperty/property-edit/property-edit-basic/property-edit-basic.component'

@Component({
    selector: 'app-property-edit',
    templateUrl: './property-edit.component.html',
    styleUrls: ['./property-edit.component.less'],
    standalone: true,
    imports: [NgIf, PropertyEditBasicComponent, PropertyEditContactsShellComponent, PropertyEditDetailsComponent, PropertyEditDocumentsComponent, PropertyEditWorkflowsComponent, PropertyEditAccountingComponent]
})
export class PropertyEditComponent implements OnInit, OnChanges {
   @Input() property: IProperty
   @Input() userProfile: IProfile
   @Input() canEdit: boolean
   @Output() propertyReset = new EventEmitter();

   PropertyEditSection_ = PropertyEditSection_;
   isAdmin: boolean
   //selectedProperty: IProperty
   isLoading: boolean

   /* form/form-fields */
   propertyForm: FormGroup
   // dividendNote: FormControl;
   mouseoverPropertySubmit: boolean
   isSubmitting: boolean

   constructor(
      private _propertyService: PropertyService,
      private _commonService: CommonService,
      private _activeModal: NgbActiveModal,
      private _appFacade: AppFacade
   ) {

   }

   ngOnInit(): void {
      this.isLoading = true
   }

   ngOnChanges(changes: SimpleChanges): void {
      const property = changes?.['property']
      if (property?.currentValue) {
         //this._setPropertyData(property.currentValue)
         this.property = property.currentValue
      }
   }

   /*
   * private methods
   * */

   //private _createFormGroup(): void {
   //   // this.dividendNote = new FormControl(null);
   //   // this.propertyForm = new FormGroup({
   //   //  statedShareValue: this.statedShareValue,
   //   //  statedValueNote: this.statedValueNote,
   //   //  dividendPerShare: this.dividendPerShare,
   //   //  dividendNote: this.dividendNote
   //   // });
   //}

   //private _setPropertyData(property: IProperty): void {
   //   // const setNumber = this._commonService.setNumber;

   //   // TODO
   //   // this.propertyForm.patchValue({
   //   //  statedShareValue: setNumber(selectedProperty.statedShareValue),
   //   //  statedValueNote: selectedProperty.statedValueNote,
   //   //  dividendPerShare: setNumber(selectedProperty.dividendPerShare),
   //   //  dividendNote: selectedProperty.dividendNote
   //   // });

   //   this.property = property
   //}

   /*
    * public methods
    * */

   savePropertyDetail(): void {
      this.isSubmitting = true

      // todo - remove
      setTimeout(() => {
         this.isSubmitting = false
      }, 2000)

      // let propertyFormValues = this.propertyForm.value,
      //  updatedProperty = {
      //    ...this.selectedProperty,
      //    statedShareValue: propertyFormValues.statedShareValue,
      //    statedValueNote: propertyFormValues.statedValueNote,
      //    dividendPerShare: propertyFormValues.dividendPerShare,
      //    dividendNote: propertyFormValues.dividendNote
      //  } as IProperty;

      // this._propertyService
      //  .createUpdateProperty(updatedProperty)
      //  .then(agent => {
      //    let msgSuffix = updatedProperty.id ? "updated" : "created";
      //    this._commonService.messageUser(`Property ${msgSuffix}!`);

      //    this.propertyUpdated.emit();

      //    this._activeModal.close();
      //  })
      //  .catch(() => {
      //    /* Do Nothing */
      //  })
      //  .finally(() => setTimeout(() => (this.isSubmitting = false), 500));
   }

   propertyStageUpdated(propertyEditSectionData: PropertyEditSectionData): void {
      const incomingProperty = _.cloneDeep(propertyEditSectionData.property)

      if (propertyEditSectionData.refresh && incomingProperty?.id) {
         this._propertyService
            .getProperty(incomingProperty.id)
            .then(refreshedItem => {
               propertyEditSectionData.property = refreshedItem
               //this.propertyUpdated.emit(propertyEditSectionData)
               this.propertyReset.emit(propertyEditSectionData)
            })
            .catch(() => {
               /* Do Nothing */
            })
      } else {
         propertyEditSectionData.property = incomingProperty
         //this.propertyUpdated.emit(propertyEditSectionData)
         this.propertyReset.emit(propertyEditSectionData)
      }
   }
}
