import { createSelector } from '@ngrx/store'
import { getAppFeatureState } from '@appState/reducers/app.reducer'
import { getLocaleCurrencyName } from '@angular/common'

// export const getItemState = createSelector(
//  fromFeature.getProfileFeatureState,
//  (state: fromFeature.ProductsAdminState) => state.items
// )

export const getSiteConfig = createSelector(
   getAppFeatureState,
   state => state.config
)

export const getCurrentProfile = createSelector(getSiteConfig, state => {
   return state.userProfile
})

// export const getStockPlanUri = createSelector(
//    getSiteConfig,
//    state => state.propertyPlanUri
// )

export const getSupportEmailAddress = createSelector(
   getSiteConfig,
   state => state.supportEmailAddress
)

export const getIsMaintenanceMode = createSelector(getSiteConfig, state => {
   return state.isMaintenanceMode
})

// export const getAreAgentsAccessible = createSelector(
//    getSiteConfig,
//    state => {
//       const userProfile = state.userProfile
//       const isMaintenanceMode = state.isMaintenanceMode

//       return (
//          userProfile.isAdmin ||
//          (!isMaintenanceMode &&
//             (userProfile.isAdminViewOnly || userProfile.hasSubordinates))
//       )
//    }
// )
