<div class="container">

   <form autocomplete="off"
         novalidate
         enctype="multipart/form-data">

      <div *ngIf="canEdit"
            class="row">

         <div class="col-sm-12">

            <div class="d-flex align-items-center gap-2">

               <div class="d-flex align-items-center gap-2">
                  <label>Document Type:</label>
                  <ng-select [items]="documentTypes"
                             bindLabel="description"
                             bindValue="code"
                             [(ngModel)]="documentTypeCode"
                             [ngModelOptions]="{standalone: true}"
                             (change)="onDocumentTypeChange($event)"
                             [clearable]="true"
                             style="min-width:290px;">
                  </ng-select>
               </div>

               <div [hidden]="!documentTypeCode">
                  <!--[textChangeIndex]="+(!!(selectedPropertyDocument?.file?.uri))"-->
                  <app-button [buttonConfig]="{textChange: ['Add Document', 'Replace Document'], type: 'submit'}"
                              [textChangeIndex]="+(!!(selectedPropertyDocument?.fileName))"
                              [isLoading]="isSubmitting"
                              [disabled]="isSubmitting || isDeleting"
                              (textBtnClickEmt)="propertyDocumentInput.click()"></app-button>
                  <span class="ps-3" *ngIf="selectedPropertyDocument?.fileName">
                     [ <a href="{{propertyDocumentBaseUri}}{{selectedPropertyDocument?.descriptor}}"
                          class="text-underline"
                          target="_blank">view document</a> ]
                  </span>
                  <span style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                     <input type="file" #propertyDocumentInput ng2FileSelect
                            name="formFile"
                            [uploader]="documentFileUploader"/>
                  </span>

               </div>

            </div>

         </div>

      </div>

   </form>

   <div class="mt-2 text-center text-error font-weight-bold"
        *ngIf="filesRequired">
      <em>* NOTE: {{filesRequired}}</em>
   </div>

   <div class="row mt-4" *ngIf="propertyDocumentFiles?.length">

      <div class="col">

         <table id="edit-item-file-grid"
                class="table table-striped">
            <thead>
               <tr>
                  <th scope="col">Description</th>
                  <th scope="col">File Name</th>
                  <th scope="col">Uploaded</th>
                  <th scope="col" *ngIf="canEdit">&nbsp;</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let doc of propertyDocumentFiles trackBy: id">
                  <td>{{doc.info.documentTypeDescription}}</td>
                  <td>
                     <a href="{{propertyDocumentBaseUri}}{{doc.descriptor}}"
                        target="_blank"
                        class="text-underline text-site-primary pointable">{{doc.fileName}}</a>
                  </td>
                  <td>{{doc.uploaded|date}}</td>
                  <td *ngIf="canEdit">
                     <button class="shadow btn btn-sm btn-info"
                             type="button"
                             (click)="editPropertyDocument(doc)"
                             [disabled]="isSubmitting || isDeleting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                     </button>

                     <button class="shadow btn btn-sm btn-danger ms-3"
                             type="button"
                             (click)="deletePropertyDocument(doc)"
                             [disabled]="isSubmitting || isDeleting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                           <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>

         <!--<ngx-datatable id="edit-item-file-grid"
                        class="material striped"
                        [rows]="propertyDocumentFiles"
                        [rowHeight]="50"
                        [columnMode]="ColumnMode.standard"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarV]="true"
                        [canAutoResize]="true">

            <ngx-datatable-column name="Description" prop="info.documentTypeDescription" [width]="275">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.info.documentTypeDescription}}
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="File Name" prop="fileName"
                                  headerClass="custom"
                                  cellClass="custom" [width]="325">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <a href="{{propertyDocumentBaseUri}}{{row.descriptor}}"
                     target="_blank"
                     class="text-underline text-site-primary pointable">{{row.fileName}}</a>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Uploaded" prop="uploaded" [width]="200"
                                  headerClass="custom text-center"
                                  cellClass="custom text-center">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.uploaded|date}}
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="canEdit"
                                  headerClass="custom edit"
                                  cellClass="custom edit"
                                  [width]="50"
                                  [maxWidth]="50">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <section class="text-center">
                     <button class="shadow btn btn-sm btn-info"
                             type="button"
                             (click)="editPropertyDocument(row)"
                             [disabled]="isSubmitting || isDeleting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                     </button>
                  </section>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="canEdit"
                                  headerClass="custom delete"
                                  cellClass="custom delete"
                                  [width]="50"
                                  [maxWidth]="50">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <section class="text-center">
                     <button class="shadow btn btn-sm btn-danger"
                             type="button"
                             (click)="deletePropertyDocument(row)"
                             [disabled]="isSubmitting || isDeleting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                           <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                     </button>
                  </section>
               </ng-template>
            </ngx-datatable-column>

         </ngx-datatable>-->

      </div>

   </div>

</div>
<ng-template confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
