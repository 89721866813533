import { createFeatureSelector, createReducer, on } from '@ngrx/store'
import { ISiteConfiguration } from '@appShared/interfaces/[Interface-based]/site-configuration.interface'
import { AppActions } from '../actions'
import { IProfile } from '@appShared/interfaces/[Interface-based]/profile.interface'

export interface AppState {
   config: ISiteConfiguration
   error: string
}

const intialAppState: AppState = {
   config: null,
   error: ''
}

export const getAppFeatureState = createFeatureSelector<AppState>('app')

export const appReducer = createReducer<AppState>(
   intialAppState,
   on(AppActions.loadSiteConfigSuccess, (state, action): AppState => {
      return {
         ...state,
         config: action.siteConfig,
         error: ''
      }
   }),
   on(AppActions.loadSiteConfigFailure, (state, action): AppState => {
      return {
         ...state,
         config: null,
         error: action.error
      }
   }),
   on(AppActions.setProfile, (state, action): AppState => {
      const userProfile = action.profile
      //only set user if there is a profile on the model
      return {
         ...state,
         config: {
            ...state.config,
            userProfile
         }
      }

   }),
   on(AppActions.toggleIsMaintenanceModeSuccess, (state, action): AppState => {
      const isMaintenanceMode = action.isMaintenanceMode
      return {
         ...state,
         config: { ...state.config, isMaintenanceMode },
         error: ''
      }
   }),
   on(AppActions.toggleIsMaintenanceModeFailure, (state, action): AppState => {
      return {
         ...state,
         config: null,
         error: action.error
      }
   }),
   on(AppActions.setUserMustResetPassword, (state, action): AppState => {
      const mustResetPassword = action.mustResetPassword
      const userProfile = {
         ...state.config.userProfile,
         mustResetPassword
      } as IProfile

      return {
         ...state,
         config: {
            ...state.config,
            userProfile
         }
      }

   })
)
