import { PageNotFoundComponent } from './page-not-found.component'
import { ShellComponent } from './shell/shell.component'
import { PropertyListingsComponent } from './property-listings/property-listings.component'
//import { UserListComponent } from './user-list/user-list.component'
import { HomeComponent } from './home/home.component'
import { LandingComponent } from './landing/landing.component'

export const containers: any[] = [
   HomeComponent,
   LandingComponent,
   PageNotFoundComponent,
   PropertyListingsComponent,
   ShellComponent
   //UserListComponent
]

export * from './home/home.component'
export *  from './landing/landing.component'
export * from './page-not-found.component'
export * from './shell/shell.component'
export * from './property-listings/property-listings.component'
//export * from './user-list/user-list.component'
