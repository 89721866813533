<div class="modal-header">
   <div class="modal-title" id="modal-basic-title">
      <h2 class="d-inline-block">Confirm <span *ngIf="isApprove">Approval</span><span *ngIf="!isApprove">Disapproval</span></h2>
      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
   </div>
</div>

<div class="modal-body">

   <div class="mt-3">
      <label for="message" class="form-label">Message to {{agentName}}: <small><i>(optional)</i></small></label>
      <textarea class="form-control" id="message" #messageTextArea [(ngModel)]="message"></textarea>
   </div>

</div>

<div class="modal-footer">
   <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Cancel</button>
   <button type="button" class="btn btn-danger" [ngClass]="{'btn-site-primary': isApprove}" (click)="setApproval()">Confirm and <span *ngIf="isApprove">Approve</span><span *ngIf="!isApprove">Disapprove</span></button>
</div>
