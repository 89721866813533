<div class="container"
     id="property-edit-notes-grid">

   <ng-container *ngIf="workflows$ | async; let workflows">

      <ng-container *ngIf="workflows.length; else noWorkflows">

         <div class="row pt-3">

            <div class="col">

               <table id="property-workflows-grid"
                      class="table table-striped">
                  <thead>
                     <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Sender</th>
                        <th scope="col">Status</th>
                        <th scope="col">Comment</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let workflow of workflows trackBy: id">
                        <td class="text-nowrap">{{workflow.info.lastActionInitiated|date:'short'}}</td>
                        <td>{{workflow.info.lastActionInitiatorContactName}}</td>
                        <td class="status">{{workflow.info.propertyWorkflowActionDescription}}</td>
                        <td>{{workflow.comment}}</td>
                     </tr>
                  </tbody>
               </table>

            </div>

         </div>

      </ng-container>

      <ng-template #noWorkflows>

         <div class="row">
            <div class="col">
               <div class="alert alert-warning">** No History **</div>
            </div>
         </div>

      </ng-template>

   </ng-container>

</div>
