<div class="color-line"></div>
<div class="bg-white shadow">
   <header class="container d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">

      <a class="d-flex align-items-center col-md-3 mb-2 mb-md-0 d-none d-md-block brand"
         routerLink="/{{contentRootUri}}">
         <img src="/assets/images/logo.png?v=20231009" />
      </a>
      <!--<form class="d-flex align-items-center col-md-3 mb-2 mb-md-0 d-none d-sm-block">
         <input type="search" class="form-control" placeholder="Search..." aria-label="Search">
      </form>-->

      <div class="title nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 font-site-tertiary text-uppercase">
         {{(headerTitle$|async )}}
      </div>

      <div>
         <span class="h5 text-header pe-3">Property <span class="d-none d-md-inline-block">Document</span> Management</span>
         <!-- TODO iteration 2 -->
         <!--<button class="shadow ms-3 float-right btn btn-primary"
                 *ngIf="(profile$|async)?.isAdmin"
                 type="button"
                 (click)="showAgentList()">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
               <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
            </svg><span class="ps-2">Agents</span>
         </button>-->
      </div>

      <!-- MAYBE menu for later -->
      <!-- <div *ngIf="profile$ | async as profile"
           class="col-md-3 text-end d-flex">
         <div class="dropdown flex-grow-1">
            <a href="#" class="d-block text-decoration-none dropdown-toggle" id="dropdownUserMenu" data-bs-toggle="dropdown" aria-expanded="false">
               <span class="pe-2">{{profile.displayName}}</span>
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
               </svg>
            </a>
            <ul class="dropdown-menu dropdown-menu-end text-small" aria-labelledby="dropdownUserMenu">
               <li>
                  <a class="dropdown-item" routerLink="/{{contentRootUri}}">Home</a>
               </li>
               <li *ngIf="(profile$|async)?.isAdmin">
                  <a class="dropdown-item" [routerLink]="['/users']">Manage Users</a>
               </li>
               <li><hr class="dropdown-divider"></li>
               <li><span class="dropdown-item pointable" (click)="logout()">Sign out</span></li>
            </ul>
         </div>
      </div> -->
   </header>
</div>
