import { createFeatureSelector, createReducer, on } from '@ngrx/store'
import { IProperty } from '../../shared/interfaces/[Model-based]/property.interface'
import { PropertyApiActions, PropertyPageActions } from '../actions/property'

export interface PropertyState {
   properties: IProperty[]
   searchFilter: string
   isRelevant: boolean
   error: string
}

const intialPropertyState: PropertyState = {
   properties: null,
   searchFilter: '',
   isRelevant: true,
   error: ''
}

export const getPropertyFeatureState = createFeatureSelector<PropertyState>(
   'property'
)

export const propertyReducer = createReducer<PropertyState>(
   intialPropertyState,
   on(
      PropertyApiActions.loadPropertiesSuccess,
      (state, action): PropertyState => {
         return {
            ...state,
            properties: action.properties,
            error: ''
         }
      }
   ),
   on(
      PropertyApiActions.loadPropertiesFailure,
      (state, action): PropertyState => {
         return {
            ...state,
            properties: [],
            error: action.error
         }
      }
   ),
   on(
      PropertyPageActions.setPropertiesSearchFilter,
      (state, action): PropertyState => {
         return {
            ...state,
            searchFilter: action.searchFilter
         }
      }
   ),
   on(
      PropertyPageActions.setPropertiesIsRelevant,
      (state, action): PropertyState => {
         return {
            ...state,
            isRelevant: action.isRelevant
         }
      }
   ),
   on(
      PropertyPageActions.updatePropertyInPropertiesSearch,
      (state, action): PropertyState => {
         let properties = [ ...state.properties ]
         let updatedProperty = action.property
         let existingPropertyIndex = properties.findIndex(prop => prop.id === updatedProperty.id)
         properties.splice(existingPropertyIndex, 1, updatedProperty)

         return {
            ...state,
            properties: properties,
            error: ''
         }
      }
   ),
)
