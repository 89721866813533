<main role="main" class="mt-3 mb-3"><!--container-->
   <div class="card" *ngIf="userProfile">

      <div class="card-header d-flex flex-row justify-content-between align-items-center flex-wrap">
         <h2 class="text-dull-white property-listings-header">Property Listings</h2>


         <div>

            <button class="shadow btn btn-sm btn-site-secondary me-3" *ngIf="userProfile.isAdmin"
                    (click)="showClosedPropertyModal()">
               <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" class="bi bi-file-arrow-down" viewBox="0 0 16 16">
                  <path d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5" />
                  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
               </svg> Export <span class="d-none d-md-inline-block">Closed</span> Properties
            </button>

            <button class="shadow btn btn-sm btn-site-secondary"
                    (click)="createProperty()">
               <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
               </svg> Add <span class="d-none d-md-inline-block">New</span> Property
            </button>

         </div>

      </div>

      <div class="card-body">

         <div class='d-flex justify-content-between align-items-center gap-1 mb-4 flex-wrap'>
            <div>
               <div class="input-group input-group-lg">
                  <input type="text" class="form-control shadow" placeholder="Search" app-autofocus
                         [formControl]="propertiesSearchFilterInput"
                         [value]="propertiesSearchFilter$|async"
                         maxlength="30" />
                  <!--(input)="setSearchFilter($event.target.value)"-->
               </div>
            </div>

            <h5 class="d-flex align-items-center" [innerHtml]="hitMessage | safe: 'html'">
            </h5>

            <div class="form-group">
               <div class="pretty p-svg p-curve p-pulse">
                  <input type="checkbox"
                         [formControl]="isOpenSubmittedCheckbox"
                         [checked]="propertiesSearchIsRelevant$|async"
                         />
                  <!--[formControl]="isOpenSubmittedCheckbox" (change)="setViewIsRecent($event)"-->
                  <div class="state p-success">
                     <svg class="svg svg-icon" viewBox="0 0 20 20">
                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                     </svg>
                     <label class="d-sm-inline-block d-md-none">Exclude completed older than 7 days</label>
                     <label class="d-none d-md-inline-block">Exclude completed properties older than 7 days</label>
                  </div>
               </div>
            </div>

            <!-- <div class="col-sm-6 col-md-4 col-lg-4">

               <div class="input-group input-group-lg">
                  <input id="filterInput" name="filterInput" type="text" class="form-control" placeholder="Search"
                         [value]="propertiesSearchFilter$|async" (input)="setSearchFilter($event.target.value)" maxlength="30">
               </div>

            </div>

            <div class='col-sm-6 col-md-8 col-lg-8'>
               <h5 class="mt-2 d-inline-block">
                  {{hitMessage}}
               </h5>

               <div class="form-group float-right mt-2">
                  <div class="pretty p-svg p-curve p-pulse">
                     <input type="checkbox"
                            [checked]="propertiesSearchIsRelevant$|async"
                            (change)="setViewIsRecent($event)" />
                     <div class="state p-success">
                        <svg class="svg svg-icon" viewBox="0 0 20 20">
                           <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                        </svg>
                        <label>Last 60 days only</label>
                     </div>
                  </div>
               </div>
            </div> -->
         </div>

         <div [hidden]="!(filteredProperties$ | async)?.length">

            <!--https://wpengine.com/resources/card-layout-css-grid-layout-how-to/-->
            <!--https://codepen.io/abbeyjfitzgerald/pen/QaeoYj-->
            <section class="cards">
               <article *ngFor="let property of (filteredProperties$ | async) trackBy: propertyTrackBy"
                        (click)="editProperty(property)"
                        class="card shadow pointable">
                  <!-- <picture class="thumbnail">
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3228.7617726584863!2d-86.52946962371843!3d35.97724931365521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88640c43ae4e8f31%3A0x7df2f208c1506b5f!2s605%20Enon%20Springs%20Rd%20W%2C%20Smyrna%2C%20TN%2037167!5e0!3m2!1sen!2sus!4v1697482613599!5m2!1sen!2sus" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       <img class="category__01" src="https://abbeyjfitzgerald.com/wp-content/uploads/2018/01/cloud.svg" alt="" />
                  </picture> -->
                  <div class="card-content">
                    <p class="status"
                    [ngClass]="{'submitted': property.info.isSubmitted, 'complete': property.info.isApproved, 'cancelled': property.info.isCancelled }">
                     Status: {{property.info.status}}
                    </p>

                    <div class="d-flex">
                        <div *ngIf="property.info.propertyTypeCode === propertyType.Sale"
                           class="d-flex flex-column ms-4 text-center text-site-tertiary fw-bold">
                           <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                              class="bi bi-house-heart text-site-tertiary w-100" viewBox="0 0 16 16">
                              <path d="M8 6.982C9.664 5.309 13.825 8.236 8 12 2.175 8.236 6.336 5.309 8 6.982Z"/>
                              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.707L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.646a.5.5 0 0 0 .708-.707L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
                           </svg>
                           SALE
                        </div>
                        <div *ngIf="property.info.propertyTypeCode === propertyType.Rental"
                           class="d-flex flex-column ms-4 text-center text-site-tertiary fw-bold">
                           <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                              class="bi bi-houses text-site-tertiary w-100" viewBox="0 0 16 16">
                              <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708L5.793 1Zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207l-4.5-4.5Z"/>
                            </svg>
                           RENTAL
                        </div>

                        <div *ngIf="property.info.propertyTypeCode === propertyType.Referral"
                           class="d-flex flex-column ms-4 text-center text-site-tertiary fw-bold">
                           <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                              class="bi bi-house-down text-site-tertiary w-100" viewBox="0 0 16 16">
                              <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207l-5 5V13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 2 13.5V8.207l-.646.647a.5.5 0 1 1-.708-.708L7.293 1.5Z"/>
                              <path d="M12.5 9a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Zm.354 5.854 1.5-1.5a.5.5 0 0 0-.708-.707l-.646.646V10.5a.5.5 0 0 0-1 0v2.793l-.646-.646a.5.5 0 0 0-.708.707l1.5 1.5a.5.5 0 0 0 .708 0Z"/>
                           </svg>
                           REFERRAL
                        </div>

                        <div class="mb-3 text-center flex-grow-1 fw-bold">
                           <div>{{property.street}}</div>
                           <div>{{property.info.addressCityStatePostal}}</div>
                           <div class="ms-1 fw-bold text-site-tertiary">
                              <!-- adding spacer for alignment issue of floating status -->
                              <span *ngIf="!property.listingNumber">&nbsp;</span>

                              <span *ngIf="property.listingNumber">MLS# <i class="fw-normal">{{property.listingNumber}}</i>  </span>
                           </div>
                        </div>

                    </div>


                    <!-- <p>

                    </p> -->
                 </div><!-- .card-content -->
                  <footer>
                     <div class="post-meta">
                        <span class="agent ps-4"><i class="fa fa-clock-o"></i> Agent: <span class="fw-bold">{{property.agent.displayName}}</span></span>
                        <span class="edit"><i class="fa-solid fa-pen-to-square"></i></span>
                     </div>
                  </footer>
                </article>


            </section>

            <!-- <ngx-datatable id="properties-grid" class="material striped" [rows]="filteredProperties$|async"
                           [columnMode]="ColumnMode.force" [rowHeight]="55" [headerHeight]="50" [footerHeight]="50" [limit]="15"
                           [scrollbarV]="true">

               <ngx-datatable-column name="MLS#" prop="listingNumber" headerClass=""
                                     cellClass="">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                     <svg *ngIf="row.isCancelled" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-exclamation text-error" viewBox="0 0 16 16">
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z" />
                     </svg>
                     <span [ngClass]="{'text-error': row.isCancelled}">
                        {{row.listingNumber}}
                     </span>
                  </ng-template>
               </ngx-datatable-column>

               <ngx-datatable-column name="Property Address" prop="info.addressFullDisplay" headerClass="address"
                                     cellClass="address" [width]="500" [maxWidth]="500">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                     <span class="text-underline text-site-primary pointable"
                           (click)="viewGoogleMap(row)"><span [innerHtml]="row.info.addressFullDisplay | safe: 'html'"></span><i class="fa fa-map-marker ps-2" aria-hidden="true"></i></span>
                  </ng-template>
               </ngx-datatable-column>

               <ngx-datatable-column name="Agent Name" prop="info.agentName" headerClass="offered"
                                     cellClass="offered">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                     {{row.info.agentName}}
                  </ng-template>

               </ngx-datatable-column>

               <ngx-datatable-column name="Status" prop="info.status" headerClass="offered"
                                     cellClass="offered">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                     <span class="text-success" [ngClass]="{'text-info': row.info.status === 'Submitted', 'text-site-primary-alt': row.info.status === 'Complete'}">
                        {{row.info.status}}
                     </span>
                  </ng-template>

               </ngx-datatable-column>

               <ngx-datatable-column headerClass="custom" cellClass="custom" [width]="50" [maxWidth]="50">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                     <section class="text-center">
                        <button class="btn btn-sm btn-site-primary" type="button" (click)="editProperty(row)">
                           <i class="fa fa-search" style="font-size:12px;"></i>
                        </button>
                     </section>
                  </ng-template>
               </ngx-datatable-column>


            </ngx-datatable> -->

         </div>

      </div>

   </div>
</main>

<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
