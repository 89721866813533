import { Component, Input } from '@angular/core'
import { NgIf } from '@angular/common'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styles: [
        `.modal-header {
        background-color: #72A130;;
        color: #FFF;
    }
    .modal-body {
      background-color: #FAFAFA;
    }`
    ],
    /* eslint-disable @angular-eslint/no-host-metadata-property */
    host: {
        '[style.display]': '"flex"',
        '[style.flex-direction]': '"column"',
        '[style.overflow]': '"hidden"'
    }
    /* eslint-enable @angular-eslint/no-host-metadata-property */
    ,
    standalone: true,
    imports: [NgIf]
})
export class ModalComponent {
   @Input() title = '';
   @Input() titleContainerName = '';
   @Input() showSave = false;
   @Input() showHeader = true;
   @Input() showFooter = true;
   @Input() closeFunction: () => void | null
   @Input() dismissFunction: () => void | null
   @Input() saveFunction: () => void | null

   constructor(public activeModal: NgbActiveModal) { }

   /*
    * public methods
    * */

   dismiss(): void {
      this.activeModal.dismiss(this.closeFunction)
   }

   close(): void {
      this.activeModal.close(this.dismissFunction)
   }

   save(): void {
      this.activeModal.close(this.saveFunction)
   }
}
