<div class="container"
     id="property-edit-contact-grid">

   <div class="row" *ngIf="!isInitialLoading && !((contacts$| async)?.length)">
      <div class="col">
         <div class="alert alert-warning">** No Contacts **</div>
      </div>
   </div>

   <div class="row pt-3" *ngIf="(contacts$| async)?.length">

      <div class="col">

         <table id="property-contacts-grid"
                class="table table-striped" >
            <thead>
               <tr>
                  <th scope="col">Contact Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact Phone</th>
                  <th scope="col" class="text-center">Primary</th>
                  <th scope="col" *ngIf="canEdit">&nbsp;</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let contact of (contacts$ | async) trackBy: id">
                  <td>{{contact.info.displayName}}</td>
                  <td>
                     <a class="text-site-primary text-underline"
                        href="mailto:{{contact.emailAddress}}">{{contact.emailAddress}}</a>
                  </td>
                  <td>{{contact.phoneNumber | mask: '(000) 000-0000'}}</td>
                  <td class="text-center">
                     <svg *ngIf="contact.isPrimary" width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-check text-success" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                     </svg>
                  </td>
                  <td *ngIf="canEdit">
                     <button class="btn btn-sm btn-site-primary"
                             type="button"
                             (click)="editContact(contact)"
                             [disabled]="isSubmitting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                     </button>

                     <button class="btn btn-sm btn-site-tertiary ms-3"
                             type="button"
                             (click)="deleteContact(contact)"
                             [disabled]="isSubmitting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                           <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>

         <!--<ngx-datatable id="property-contacts-grid"
                        class="material striped"
                        [rows]="contacts$ | async"
                        [rowHeight]="50"
                        [columnMode]="ColumnMode.standard"
                        [sorts]="[{ prop: 'name', dir: 'desc' }]"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarV]="true"
                        [limit]="5">

            <ngx-datatable-column name="Contact Name" prop="displayName" [width]="280">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.info.displayName}}
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Publisher" prop="emailAddress" [width]="300">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <a class="text-site-primary text-underline"
                     href="mailto:{{row.emailAddress}}">{{row.emailAddress}}</a>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Contact Phone" prop="phone" [width]="130">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.phoneNumber | mask: '(000) 000-0000'}}
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Pri" prop="isPrimary" [width]="50">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <svg *ngIf="row.isPrimary" width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-check text-success" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                     <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                  </svg>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="canEdit"
                                  headerClass="custom edit"
                                  cellClass="custom edit"
                                  [width]="50"
                                  [maxWidth]="50">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <section class="text-center">
                     <button class="btn btn-sm btn-site-primary"
                             type="button"
                             (click)="editContact(row)"
                             [disabled]="isSubmitting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                     </button>
                  </section>
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="canEdit"
                                  headerClass="custom delete"
                                  cellClass="custom delete"
                                  [width]="50"
                                  [maxWidth]="50"
                                  [hidden]="!canEdit">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <section class="text-center">
                     <button class="btn btn-sm btn-site-tertiary"
                             type="button"
                             (click)="deleteContact(row)"
                             [disabled]="isSubmitting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                           <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                     </button>
                  </section>
               </ng-template>
            </ngx-datatable-column>

         </ngx-datatable>-->

      </div>

   </div>

   <span id="property-edit-contact"></span>

   <div *ngIf="canEdit"
        class="row mt-4">

      <!--<div class="col">
         <div>{{selectedPrimaryContact|json}}</div>
         <h4 *ngIf="selectedPrimaryContact?.id">Contact: {{selectedPrimaryContact.info.displayName}}</h4>
         <h4 *ngIf="selectedPrimaryContact && !(selectedPrimaryContact?.id)">New Contact</h4>

      </div>-->

      <div class="col text-end">

         <button class="btn btn-site-primary btn-sm me-3"
                 (click)="addContact()">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg> Add Contact
         </button>

      </div>

   </div>

</div>
<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>

