/* SERVICES */
//import { AdminService } from './admin.service'

import { AgentListingGuardService } from './agent-listing-guard.service'
import { AgentService } from './agent.service'
import { AppConfigProvider } from './app-config-provider.service'
import { AuthGuardService } from './auth-guard.service'
import { AuthService } from './auth.service'
import { CommonService } from './common.service'
import {
   ConfirmService,
   ConfirmState
} from '../components/confirm-modal-and-service'
import { DateTimeService } from './date-time.service'
import { EmployeeService } from './employee.service'
import { GuardedSingletonService } from './guarded-singleton.service'
import { HttpRequestInterceptor } from './http-request-interceptor'
import { LoadingInterceptor } from './loading-interceptor'
import { LoadingService } from './loading.service'
import { LookupService } from './lookup/lookups.service'
import { NotificationsService } from './notifications.service'
import { ProfileService } from './profile.service'
import { PublicOnlyGuardService } from './public-only-guard.service'
import { PropertyService } from './property.service'
import { PropertyListingsGuardService } from './property-listings-guard.service'
import { SiteService } from './site.service'
import { UrlService } from './url.service'

export const services: any[] = [
   //AdminService,
   AgentListingGuardService,
   AgentService,
   AppConfigProvider,
   AuthService,
   AuthGuardService,
   CommonService,
   ConfirmService,
   ConfirmState,
   DateTimeService,
   EmployeeService,
   GuardedSingletonService,
   HttpRequestInterceptor,
   LoadingInterceptor,
   LoadingService,
   LookupService,
   NotificationsService,
   ProfileService,
   PropertyListingsGuardService,
   PublicOnlyGuardService,
   PropertyService,
   SiteService,
   UrlService
]

//export * from './admin.service'
export * from './agent-listing-guard.service'
export * from './agent.service'
export * from './app-config-provider.service'
export * from './auth-guard.service'
export * from './auth.service'
export * from './common.service'
export * from '../components/confirm-modal-and-service'
export * from './date-time.service'
export * from './employee.service'
export * from './guarded-singleton.service'
export * from './http-request-interceptor'
export * from './loading-interceptor'
export * from './loading.service'
export * from './lookup/lookups.service'
export * from './notifications.service'
export * from './profile.service'
export * from './property-listings-guard.service'
export * from './property.service'
export * from './public-only-guard.service'
export * from './site.service'
export * from './url.service'

/* TOKENS */
import { JQUERY_TOKEN } from './JQuery.service'
import { MOMENT_TOKEN } from './moment.service'
import { TOASTR_TOKEN } from './toastr.service'

export { JQUERY_TOKEN, MOMENT_TOKEN, TOASTR_TOKEN }

export * from './JQuery.service'
export * from './moment.service'
export * from './toastr.service'
