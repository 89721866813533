<div *ngIf="property">

  <div id="property-edit-section-{{PropertyEditSection_.BasicInfo}}" class="pt-2">

     <app-property-edit-basic [property]="property"
                              [userProfile]="userProfile"
                              [canEdit]="canEdit"
                              (propertyStageUpdated)="propertyStageUpdated($event)"></app-property-edit-basic>

  </div>

  <div [hidden]="!property?.id">

     <fieldset class="mt-4 section-container property-contacts-container">
        <legend id="property-edit-section-{{PropertyEditSection_.Contacts}}">Contacts</legend>

        <app-property-edit-contacts-shell [property]="property"
                                          [canEdit]="canEdit"
                                          (propertyStageUpdated)="propertyStageUpdated($event)"></app-property-edit-contacts-shell>

     </fieldset>

     <div class="mb-4">

        <fieldset class="mt-4 section-container property-details-container">
           <legend id="property-edit-section-{{PropertyEditSection_.Details}}">Details</legend>

           <app-property-edit-details [property]="property"
                                      [canEdit]="canEdit"
                                      (propertyStageUpdated)="propertyStageUpdated($event)"></app-property-edit-details>

        </fieldset>

        <fieldset class="mt-4 section-container property-documents-container">
           <legend id="property-edit-section-{{PropertyEditSection_.Documents}}">Documents</legend>

           <app-property-edit-documents [property]="property"
                                        [canEdit]="canEdit"
                                        (propertyStageUpdated)="propertyStageUpdated($event)"></app-property-edit-documents>

        </fieldset>

        <fieldset class="mt-4 section-container property-workflows-container">
           <legend id="property-edit-section-{{PropertyEditSection_.Workflows}}">Status History</legend>

           <app-property-edit-workflows [property]="property"></app-property-edit-workflows>

        </fieldset>

        <fieldset *ngIf="(userProfile.isAdmin && property.info.isSubmitted) || property.info.isApproved"
                  class="mt-4 section-container property-accounting-container">
           <legend id="property-edit-section-{{PropertyEditSection_.Accounting}}">Accounting</legend>

           <app-property-edit-accounting [property]="property"
                                         [isAdmin]="userProfile.isAdmin"
                                         (propertyStageUpdated)="propertyStageUpdated($event)"></app-property-edit-accounting>

        </fieldset>

     </div>

  </div>

</div>
