import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { lastValueFrom, Observable, of, take } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { IAgent } from '@appShared/interfaces/[Model-based]/agent.interface'

@Injectable({ providedIn: 'root' })
export class AgentService {
   private _agentApi = '/api/portal/agents'

   FAKE_AGENTS: IAgent[] = [
      {
         id: 123,
         displayName: 'Ed Solima',
         emailAddress: 'esolima@server.com',
         userLogonName: 'esolima'
      } as IAgent,
      {
         id: 234,
         displayName: 'Leonard Lewis',
         emailAddress: 'llewis@server.com',
         userLogonName: 'llewis'
      } as IAgent,
      {
         id: 345,
         displayName: 'Jeff Young',
         emailAddress: 'jyoung@server.com',
         userLogonName: 'jyoung'
      } as IAgent
   ]

   constructor(private _http: HttpClient) { }

   getAgents(): Observable<IAgent[]> {
      // TODO JY remove
      //return of(this.FAKE_AGENTS)

      const url = `${this._agentApi}`

      return this._http.get<IAgent[]>(url)
   }

   async getAgentsAsync(): Promise<IAgent[]> {
      //const agents = [
      //        ]
      //return await new Promise(resolve => {
      //   resolve([...agents] as IAgent[])
      //})

      const request$ = this.getAgents().pipe(take(1))

      return await lastValueFrom<IAgent[]>(request$)
   }

   async getAgent(id: number): Promise<IAgent> {
      // TODO JY remove
      const matchingAgent = this.FAKE_AGENTS.find(
         agent => agent.id === id
      )
      return await new Promise(resolve => {
         resolve({ ...matchingAgent } as IAgent)
      })

      const url = `${this._agentApi}/${id}`

      const request$ = this._http.get<IAgent>(url).pipe(take(1))

      return await lastValueFrom<IAgent>(request$)
   }
}
