import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { NgIf, NgFor, NgClass } from '@angular/common'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface'
import { PropertyEditSectionData, PropertyEditSection_, PropertyService } from '@appShared/services/property.service'
import { ConfirmService } from '@appShared/components/confirm-modal-and-service'
import { CommonService } from '@appShared/services/common.service'
import { AgentService } from '@appShared/services/agent.service'
import { PropertyType, PropertyTypes, PropertyType_, StateProvinces, TransactionType, TransactionTypes } from '@appShared/services/lookup/[CodeGen]'
import { IAgent } from '@appShared/interfaces/[Model-based]/agent.interface'
import { IProfile } from '@appShared/interfaces/[Interface-based]/profile.interface'
import * as _ from 'lodash'
import { ButtonComponent } from '@appShared/components/button/button.component'

@Component({
    selector: 'app-property-edit-basic',
    templateUrl: './property-edit-basic.component.html',
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, NgClass, NgSelectModule, ButtonComponent]
})
export class PropertyEditBasicComponent implements /*OnInit,*/OnChanges {
   @Input() property: IProperty
   @Input() userProfile: IProfile
   @Input() canEdit: boolean
   @Output() propertyStageUpdated = new EventEmitter()

   private _transactionTypes = TransactionTypes

   // formgroup/fields
   propertyBasicInfoForm: FormGroup
   propertyTypeSelectFieldName = 'propertyTypeCode'
   propertyTypeSelect: PropertyType
   propertyTypes = PropertyTypes
   listingNumber: FormControl
   isListingUnavailable: FormControl
   agentsDropDown: FormControl
   agents: IAgent[] = []
   street: FormControl
   city: FormControl
   statesDropdown: FormControl
   states = StateProvinces
   postalCode: FormControl
   standardDomainSearchFn: (
      term: string,
      item: any,
      matchingFieldOverride1?: string,
      matchingFieldOverride2?: string) => boolean
   transactionTypeDropdown: FormControl
   transactionTypes: TransactionType[] = []
   mouseoverPropertyBasicInfoSubmit: boolean

   isSubmitting: boolean

   get isNewItem(): boolean {
      return !(this.property?.id)
   }

   constructor(
      private _commonService: CommonService,
      private _agentService: AgentService,
      private _propertyService: PropertyService,
      private _confirmService: ConfirmService
   ) {

      // .finally(() => (this.isLoading = false))

      this._createFormGroup()

      this.standardDomainSearchFn = this._commonService.standardDomainSearchFn
   }

   //ngOnInit(): void {
   //  // this._createFormGroup()
   //}

   ngOnChanges(changes: SimpleChanges): void {
      const property = changes?.['property']
      const canEdit = changes?.['canEdit']
      if (property?.currentValue) {
         // using setTimeout to solve ExpressionChangedAfterItHasBeenCheckedError
         // with loading spinner
         setTimeout(() => this._setBasicData())
      } else if (canEdit?.currentValue) {
         this._setVisibility()
      }
   }

   /*
    * events
    * */

   onPropertyTypeChange(propertyTypeCode: PropertyType_): void {
      if (propertyTypeCode) {

         this.transactionTypeDropdown.setValue(null)
         this.transactionTypes = this._transactionTypes.filter(
            type => type.propertyTypeCode === propertyTypeCode
         )

         if (this.transactionTypes.length == 1) {
            this.transactionTypeDropdown.setValue(this.transactionTypes[0])
         }

         if (propertyTypeCode == PropertyType_.Referral
            && this.propertyBasicInfoForm.enabled) {
            this.street.setValidators([])
            this.postalCode.setValidators(Validators.pattern(this._commonService.regexp().postalCode.usOnly))
         } else {
            this.street.setValidators(Validators.required)
            this.postalCode.setValidators([Validators.required,
               Validators.pattern(this._commonService.regexp().postalCode.usOnly)])
         }

         this.street.updateValueAndValidity()
         this.postalCode.updateValueAndValidity()
      }
   }

   /*
   * private methods
   * */

   private _createFormGroup(): void {
      this.listingNumber = new FormControl(null, Validators.required)
      this.isListingUnavailable = new FormControl()
      this.agentsDropDown = new FormControl(null, Validators.required)
      this.street = new FormControl(null, Validators.required)
      this.city = new FormControl(null, Validators.required)
      this.statesDropdown = new FormControl(null, Validators.required)
      this.postalCode = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().postalCode.usOnly)
      ])
      this.transactionTypeDropdown = new FormControl(null, Validators.required)

      this.propertyBasicInfoForm = new FormGroup({
         [this.propertyTypeSelectFieldName]: new FormControl(null, Validators.required),
         listingNumber: this.listingNumber,
         isListingUnavailable: this.isListingUnavailable,
         agentsDropDown: this.agentsDropDown,
         street: this.street,
         city: this.city,
         statesDropdown: this.statesDropdown,
         postalCode: this.postalCode,
         transactionTypeDropdown: this.transactionTypeDropdown
      })
   }

   private _setBasicData(): void {

      let setData = () => {
         const property = this.property
         const transactionTypeCode = property.transactionTypeCode
         const transactionType = TransactionTypes.find(
            type => type.code == transactionTypeCode
         )

         const propertyTypeCode = transactionType.propertyTypeCode

         if (propertyTypeCode) {
            this._commonService.setDynamicFormControlSelect(
               this,
               'propertyTypeSelect',
               this.propertyBasicInfoForm.controls[this.propertyTypeSelectFieldName],
               this.propertyTypes,
               propertyTypeCode,
               null,
               false
            )

            this.onPropertyTypeChange(propertyTypeCode)
         }

         this._commonService.setFormControlSelect(
            this.property.transactionTypeCode,
            this.transactionTypes,
            this.transactionTypeDropdown
         )

         let agent: IAgent
         if (property.agentId) {
            agent = this.agents.find(
               ag => ag.id === property.agentId
            )

            // agent might be excluded from dropdown if not Active
            // so add dynamically in that case
            if (!agent) {
               agent = property.agent
            }
         } else if (this.userProfile.isAgent) {
            agent = this.agents.find(
               ag => ag.userObjectIdentifier === this.userProfile.objectId
            )
         }

         const isListingUnavailable = property.isListingUnavailable
         property.listingNumber = this.setListingAvailability(isListingUnavailable, property.listingNumber)

         this._commonService.setFormControlSelect(
            property.stateProvinceCode,
            this.states,
            this.statesDropdown
         )

         this.propertyBasicInfoForm.patchValue({
            agentsDropDown: agent || null,
            listingNumber: property.listingNumber || null,
            isListingUnavailable,
            street: property.street || null,
            city: property.city || null,
            postalCode: property.postalCode || null
         })
      }

      this._setVisibility()

      if (this.agents.length) {
         setData()
      } else {
         this._agentService.getAgentsAsync()
            .then(agents => {
               this.agents = agents

               setData()
            })
            .catch(() => {
               /* Do Nothing */
            })
      }
   }

   private _setVisibility(): void {
      this.canEdit
         ? this.propertyBasicInfoForm.enable()
         : this.propertyBasicInfoForm.disable()

      if (this.userProfile.isAgent) {
         this.agentsDropDown.disable()
      }
   }

   /*
    * public methods
    * */


   setListingAvailability(isListingUnavailable?: boolean, listingNumber?: string): string {
      if (isListingUnavailable) {
         this.listingNumber.setValue(null)
         this.listingNumber.disable()
      } else {
         this.listingNumber.setValue(listingNumber || null)

         if (this.propertyBasicInfoForm.enabled) this.listingNumber.enable()
      }

      return isListingUnavailable ? null : listingNumber
   }

   updateBasicInfo(): void {
      this.isSubmitting = true

      /*
       * if fields are disabled, angular forms removes them
       * from the object so need pull "raw" values
       */
      const rawValues = this.propertyBasicInfoForm.getRawValue()
      const basicInfoValues = { ...rawValues }
      const agentId =
         basicInfoValues.agentsDropDown?.id

      const stateProvinceCode =
         basicInfoValues.statesDropdown &&
         basicInfoValues.statesDropdown.code
      const transactionTypeCode =
         basicInfoValues.transactionTypeDropdown &&
         basicInfoValues.transactionTypeDropdown.code
      const property = this._propertyService.readyPropertyObjectForSubmit({ ...this.property })

      delete basicInfoValues.agentsDropDown
      delete basicInfoValues.statesDropdown
      delete basicInfoValues.transactionTypeDropdown

      const updatedProperty = {
         ...property,
         ...basicInfoValues,
         agentId,
         stateProvinceCode,
         transactionTypeCode
      } as IProperty

      const section = PropertyEditSection_.BasicInfo

      this._propertyService.createUpdateProperty(updatedProperty, section)
         .then(returnProperty => {
            const msgSuffix = updatedProperty.id ? 'updated' : 'created'
            this._commonService.messageUser(`Property ${msgSuffix}!`)

            this.propertyStageUpdated.emit({
               propertyEditSection: section,
               property: returnProperty,
               refresh: true
            } as PropertyEditSectionData)
         })
         .catch(() => {
            /* Do Nothing */
         })
         .finally(() => this.isSubmitting = false)

   }
}
