import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnDestroy,
   Output,
   SimpleChanges
} from '@angular/core'
import { NgIf, NgClass, DatePipe } from '@angular/common'
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxMaskDirective } from 'ngx-mask'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgxCurrencyDirective } from 'ngx-currency'
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
   PropertyEditSectionData,
   PropertyEditSection_,
   PropertyService
} from '@appShared/services/property.service'
import { CommonService } from '@appShared/services/common.service'
import { ToastrType } from '@appShared/services/toastr.service'
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface'
import { DepositAccounts } from '@appShared/services/lookup/[CodeGen]/deposit-account.domain'
import { TransactionType_ } from '@appShared/services/lookup/[CodeGen]/transaction-type.domain'
import { AccountingApprovalModalComponent }
   from '@appProperty/property-edit/property-edit-accounting/accounting-approval-modal/accounting-approval-modal.component'
import * as _ from 'lodash'
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service'
import { ButtonComponent } from '@appShared/components/button/button.component'

@Component({
    selector: 'app-property-edit-accounting',
    templateUrl: './property-edit-accounting.component.html',
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, NgxCurrencyDirective, BsDatepickerModule, NgSelectModule, NgxMaskDirective, ButtonComponent, ConfirmTemplateDirective, ConfirmModalComponent, DatePipe]
})
export class PropertyEditAccountingComponent implements OnChanges , OnDestroy {
   @Input() property: IProperty
   @Input() isAdmin: boolean
   @Output() propertyStageUpdated = new EventEmitter()
   private _ngDestroyed$ = new Subject()

   // formgroup/fields
   propertyAccountingForm: FormGroup
   checkDepositAmount: FormControl
   depositAccountTypeDropDown: FormControl
   depositAccountTypes = DepositAccounts
   checkDepositedDatePicker: FormControl
   datePickerBaseConfig: Partial<BsDatepickerConfig> = {}
   transactionCount: FormControl
   sapPostingDatePicker: FormControl
   isAgentDirectPay: FormControl
   commission: FormControl
   transactionWeight: FormControl
   standardDomainSearchFn: (
      term: string,
      item: any,
      matchingFieldOverride1?: string,
      matchingFieldOverride2?: string) => boolean
   mouseoverPropertyAccountingSubmit: boolean
   isSaleTransactionType: boolean
   isSubmitting: boolean

   constructor(
      private _commonService: CommonService,
      private _propertyService: PropertyService,
      private _modalService: NgbModal
   ) {
      this._createFormGroup()

      this.standardDomainSearchFn = this._commonService.standardDomainSearchFn
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.['property']?.currentValue) {
         this._setAccountingData()
      }
   }

   ngOnDestroy(): void {
      this._ngDestroyed$.next(true)
      this._ngDestroyed$.complete()
   }

   /*
    * private methods
    * */

   private _createFormGroup(): void {
      this.checkDepositAmount = new FormControl()
      this.depositAccountTypeDropDown = new FormControl()
      this.checkDepositedDatePicker = new FormControl()
      this.transactionCount = new FormControl()
      this.sapPostingDatePicker = new FormControl()
      this.isAgentDirectPay = new FormControl()
      this.commission = new FormControl()
      this.transactionWeight = new FormControl()

      this.propertyAccountingForm = new FormGroup({
         checkDepositAmount: this.checkDepositAmount,
         depositAccountTypeDropDown: this.depositAccountTypeDropDown,
         checkDepositedDatePicker: this.checkDepositedDatePicker,
         transactionCount: this.transactionCount,
         sapPostingDatePicker: this.sapPostingDatePicker,
         isAgentDirectPay: this.isAgentDirectPay,
         commission: this.commission,
         transactionWeight: this.transactionWeight
      })

      this.transactionCount.valueChanges
         .pipe(
            takeUntil(this._ngDestroyed$),
            debounceTime(400),
            distinctUntilChanged()
         )
         .subscribe(value => {
            this._verifyTransWeightRule(value, this.transactionWeight.value)
         })

      this.transactionWeight.valueChanges
         .pipe(
            takeUntil(this._ngDestroyed$),
            debounceTime(400),
            distinctUntilChanged()
         )
         .subscribe(value => {
            this._verifyTransWeightRule(this.transactionCount.value, value)
         })
   }

   private _verifyTransWeightRule(transCount: any, transWeight: any) {
      transCount = _.toNumber(transCount || 0)
      transWeight = _.toNumber(transWeight || 0)
      if (transWeight && !transCount) {
         this.transactionWeight.setValue(0)
         this._commonService.messageUser('You must enter a Trans Count before a Trans Weight', null, ToastrType.error)
      } else if (transCount && transCount < transWeight) {
         this.transactionWeight.setValue(0)
         this._commonService.messageUser('Trans Weight must be less or equal to Trans Count', null, ToastrType.error)
      }
   }

   private _setAccountingData(): void {
      const property = this.property

      this._commonService.setFormControlSelect(
         property.depositAccountCode,
         this.depositAccountTypes,
         this.depositAccountTypeDropDown
      )

      const transactionTypeCode = property.transactionTypeCode
      const saleTransactionTypes = [TransactionType_.Buyer, TransactionType_.Seller, TransactionType_.BuyerSeller]
      this.isSaleTransactionType = saleTransactionTypes.includes(transactionTypeCode)
      property.isAgentDirectPay = this.isSaleTransactionType ? property.isAgentDirectPay : false

      this.propertyAccountingForm.patchValue({
         checkDepositAmount: property.checkDepositAmount || null,
         checkDepositedDatePicker: property.checkDeposited
            ? new Date(property.checkDeposited)
            : null,
         transactionCount: property.transactionCount || null,
         sapPostingDatePicker: property.posted
            ? new Date(property.posted)
            : new Date()/* rules: default SAP Posting date if nothing there */,
         isAgentDirectPay: property.isAgentDirectPay || false,
         commission: property.commission || null,
         transactionWeight: property.transactionWeight || null
      })

      this.isAdmin
         ? this.propertyAccountingForm.enable()
         : this.propertyAccountingForm.disable()
   }

   private _updateProperty(property: IProperty, approved?: boolean) {
      const propertyEditSectionAccounting = PropertyEditSection_.Accounting

      this._propertyService.createUpdateProperty(property, propertyEditSectionAccounting, approved)
         .then(returnProperty => {
            // determine if coming from "update" or approve/disapprove""
            const msgSuffix = property.id
               ? approved == null
                  ? 'updated'
                  : approved
                     ? 'approved'
                     : 'disapproved'
               : 'created'
            this._commonService.messageUser(`Property ${msgSuffix}!`)

            this.propertyStageUpdated.emit({
               propertyEditSection: propertyEditSectionAccounting,
               property: returnProperty,
               refresh: true
            } as PropertyEditSectionData)
         })
         .catch(() => {
            /* Do Nothing */
         })
         .finally(() => {
            this.isSubmitting = false
         })
   }

   /*
    * events
    * */

   /*
    * public methods
    * */

   updatePropertyAccounting(approved?: boolean): void {

      this.isSubmitting = true

      const propertyAccountingValues = { ...this.propertyAccountingForm.value }
      const dateService = this._commonService.dateTime
      const posted = dateService.formatDate(
         propertyAccountingValues.sapPostingDatePicker
      )
      const checkDeposited = dateService.formatDate(
         propertyAccountingValues.checkDepositedDatePicker
      )
      const depositAccountCode =
         (propertyAccountingValues.depositAccountTypeDropDown &&
         propertyAccountingValues.depositAccountTypeDropDown.code) || 0

      delete propertyAccountingValues.depositAccountTypeDropDown

      const updatedProperty = {
         ...this.property,
         ...propertyAccountingValues,
         posted,
         checkDeposited,
         depositAccountCode
      } as IProperty
      const section = PropertyEditSection_.Accounting

      if (approved !== null) {

         this.isSubmitting = false

         const accountingApprovalModalRef = this._modalService.open(
            AccountingApprovalModalComponent,
            {
               scrollable: true
               // windowClass: 'my-modal-md'
            }
         )
         const accountingApprovalModalComponent: AccountingApprovalModalComponent = accountingApprovalModalRef.componentInstance
         accountingApprovalModalComponent.property = updatedProperty
         accountingApprovalModalComponent.updateProperty = this._updateProperty.bind(this)
         accountingApprovalModalComponent.isApprove = approved


         return
      }

      this._updateProperty(updatedProperty, approved)
   }
}
