<div class="container">

   <app-property-edit-contacts-grid [property]="property"
                                    [canEdit]="canEdit"
                                    (propertyContactSelected)="setSelectedPropertyContact($event)"
                                    (propertyContactUpdated)="propertyContactUpdated($event)"
                                    (propertyContactDeleted)="propertyContactDeleted($event)"></app-property-edit-contacts-grid>


   <div [hidden]="!selectedPropertyContact"
        class="mt-4">

      <app-property-edit-contact [property]="property"
                                 [canEdit]="canEdit"
                                 [propertyContact]="selectedPropertyContact"
                                 (propertyContactUpdated)="propertyContactUpdated($event)"
                                 (cancelPropertyContact)="cancelPropertyContact()"
                                 (createNewPropertyContact)="setSelectedPropertyContact($event)"></app-property-edit-contact>

   </div>

</div>
