
<div class='d-flex justify-content-between align-items-center gap-1 mb-4'>

   <div>
      <div class="input-group input-group-lg">
         <input type="text" class="form-control shadow" placeholder="Search" app-autofocus
                [formControl]="agentsSearchFilterInput"
                [value]="agentsSearchFilter$|async"
                maxlength="30" />
      </div>
   </div>

   <small class="d-flex align-items-center" [innerHtml]="hitMessage | safe: 'html'">
   </small>

   <div class="form-group">
      <div class="pretty p-svg p-curve p-pulse">
         <input type="checkbox"
                [formControl]="includeInactiveCheckbox"
                [checked]="agentsIncludeInactive$|async" />
         <div class="state p-success">
            <svg class="svg svg-icon" viewBox="0 0 20 20">
               <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
            </svg>
            <label>Include Inactive Agents</label>
         </div>
      </div>
   </div>

</div>

<ng-container *ngIf="filteredAgents$ | async as agents">

   <div class="row justify-content-center">

      <div class="col-md-10">

         <ngx-datatable id="agents-grid"
                        class="material striped"
                        [rows]="agents"
                        [rowHeight]="50"
                        [columnMode]="ColumnMode.flex"
                        [sorts]="[{ prop: 'name', dir: 'desc' }]"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarV]="true"
                        [limit]="5">

            <ngx-datatable-column name="Agent" prop="displayName" [flexGrow]="1"  >
               <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.displayName}}
               </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Email" prop="emailAddress"[flexGrow]="1">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <a class="text-site-primary text-underline"
                     href="mailto:{{row.emailAddress}}">{{row.emailAddress}}</a>
               </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column headerClass="custom edit"
                                  cellClass="custom edit"
                                  [flexGrow]="0.5">
               <ng-template let-row="row" ngx-datatable-cell-template>
                  <section class="text-center">
                     <button class="btn btn-sm btn-site-primary"
                             type="button"
                             (click)="editAgent(row)"
                             [disabled]="isSubmitting">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil me-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg> Edit
                     </button>
                  </section>
               </ng-template>
            </ngx-datatable-column>

         </ngx-datatable>

      </div>

   </div>

</ng-container>

<!--<ng-template #noResults>
   <em class="d-block text-danger text-center"
       *ngIf="!searching">
      *No results
   </em>
</ng-template>-->

<div class="text-center mt-4">
   <button class="shadow ms-3 btn btn-primary"
           type="button"
           (click)="showAddAgent()">
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
         <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
         <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
      </svg><span class="ps-2">Add Agent</span>
   </button>
</div>

<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
