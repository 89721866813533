import { createSelector } from '@ngrx/store'
import { IAgent } from '@appShared/interfaces/[Model-based]/agent.interface'
import { getAgentFeatureState } from '@appState/reducers/agent.reducer'

export const getAgents = createSelector(
   getAgentFeatureState,
   state => state.agents
)

export const getAgentSearchFilter = createSelector(
   getAgentFeatureState,
   state => state.searchFilter
)

export const getAgentsIncludeInactive = createSelector(
   getAgentFeatureState,
   state => state.includeInactive
)

export const getFilteredAgents$ = createSelector(
   getAgents,
   getAgentSearchFilter,
   getAgentsIncludeInactive,
   (agents: IAgent[], searchFilter: string, includeInactive: boolean) => {
      const filterBy = searchFilter?.toLocaleLowerCase()
      let filteredAgents: IAgent[] = [...(agents||[])]

      if (filterBy.trim()) {
         filteredAgents = agents.filter((agent: IAgent) => {
            return agent.displayName.toLocaleLowerCase()
               .indexOf(filterBy) !== -1 ||
               agent.emailAddress.toLocaleLowerCase()
                  .indexOf(filterBy) !== -1
         })
      }

      // filter by "includeInactive" only if they select it
      //TODO JY - will need Active flag added to Agent table in DB
      // if (includeInactive) {
      //    filteredAgents = filteredAgents.filter((agent: IAgent) => {
      //       return agent.info.isActive === includeInactive
      //    })
      // }

      return filteredAgents
   }
)

