import { createAction, props } from '@ngrx/store'
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface'
const apiLevelEventPrefix = '[Properties Page API]'

export const loadPropertiesSuccess = createAction(
   `${apiLevelEventPrefix} Load Properties Success`,
   props<{ properties: IProperty[] }>()
)

export const loadPropertiesFailure = createAction(
   `${apiLevelEventPrefix} Load Properties Failure`,
   props<{ error: string }>()
)
