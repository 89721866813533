import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import {
   Component,
   OnDestroy,
   OnInit
} from '@angular/core'
import {
   debounceTime,
   distinctUntilChanged,
   take,
   takeUntil,
   tap
} from 'rxjs/operators'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CommonService } from '@appShared/services/common.service'
import { ConfirmService } from '@appShared/components/confirm-modal-and-service'
import { AddAgentModalComponent } from '../add-agent/add-agent-modal.component'
import { IAgent } from '@appShared/interfaces/[Model-based]/agent.interface'
import { AgentFacade } from '@appShared/services/agent.facade'
import { AgentService } from '@appShared/services/agent.service'
import { ColumnMode, NgxDatatableModule } from '@swimlane/ngx-datatable'
import { SafePipe } from '../../../shared/filters/safe.pipe';
import { ConfirmTemplateDirective, ConfirmModalComponent } from '../../../shared/components/confirm-modal-and-service';
import { NgIf, AsyncPipe } from '@angular/common';
import { AutofocusDirective } from '../../../shared/directives/auto-focus.directive';

@Component({
    selector: 'app-agent-list',
    templateUrl: './agent-list.component.html',
    standalone: true,
    imports: [FormsModule, AutofocusDirective, ReactiveFormsModule, NgIf, NgxDatatableModule, ConfirmTemplateDirective, ConfirmModalComponent, AsyncPipe, SafePipe]
})
export class AgentListComponent implements OnInit, OnDestroy {
   private _ngDestroyed$ = new Subject()
   private _searchFilter: string
   agentsSearchFilter$: Observable<string>
   agentsIncludeInactive$: Observable<boolean>
   agentsSearchFilterInput: FormControl
   includeInactiveCheckbox: FormControl
   hitMessage = ''

   /* grid */
   filteredAgents$: Observable<IAgent[]>
   ColumnMode = ColumnMode

   constructor(
      private _agentFacade: AgentFacade,
      private _modalService: NgbModal
   ) { }

   ngOnInit(): void {
      this._agentFacade.setAgentsSearchFilter('')

      this.agentsSearchFilter$ = this._agentFacade.agentsSearchFilter$.pipe(
         tap(searchFilter => {
            this._searchFilter = searchFilter
         })
      )

      this.agentsIncludeInactive$ = this._agentFacade.agentsIncludeInactive$.pipe(
         tap(includeInactive => `${(new Date()).getTime()} includeInactive: ${includeInactive}`)
      )
      this._agentFacade.agentsIncludeInactive$.pipe(
         take(1)
      ).subscribe(includeInactive => { `${(new Date()).getTime()} includeInactive: '${includeInactive}'` })

      this.filteredAgents$ = this._agentFacade.filteredAgents$.pipe(
         tap(filteredAgents => {
            const hitCount = filteredAgents.length
            if (hitCount) {
               this.hitMessage = this._searchFilter.trim()
                  ? `${hitCount} record(s) matching: '${this._searchFilter}'`
                  : `Viewing ${hitCount} record(s)`
            } else {
               this.hitMessage = `No records matching: '${this._searchFilter}'`
            }
         })
      )
      this.agentsSearchFilterInput = new FormControl()
      this.agentsSearchFilterInput.valueChanges.pipe(
         takeUntil(this._ngDestroyed$),
         tap(_ => this._setLoadingMessage()),
         debounceTime(400),
         distinctUntilChanged()
      ).subscribe(searchFilter => {
         this._agentFacade.setAgentsSearchFilter(searchFilter)
      })

      this.includeInactiveCheckbox = new FormControl(true)
      this.includeInactiveCheckbox.valueChanges.pipe(
         takeUntil(this._ngDestroyed$),
         tap(_ => this._setLoadingMessage()),
         debounceTime(400)
      ).subscribe(checked => {
         this._agentFacade.setAgentsIncludeInactive(checked)
      })
   }

   ngOnDestroy(): void {
      this._ngDestroyed$.next(true);
      this._ngDestroyed$.complete();
   }
   /*
    * private methods
    * */

   private _setLoadingMessage(msg?: string) {
      this.hitMessage = msg || '<span class="fw-normal pb-1">Loading agents</span><img src="/assets/images/processing.gif" class="processing-gif ps-2"/>'
   }

   /*
    * public methods
    * */
   editAgent(agent?: IAgent) {
      if (agent) {
         console.log(agent)
      }
   }

   showAddAgent(): void {
      this._modalService.open(AddAgentModalComponent, {
         scrollable: true,
         windowClass: 'my-modal-md'
      })
   }

}
