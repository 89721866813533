import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { AuthService } from '@appShared/services/auth.service'
//import { SiteService } from '@appShared/services/site.service'
import { AppActions } from '@appState/actions'

@Injectable({ providedIn: 'root' })
export class AppEffects {
   constructor(
      private _actions$: Actions,
      private _authService: AuthService,
      //private _siteService: SiteService
   ){}

   loadSiteConfig$ = createEffect(() => {
      return this._actions$.pipe(
         ofType(AppActions.loadSiteConfig),
         switchMap(action =>
            this._authService.getSiteConfig().pipe(
               switchMap(siteConfig => {
                  const actions = []

                  // const profile = siteConfig?.user
                  // const selectedEmployeeProfile = profile?.dealerInfo ? profile : null
                  /* add setSelectedDealer IF user is a dealer */
                  // if (selectedEmployeeProfile) {
                  //  actions.push(AgentPageActions.setSelectedAgent({ profile: profile }))
                  // }

                  actions.push(
                     AppActions.loadSiteConfigSuccess({ siteConfig })
                  )

                  return actions
               }),
               catchError(error =>
                  of(AppActions.loadSiteConfigFailure({ error }))
               )
            )
         )
      )
   })

   // toggleSiteOffline$ = createEffect(() => {
   //    return this._actions$.pipe(
   //       ofType(AppActions.toggleIsMaintenanceMode),
   //       switchMap(action =>
   //          this._siteService.toggleIsMaintenanceMode(action.isMaintenanceMode).pipe(
   //             switchMap(isMaintenanceMode => {
   //                const actions = []

   //                actions.push(AppActions.toggleIsMaintenanceModeSuccess({ isMaintenanceMode }))

   //                return actions
   //             }),
   //             catchError(error => of(AppActions.loadSiteConfigFailure({ error })))
   //          )
   //       )
   //    )
   // })
}
