import { AfterViewInit, Component, Input, ViewChild } from '@angular/core'
import { NgIf, DatePipe } from '@angular/common'
import { take, tap } from 'rxjs'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
   PropertyEditSectionData,
   PropertyEditSection_,
   PropertyService
} from '@appShared/services/property.service';
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import {
   IProperty,
   IPropertyContact
} from '@appShared/interfaces/[Model-based]';
import { DocumentTypes } from '@appShared/services/lookup/[CodeGen]/document-type.domain'
import {
   ConfirmOptions,
   ConfirmService
} from '@appShared/components/confirm-modal-and-service';
import { IProfile } from '@appShared/interfaces/[Interface-based]/profile.interface';
import { PropertyEditSubmitModalComponent } from '@appProperty/property-edit/property-edit-submit-modal/property-edit-submit-modal.component';
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service'
import { PropertyEditComponent } from '@appProperty/property-edit/property-edit.component'
import * as _ from 'lodash'

@Component({
    selector: 'app-property-edit-modal',
    templateUrl: './property-edit-modal.component.html',
    styleUrls: ['./property-edit-modal.component.less'],
    standalone: true,
    imports: [NgIf, PropertyEditComponent, ConfirmTemplateDirective, ConfirmModalComponent, DatePipe]
})
export class PropertyEditModalComponent implements AfterViewInit {
   @Input() property: IProperty;
   @Input() userProfile: IProfile
   @Input() closeFunction: () => void | null;
   @Input() dismissFunction: () => void | null;
   @Input() saveFunction: () => void | null;
   @Input() refreshGrid: () => void;

   canEdit: boolean
   showAccoutingLink: boolean
   PropertyEditSection_ = PropertyEditSection_;

   constructor(
      private _activeModal: NgbActiveModal,
      private _commonService: CommonService,
      private _confirmService: ConfirmService,
      private _propertyService: PropertyService,
      private _modalService: NgbModal
   ) { }

   ngAfterViewInit(): void {
      setTimeout(() => {
         this._refreshProperty(this.property);
      }, 0);
   }

   /*
    * private methods
    * */

   private _refreshProperty(property?: IProperty): void {
      const setProperty = (refreshedProperty?: IProperty) => {
         this.property = refreshedProperty || property;

         this._setVisibility()

         setTimeout(() => {
            this.goToSection();
         }, 0);
      };

      /* if existing property - get fresh data */
      if (property.id) {
         this._propertyService
            .getProperty(property.id)
            .then(refreshedProperty => {
               setProperty(refreshedProperty);
            })
            .catch(() => {
               this._activeModal.close(this.closeFunction);
            });
      } else {
         setProperty();
      }
   }

   private _setVisibility() {

      const propertyInfo = this.property?.info
      this.canEdit = this.userProfile.isAdmin || this.property?.id == 0 || propertyInfo.isOpen
      this.showAccoutingLink = (this.userProfile.isAdmin && propertyInfo?.isSubmitted) || propertyInfo.isApproved
   }
   /*
    * public methods
    * */

   viewGoogleMap(): void {
      if (this.property) {
         window.open(this.property.info.mappedAddressUri, '_blank');
      }
   }

   goToSection(section?: PropertyEditSection_): void {
      section = section || PropertyEditSection_.BasicInfo;

      /* if new item, then scroll to basic data section */
      if (!this.property.id) {
         section = PropertyEditSection_.BasicInfo;
      }

      setTimeout(() => {
         this._commonService.scrollToModalAnchor(
            `property-edit-section-${section}`
         );
      }, 0);
   }

   propertyReset(propertyEditSection: PropertyEditSectionData): void {
      const incomingProperty = propertyEditSection.property

      this.property = {
         ...this.property,
         ...incomingProperty
      }

      this._setVisibility()

      if (propertyEditSection.refresh) {
         this.refreshGrid()
      }
   }

   propertyStageUpdated(propertyEditSectionData: PropertyEditSectionData): void {
      const incomingProperty = _.cloneDeep(propertyEditSectionData.property)

      if (propertyEditSectionData.refresh && incomingProperty?.id) {
         this._propertyService
            .getProperty(incomingProperty.id)
            .then(refreshedItem => {
               propertyEditSectionData.property = refreshedItem
               //this.propertyUpdated.emit(propertyEditSectionData)
               this.propertyReset(propertyEditSectionData)
            })
            .catch(() => {
               /* Do Nothing */
            })
      } else {
         propertyEditSectionData.property = incomingProperty
         //this.propertyUpdated.emit(propertyEditSectionData)
         this.propertyReset(propertyEditSectionData)
      }
   }

   submitToAccounting(): void {
      const property = this.property;

      if (property.info.isIncomplete) {
         const messageArray = ['']
         if (!property.info.hasContacts) {
            messageArray.push('* Add at least 1 contact')
         }
         if (!property.info.areDetailsComplete) {
            messageArray.push('* Detail information incomplete')
         }
         if (!property.info.areDocumentsComplete) {
            messageArray.push('* Documents incomplete')
            var missingDocuments = DocumentTypes.filter(docType => property.info.missingRequiredDocumentTypeCodes.includes(docType.code))
               .map(docType => docType.description)
            messageArray.push(`<ul><li>${missingDocuments.join('</li><li>')}</li></ul>`)
         }

         if (messageArray.length) {
            const message = messageArray.join('<br/>')
            this._confirmService
               .confirm({
                  title: 'Missing for Submit to Accounting!',
                  message: `You information has been saved, but the following information is
                      needed before being able to submit to Accounting:
                      <p class="font-weight-bold text-error">
                      ${message}</p>`,
                  hideNo: true,
                  yesText: 'OK'
               } as ConfirmOptions)
               .then(() => {
                  /* Do nothing */
               })
               .catch(() => {
                  /* Do nothing */
               })
         }

         return;
      }

      const propertyEditSubmitModalRef = this._modalService.open(
         PropertyEditSubmitModalComponent,
         {
            scrollable: true
            // windowClass: 'my-modal-md'
         }
      )
      const propertySubmitModealComponent: PropertyEditSubmitModalComponent = propertyEditSubmitModalRef.componentInstance
      propertySubmitModealComponent.property = { ...property }
      propertySubmitModealComponent.propertyReset = this.propertyStageUpdated.bind(this)

   }

   dismiss(): void {
      this._activeModal.dismiss(this.closeFunction);
   }

   close(): void {
      this._activeModal.close(this.dismissFunction);
   }
}
