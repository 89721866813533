<h5 class="text-center text-site-primary mb-4">
   Search by either First Name or Last Name
</h5>

<div class="row justify-content-center search-area">

   <div class="col-md-8 col-lg-6 text-center">
      <form #searchForm="ngForm" novalidate autocomplete="off">
         <div class="form-group">
            <input type="text" class="form-control shadow" app-autofocus [formControl]="employeeSearchTerm"
                   placeholder="Search employees" aria-label="Search employees" />
         </div>
      </form>
   </div>
</div>

<div class="text-center">
   <p class="lead" *ngIf="searching">Searching...</p>
</div>

<ng-container *ngIf="employees$ | async as employees">

   <div class="row justify-content-center">

      <div class="col-md-10">

         <table class="table" *ngIf="employees.length; else noResults">
            <thead>
               <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Company</th>
                  <th scope="col">Department</th>
                  <th scope="col">&nbsp;</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let employee of employees">
                  <td>{{employee.displayName}}</td>
                  <td>{{employee.company}}</td>
                  <td>{{employee.department}}</td>
                  <td style="width:100px;">
                     <button (click)="createAgent(employee)"
                             type="button"
                             class="shadow btn btn-sm btn-site-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                           <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg> Add
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>

      </div>

   </div>

</ng-container>

<ng-template #noResults>
   <em class="d-block text-danger text-center"
       *ngIf="!searching">
      *No results matched your search for <strong>'{{employeeSearchTermInput?.value}}'</strong>
   </em>
</ng-template>

<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
