import { Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common'
import { map, Observable, of } from 'rxjs'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { IProperty } from '@appShared/interfaces/[Model-based]/property.interface'
import { IPropertyWorkflow } from '@appShared/interfaces/[Model-based]/property-workflow.interface'
import * as _ from 'lodash'

@Component({
    selector: 'app-property-edit-workflows',
    templateUrl: './property-edit-workflows.component.html',
    styles: ['td.status { width: 225px; }'],
    standalone: true,
    imports: [NgIf, NgFor, AsyncPipe, DatePipe]
})
export class PropertyEditWorkflowsComponent implements OnChanges {
   @Input() property: IProperty

   /* grid */
   ColumnMode = ColumnMode;
   workflows$: Observable<Array<IPropertyWorkflow>> = of([]);

   constructor(
   ) { }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.['property']?.currentValue) {
         this.getPropertyWorkflows()
      }
   }

   /*
   * public methods
   * */

   getPropertyWorkflows(): void {
      // if they want an initial sort differently
      //this.workflows$ = of(this.property.propertyWorkflows.sort((a: IPropertyWorkflow, b: IPropertyWorkflow) => {
      //   return a.info.lastActionInitiated > b.info.lastActionInitiated ? -1 : 1
      //}))

      this.workflows$ = of(this.property.propertyWorkflows).pipe(
         map((workflows) => {

            if (!workflows?.length) {
               return []
            }
            /*
               due to property returned from update that returns addded
               workflow, the info properties are not set yet - filter
               them out - the site makes immediate call to get updated
               property anyway, so info will be there to show workflows
            */
            return workflows.filter(workflow => workflow.info.lastActionInitiated)
         })
      )
   }
}
