import {
   Component, OnDestroy
} from '@angular/core'
import { NgIf } from '@angular/common'
//import { FormControl, FormGroup } from '@angular/forms'
//import { Subject, takeUntil } from 'rxjs'
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { CommonService } from '@appShared/services/common.service'
import {
   PropertyService
} from '@appShared/services/property.service'
import { ToastrType } from '@appShared/services/toastr.service'

@Component({
    selector: 'app-closed-properties-export',
    templateUrl: './closed-properties-export.component.html',
    styles: [
        `form {height: 385px}`
    ],
    standalone: true,
    imports: [BsDatepickerModule, NgIf]
})
export class ClosedPropertiesExportComponent /*implements OnDestroy */{
   //private _ngDestroyed$ = new Subject()

   downloadUri: string
   dateRangeDisplay = '* Select above to set the date range for the report'

   // formgroup/fields
   //exportDateRangePickerValue = []
   //propertyExportForm: FormGroup
   //exportDateRangePicker: FormControl
   datePickerBaseConfig: Partial<BsDatepickerConfig> = {}
   isSubmitting: boolean

   constructor(
      private _propertyService: PropertyService,
      private _commonService: CommonService
   ) {
      //this._createFormGroup()
   }

   //ngOnDestroy(): void {
   //   this._ngDestroyed$.next(true)
   //   this._ngDestroyed$.complete()
   //}

   /*
    * private methods
    * */

   //private _createFormGroup(): void {
   //   this.exportDateRangePicker = new FormControl()

   //   this.propertyExportForm = new FormGroup({
   //      exportDateRangePicker: this.exportDateRangePicker
   //   })

   //   this.exportDateRangePicker.valueChanges
   //      .pipe(takeUntil(this._ngDestroyed$))
   //      .subscribe(dateRange => this._setDownloadParameters(dateRange))

   //}

   private _setDownloadParameters(dateRange): void {

      if (!(dateRange?.length)) {
         this._commonService.messageUser(
            "Please select a date range for the report!", null,
            ToastrType.error)

         return
      }

      const beginDate = this._commonService.dateTime.formatDate(dateRange[0])
      const endDate = this._commonService.dateTime.formatDate(dateRange[1])

      this.downloadUri = `${this._propertyService.propertyApi}/closed-properties-export.csv?from=${beginDate}&thru=${endDate}`
   }

   /*
    * public methods
    * */

   setDownloadParameters(dateRange): void {
      if (!(dateRange?.length)) {
         this._commonService.messageUser(
            "Please select a date range for the report!", null,
            ToastrType.error)

         return
      }

      const beginDate = this._commonService.dateTime.formatDate(dateRange[0])
      const endDate = this._commonService.dateTime.formatDate(dateRange[1])

      this.dateRangeDisplay = `Properties closed from ${beginDate} to ${endDate}`
      this.downloadUri = `${this._propertyService.propertyApi}/closed-properties-export.csv?from=${beginDate}&thru=${endDate}`
   }
}
