import { Injectable } from '@angular/core'
import {
   HttpInterceptor,
   HttpHandler,
   HttpRequest,
   HttpEvent,
   HttpContextToken
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { LoadingService } from './loading.service'
import * as _ from 'lodash'

//https://blog.angular-university.io/angular-loading-indicator/
export const SkipLoading =
   new HttpContextToken<boolean>(() => false)
/* EXAMPLE: how to pass "SkipLoading" in api calls:

    this.http.get("/api/courses", {
     context: new HttpContext().set(SkipLoading, true),
   });
*/

@Injectable()
export class LoadingInterceptor
   implements HttpInterceptor {
   constructor(private loadingService: LoadingService) {
   }

   intercept(
      req: HttpRequest<any>,
      next: HttpHandler
   ): Observable<HttpEvent<any>> {
      // Check for a custom attribute
      // to avoid showing loading spinner
      if (req.context.get(SkipLoading)) {
         // Pass the request directly to the next handler
         return next.handle(req)
      }

      // Turn on the loading spinner
      this.loadingService.loadingOn()

      return next.handle(req).pipe(
         finalize(() => {
            // Turn off the loading spinner
            this.loadingService.loadingOff()
         })
      )
   }
}
