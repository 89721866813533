import { AfterContentInit, Directive, ElementRef } from '@angular/core'

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[app-autofocus]',
    standalone: true
})
export class AutofocusDirective implements AfterContentInit {

  constructor(private el: ElementRef) {
  }

  ngAfterContentInit(): void {
    //console.log('[app-autofocus]', this.el)
    setTimeout(() => {
      const e = this.el.nativeElement
      if (e) {
        if (e.tagName?.toLowerCase() === 'input') {
          e.focus()
        } else {
          const inputs = e.getElementsByTagName('input')
          if (inputs?.length) {
            inputs[0].focus()
          }
        }
      }
    }, 100)
  }
}
