import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { Store } from '@ngrx/store'
import { AppActions } from '@appState/actions'
import { State } from '@appState/index'
import * as fromStore from '@appState/selectors/app.selectors'
import {
   IProfile,
   //IProfile,
   ISiteConfiguration,
} from '@appShared/interfaces/[Interface-based]'

@Injectable({
   providedIn: 'root'
})
export class AppFacade {
   siteConfig$: Observable<ISiteConfiguration> = this._store.select(
      fromStore.getSiteConfig
   )

   profile$: Observable<IProfile> = this._store.select(fromStore.getCurrentProfile)

   isMaintenanceMode$: Observable<boolean> = this._store.select(fromStore.getIsMaintenanceMode)

   //hasVerifiedSSN$: Observable<boolean> = this._store.select(
   //  fromStore.getHasVerifiedSSN
   //)

   //stockPlanUri$: Observable<string> = this._store.select(
   //  fromStore.getStockPlanUri
   //)

   supportEmailAddress$: Observable<string> = this._store.select(
      fromStore.getSupportEmailAddress
   )

   //isAdminOnlyMode$: Observable<boolean> = this._store.select(
   //  fromStore.getIsAdminOnlyMode
   //)

   //areAgentsAccessible$: Observable<boolean> = this._store.select(
   //  fromStore.getAreAgentsAccessible
   //)

   constructor(private _store: Store<State>) { }

   loadSiteConfig(): void {
      this._store.dispatch(AppActions.loadSiteConfig())
   }

   prefetchSiteConfig(item): void {
      // if haven't gotten store yet, go get it
      if (!item) {
         this.loadSiteConfig()
      }
   }

   setProfile(profile: IProfile): void {
      this._store.dispatch(AppActions.setProfile({ profile }))
   }
   //toggleIsAdminOnlyMode(isAdminOnlyMode: boolean): void {
   //  this._store.dispatch(AppActions.toggleIsAdminOnlyMode({ isAdminOnlyMode }))
   //}
}
